import { CharecteristicEditComponent } from '../charecteristics-edit/charecteristic-edit.component';
import { CharecteristicsModel } from '../../../../../../core/quotation/_models/charecteristics.model';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { CharecteristicsService } from '../../../../../../core/quotation/_services/charecteristics.service';
import {Component, OnInit, Input, ChangeDetectorRef, ViewRef, OnDestroy} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {MatDialog} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'kt-charecteristic-list',
  templateUrl: './charecteristic-list.component.html',
  styles: [
  ]
})

export class CharecteristicListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['IdSeccion', 'Seccion', 'Descripcion', 'actions'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<CharecteristicsModel>(true, []);
  private subscriptions: Subscription[] = [];

  formGroup: FormGroup;
  hasFormErrors = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
  isPool = false;
  template = 1;
  name = 'Pool';

  constructor(private charecteristicsService: CharecteristicsService,
              private cdr: ChangeDetectorRef,
              private subheaderService: SubheaderService,
              private dialog: MatDialog,
              private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista Característica', 'Estas son sus caracteristicas');
    this.loadData(this.template);

    this.subscriptions.push(this.subheaderService.setNewCharecteristic$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){
          this.add();
        }
      })
    }))

    this.subscriptions.push(this.subheaderService.template$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.loadData(bc);
        this.template = bc;
        this.cdr.detectChanges();
      });
    }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

  }

  loadData(isPool){
    this.charecteristicsService.get(isPool).subscribe( next =>{
      console.log(next);
      this.dataSource = new MatTableDataSource(next);
      this.cdr.detectChanges();
    }, e => {
      console.log(e);
    });

  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }



  edit(charecteristicsModel: CharecteristicsModel) {
    console.log(charecteristicsModel);
    const dialogRef = this.dialog.open(CharecteristicEditComponent,  { data: { charecteristicsModel, template: this.template } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.charecteristicsService.get(this.template).subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
    });
  }
  add() {
    const charecteristic = new CharecteristicsModel();
    charecteristic.clear();
    this.edit(charecteristic);
  }

}
