import { BaseModel } from '../../_base/crud';

export class ProductCharecteristicsModel extends BaseModel {

  IdSeccion: number;
  Seccion: string;
  IdPlantillaC: number;
  IdCaracteristica: number;
  IdPlan: number;
  Descripcion: string;
  Valor: string;
  AplicaMaternidad: boolean;
  AplicaNSolo: boolean;
  Estado: number;

  _isEditMode: boolean;

  clear(IdPlan: number) {
    this.IdSeccion = 0;
    this.Seccion = '';
    this.IdPlantillaC= 0;
    this.IdCaracteristica= 0;
    this.IdPlan= IdPlan;
    this.Descripcion = '';
    this.Valor = '';
    this.AplicaMaternidad = true;
    this.AplicaNSolo = true;
    this.Estado= 0;

    this._isEditMode = false;
  }
}
