<div class="card card-custom"
     [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{getTitle()}}</h3>
    </div>
  </div>

    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <kt-alert *ngIf="hasFormErrors" type="{{type}}" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
        {{ms}}
      </kt-alert>
      <form [formGroup]="chForm">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Seccion.</label>
              <select formControlName="IdSeccion" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
                <option value="" disabled>-- Seccion --</option>
                <ng-container *ngFor="let s of sections">
                  <option [value]="s.IdSeccion">{{ s.Seccion }}</option>
                </ng-container>
              </select>
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('IdSeccion', 'required')">Debe seleccionar una seccion</div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Descripción</label>
              <input formControlName="Descripcion" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('Descripcion', 'required')">Debe indicar una descripcion</div>
            </div>
          </div>
        </div>
      </form>

    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="{{ 'AUTH.GENERAL.CANCEL_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.CANCEL' | translate }}
          </button>&nbsp;
          <button (click)="save()" type="button" mat-raised-button color="primary" [disabled]="viewLoading" matTooltip="{{ 'AUTH.GENERAL.SAVE_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
</div>

