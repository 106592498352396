import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PdkMaker} from '../../../../../../../core/quotation/_services/pdf-make';
import {QuoteService} from '../../../../../../../core/quotation/_services/quote.service';
import {AuthService} from '../../../../../../../core/auth';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductModel, ProductsService} from '../../../../../../../core/quotation';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'kt-change-plan',
  templateUrl: './change-plan.component.html',
  styles: [
  ]
})
export class ChangePlanComponent implements OnInit {
  viewLoading = false;
  formChange: FormGroup;
  plans: ProductModel[];
  rol: number;
  error = false;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private pdfService: PdkMaker,
    private router: Router,
    private qs: QuoteService,
    private ps: ProductsService,
    private us: AuthService,
    public dialogRef: MatDialogRef<ChangePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.rol = Number(localStorage.getItem('p'));
    this.ps.getAll().subscribe( next=>{
      this.plans = next;
    } );
    console.log(this.data);
    const data = JSON.stringify(this.data.q);
    localStorage.setItem('_data', data);
    this.createForm();
  }

  createForm() {
    this.formChange = this.fb.group({
      plan: [0, Validators.required]
    });
  }


  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.formChange.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  validate(plan: ProductModel){
    console.log(plan);
    // tslint:disable-next-line:triple-equals
    if(this.data.IdPlan == plan.IdPlan){
      return false;
    }

    if(this.rol === 2){
      if(plan.Estado !== 1){
        return false;
      }
    }else{
      if(plan.Estado === 2){
        return false;
      }
    }

    // tslint:disable-next-line:triple-equals
    if(this.data.IdProducto != plan.IdProducto){
      return false;
    }console

    if(Boolean(!this.data.conBeneficio) && Number(this.data.IdProducto) !== 2){
      // tslint:disable-next-line:triple-equals
      if(!plan.ConBeneficios){
        return false;
      }
    }
    return true;
  }

  onYesClick() {
    const controls = this.formChange.controls;
    /** check form */
    if (this.formChange.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.error = false;
    this.viewLoading = true;

    if(controls.plan.value >0){
      let a = 'sinBeneficios';

      if(this.data.ConBeneficio){
        a = 'conBeneficios';
      }


      const url = '/cotizar/formulario/'+ controls.plan.value +'/'+ a ;
      this.dialogRef.close();
      this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
    }else{
      this.error = true;
      this.viewLoading = false;
      return false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
