import {Component, Inject, OnInit} from '@angular/core';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {MatTableDataSource} from '@angular/material/table';
import {ProductsService} from '../../../../../../core/quotation/_services/products.service';
import {ProductModel} from '../../../../../../core/quotation/_models/product.model';
import {BackUserModel} from '../../../../../../core/quotation/_models/_out/back-user.model';
import {AdditionalBenefitsService} from '../../../../../../core/quotation/_services/additional-benefits.service';

@Component({
  selector: 'kt-benift-plan-edit',
  templateUrl: './benift-plan-edit.component.html',
  styles: [
  ]
})
export class BeniftPlanEditComponent implements OnInit {
  benefit: CatalogueBenefitsModel;
  benefits: CatalogueBenefitsModel[];
  plans: ProductModel[];
  hasFormErrors = false;
  benefitsForm: FormGroup;
  viewLoading = false;

  constructor(public dialogRef: MatDialogRef<BeniftPlanEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private layoutUtilsService: LayoutUtilsService,
              private abs: AdditionalBenefitsService,
              private cbs: CatalogueBenefitsService,
              private ps: ProductsService) { }

  ngOnInit(): void {
    this.benefit = this.data.benefits;

    this.cbs.getAll().subscribe( next =>{
      this.benefits = next;
    } );

    this.ps.getAll().subscribe( next=>{
      this.plans = next;
    } );

    this.createForm();
  }

  createForm() {
    if (this.benefit.IdBeneficio > 0) {
      this.benefitsForm = this.fb.group({
        description: [this.benefit.Descripcion, Validators.required],
        obser: [this.benefit.Observacion, Validators.required],
        coste: [this.benefit.Costo, Validators.required]
      });
    } else {
      this.benefitsForm = this.fb.group({
        plan: [, Validators.required],
        benefit: [, Validators.required]
      });
    }
  }

  getTitle(): string {
    if (this.benefit.IdBeneficio > 0) {
      return 'Editar beneficio adicional';
    }
    return 'Agregar beneficio adicional';
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.benefitsForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  save() {
    this.hasFormErrors = false;
    const controls = this.benefitsForm.controls;
    /** check form */
    if (this.benefitsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }
    const _benefit: CatalogueBenefitsModel = new CatalogueBenefitsModel();
    _benefit.clear();

    if (this.benefit.IdBeneficio > 0) {
      _benefit.IdBeneficio = this.benefit.IdBeneficio;
      _benefit.DescBeneficio = this.benefit.DescBeneficio;
      _benefit.IdPlan = this.benefit.IdPlan;
      _benefit.DescPlan = this.benefit.DescPlan;
      _benefit.Costo = controls.coste.value;
      _benefit.Observacion = controls.obser.value;
      _benefit.Descripcion = controls.description.value;
      _benefit.IdCatalogoBeneficio = this.benefit.IdCatalogoBeneficio;
      this.update(_benefit);
    } else {
      _benefit.DescBeneficio = this.benefits[controls.benefit.value].DescBeneficio;
      _benefit.IdPlan = this.plans[controls.plan.value].IdPlan;
      _benefit.DescPlan = this.plans[controls.plan.value].DescPlan;
      _benefit.Costo = this.benefits[controls.benefit.value].Costo;
      _benefit.Observacion = this.benefits[controls.benefit.value].Observacion;
      _benefit.Descripcion = this.benefits[controls.benefit.value].Descripcion;
      _benefit.IdCatalogoBeneficio = this.benefits[controls.benefit.value].IdCatalogoBeneficio;
      this.create(_benefit);
    }
  }

  create(benefits: CatalogueBenefitsModel) {

    this.abs.add(benefits).subscribe( next =>{
      console.log(next);
      const message = `El nuevo beneficio fue agregado con exito.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e =>{
      this.hasFormErrors = true;
    } );
  }

  update(benefits: CatalogueBenefitsModel){
    this.abs.update(benefits).subscribe( next =>{
      console.log(next);
      const message = 'El beneficio ' + benefits.IdBeneficio + ' fue actualizado con exito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e =>{
      this.hasFormErrors = true;
    } );
  }

}
