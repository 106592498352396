// Angular
import {Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ViewRef, ChangeDetectorRef} from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, take } from 'rxjs/operators';
import { fromEvent, merge, Subscription, of } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
// Components
import { CustomerEditDialogComponent } from '../customer-edit/customer-edit.dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import {UserModel} from '../../../../../../core/quotation';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {UsersService} from '../../../../../../core/quotation/_services/users.service';
import {BrokerModel} from '../../../../../../core/quotation/_models/broker.model';
import {BrokersService} from '../../../../../../core/quotation/_services/brokers.service';

// Table with EDIT item in MODAL
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/compgetItemCssClassByStatusonents/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-customers-list',
	templateUrl: './customers-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CustomersListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: MatTableDataSource<UserModel>;
	dataSourceTemp: UserModel[];
	displayedColumns = ['Identificacion', 'Broker', 'Nombres', 'Correo', 'IdEstado', 'actions'];
  @ViewChild('input', {static: true}) input: ElementRef;
  @ViewChild('inputStatus', {static: true}) inputStatus: ElementRef;
  @ViewChild('inputBrokers', {static: true}) inputBrokers: ElementRef;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	selection = new SelectionModel<UserModel>(true, []);
	customersResult: UserModel[] = [];
	// Subscriptions
	private subscriptions: Subscription[] = [];
  brokers:BrokerModel[];
  filterStatus = '';
  filterGeneral = '';
  filterAngency = '*';
  selectedValueBroker = '';
  selectedValueStatus = '';


	constructor(
		public dialog: MatDialog,
		public snackBar: MatSnackBar,
		private layoutUtilsService: LayoutUtilsService,
		private translate: TranslateService,
    private bs: BrokersService,
		private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private us: UsersService,
    public subheaderService: SubheaderService
	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// Init DataSource
    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.users);
        this.dataSourceTemp = data.users;
      });

    this.bs.getAll().subscribe( next =>{
      this.brokers = next;
      this.cdr.detectChanges();
      console.log(next);
    });

    this.subscriptions.push(this.subheaderService.newUser$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){
          this.addCustomer();
        }
      });
    }));

	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
	}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

	/** ACTIONS */
	/**
	 * Delete customer
	 *
	 * @param _item: UserModel
	 */
	deleteCustomer(_item: UserModel) {
		const _title: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE');
		const _description: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION');
		const _waitDesciption: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION');
		const _deleteMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE');

		const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
		});
	}


	/**
	 * Fetch selected customers
	 */
	fetchCustomers() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.Nombres}, ${elem.Nombres}`,
				id: elem.Identificacion.toString(),
				status: elem.IdEstado
			});
		});
		this.layoutUtilsService.fetchElements(messages);
	}

	/**
	 * Show UpdateStatuDialog for selected customers
	 */
	updateStatusForCustomers() {
		const _title = this.translate.instant('ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE');
		const _updateMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE');
		const _statuses = [{ value: 0, text: 'Suspended' }, { value: 1, text: 'Active' }, { value: 2, text: 'Pending' }];
		const _messages = [];

		this.selection.selected.forEach(elem => {
			_messages.push({
				text: `${elem.Nombres}, ${elem.Nombres}`,
				id: elem.Identificacion.toString(),
				status: elem.IdEstado,
				statusTitle: this.getItemStatusString(elem.IdEstado),
				statusCssClass: this.getItemCssClassByStatus(elem.IdEstado)
			});
		});

		const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				this.selection.clear();
				return;
			}
			this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update, 10000, true, true);
			this.selection.clear();
		});
	}

	/**
	 * Show add customer dialog
	 */
	addCustomer() {
	  console.log('llego a agregar');
		const newCustomer = new UserModel();
		newCustomer.clear(); // Set all defaults fields
		this.editCustomer(newCustomer);
	}

	/**
	 * Show Edit customer dialog and save after success close result
	 * @param customer: UserModel
	 */
	editCustomer(customer: UserModel) {
    localStorage.removeItem('perfil');
		let saveMessageTranslateParam = 'Guardar';
		saveMessageTranslateParam += customer.IdUsuario > 0 ? 'Agregar usuario' : 'Nuevo Usuario';
		const _saveMessage = this.translate.instant(saveMessageTranslateParam);
		const _messageType = customer.IdUsuario > 0 ? MessageType.Update : MessageType.Create;
		const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer } });
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
      this.us.getAll().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
		});
	}


	/**
	 * Toggle all selections
	 */

	/** UI */
	/**
	 * Retursn CSS Class Name by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: number = 0): string {
		switch (status) {
			case 1:
				return 'success';
			case 2:
				return 'metal';
			case 3:
				return 'danger';
      case 4:
        return 'metal';
      case 5:
        return 'metal';
		}
		return '';
	}

	/**
	 * Returns Item Status in string
	 * @param status: number
	 */
	getItemStatusString(status: number = 0): string {
		switch (status) {
			case 1:
				return 'Activo';
			case 2:
				return 'Inactivo';
			case 3:
				return 'Denegado';
      case 4:
        return 'Pendiente';
      case 5:
        return 'Suspendido';
		}
		return '';
	}

	/**
	 * Returns CSS Class Name by type
	 * @param status: number
	 */
	getItemCssClassByType(status: number = 0): string {
		switch (status) {
			case 0:
				return 'primary';
			case 1:
				return 'danger';
			case 2:
				return 'success';
		}
		return 'success';
	}

  filter(e){
	  let userTempFilter: UserModel[] = [];
    console.log(e.value);
    if(e.value === '*'){
      this.dataSource = new MatTableDataSource(this.dataSourceTemp);
    }else{
      let temp = this.dataSourceTemp.filter( data => data.IdBroker === e.value);
      this.dataSource = new MatTableDataSource(temp);
    }
  }


}
