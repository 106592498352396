import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BackTarifasResModel} from '../_models/_in/back-tarifas-res.model';
import {Back_benefistResModel} from '../_models/_in/back_benefist-res.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetBeneficioPlan';

@Injectable({
  providedIn: 'root'
})

export class BenefitsService {
  constructor(private http: HttpClient) {}

  GetBenefist(id){
    const options = {};
    return this.http.get<Back_benefistResModel[]>(URL_API.url + URL_API_GET_QUOTES + '?idPlan='+ id, options);
  }

}
