<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
  <!--begin::Login-->
  <div class="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
    <!--begin::Content-->
    <div class="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white overflow-auto">
      <router-outlet></router-outlet>
    </div>
    <!--begin::Content-->
    <!--begin::Aside-->
    <div class="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
      <div class="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom" style="background-image: url(assets/media/svg/illustrations/working.svg);">
        <!--begin::Aside title-->
        <h3 class="pt-lg-40 pl-lg-20 pb-lg-0 pl-10 py-20 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg text-white">Cotizador
          <br />Web</h3>
        <!--end::Aside title-->
      </div>
    </div>
    <!--end::Aside-->
  </div>
  <!--end::Login-->
</div>
