import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kt-pool-rate-edit',
  templateUrl: './pool-rate-edit.component.html',
  styles: [
  ]
})
export class PoolRateEditComponent implements OnInit {
  viewLoading = false;
  loadingAfterSubmit = false;
  constructor(public dialogRef: MatDialogRef<PoolRateEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
