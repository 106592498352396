import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {BeniftCatalogueEditComponent} from '../../benefit-catalogue/benift-catalogue-edit/benift-catalogue-edit.component';
import {BeniftPlanEditComponent} from '../benift-plan-edit/benift-plan-edit.component';
import {ProductsService} from '../../../../../../core/quotation/_services/products.service';
import {AdditionalBenefitsService} from '../../../../../../core/quotation/_services/additional-benefits.service';

@Component({
  selector: 'kt-benift-plan-list',
  templateUrl: './benift-plan-list.component.html',
  styles: [
  ]
})
export class BeniftPlanListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<CatalogueBenefitsModel>;
  displayedColumns = ['IdBeneficio', 'DescProducto', 'DescPlan', 'DescBeneficio', 'Costo', 'Observacion', 'actions'];
  private subscriptions: Subscription[] = [];
  constructor(
              public dialog: MatDialog,
              private cbs: CatalogueBenefitsService,
              private abs: AdditionalBenefitsService,
              private ps: ProductsService,
              private layoutUtilsService: LayoutUtilsService,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private subheaderService: SubheaderService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Beneficios adicionales', 'Aqui configura los beneficios adicionales de sus planes');
    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.benefits);
      });

    this.subscriptions.push(this.subheaderService.setNewBenefit$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){
          this.add();
        }
      });
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  edit(benefits: CatalogueBenefitsModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdCatalogoBeneficio > 0 ? 'Agregar usuario' : 'Nuevo Usuario';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdCatalogoBeneficio > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(BeniftPlanEditComponent,  { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.abs.getAll().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
    });
  }


  add() {
    const catalogue = new CatalogueBenefitsModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }

}
