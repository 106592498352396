import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';


import { CatalogueSectionsModel } from 'src/app/core/quotation/_models/catalogue-section.model';
import { CatalogueSectionService } from 'src/app/core/quotation/_services/section-catalogue.service';


@Component({
  selector: 'kt-section-catalogue-edit',
  templateUrl: './section-catalogue-edit.component.html',
  styleUrls: []
})
export class SectionCatalogueEditComponent implements OnInit, OnDestroy {
  benefits: CatalogueSectionsModel;
  hasFormErrors = false;
  benefitsForm: FormGroup;
  viewLoading = false;

  constructor(
    public dialogRef: MatDialogRef<SectionCatalogueEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private cbs: CatalogueSectionService
  ) { }

  private componentSubscriptions: Subscription;

  ngOnInit(): void {
    this.benefits = this.data.benefits;
    this.createForm();
    this.benefits.Seccion.length === 0 ? this.benefitsForm.get('Estado').setValue('1') : null;
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.benefits.IdSeccion > 0) {
      return 'Editar Sección';
    }

    return 'Agregar Sección';
  }

  createForm() {
    this.benefitsForm = this.fb.group({
      DescBeneficio: [this.benefits.Seccion, Validators.required],
      Estado: [this.benefits.Estado.toString(), Validators.required]
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.benefitsForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  save() {
    const controls = this.benefitsForm.controls;

    if (this.benefitsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    if (this.benefits.IdSeccion > 0) {
      const benefitPreparate = new CatalogueSectionsModel();
      benefitPreparate.clear();
      benefitPreparate.Seccion = controls.DescBeneficio.value;
      benefitPreparate.IdSeccion = this.benefits.IdSeccion;
      benefitPreparate.Estado = parseInt(controls.Estado.value);
      benefitPreparate.EstadoDesc = this.benefits.EstadoDesc;

      this.update(benefitPreparate);
    } else {
      const benefitPreparate = new CatalogueSectionsModel();
      benefitPreparate.clear();
      benefitPreparate.Seccion = controls.DescBeneficio.value;
      benefitPreparate.Estado =parseInt(controls.Estado.value);


      this.create(benefitPreparate);
    }
  }

  preparate(controls) {
    const benefitPreparate = new CatalogueSectionsModel();
    benefitPreparate.clear();
    benefitPreparate.Seccion = controls.DescBeneficio;
    benefitPreparate.Estado = this.benefits.Estado;

    return benefitPreparate;
  }

  create(benefits: CatalogueSectionsModel) {

    this.cbs.add(benefits).subscribe(next => {
      console.log(next);
      const message = `El nuevo catalogo fue agregado con exito.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e => {
      this.hasFormErrors = true;
    });
  }

  update(benefits: CatalogueSectionsModel) {
    console.log(benefits);
    this.cbs.update(benefits).subscribe(next => {
      console.log(next);
      const message = 'El catalogo ' + benefits.IdSeccion + ' fue actualizado con exito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e => {
      this.hasFormErrors = true;
    });
  }

}
