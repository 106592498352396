
<div class="mat-table-wrapper">
    <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
      {{ ms }}
    </kt-alert>
      <mat-table [dataSource]="dataSource" class="lmat-elevation-z8">
  
      <!-- Codigo Column -->
      <ng-container matColumnDef="IdCategoria">
        <mat-header-cell *matHeaderCellDef class="mat-column mat-column-id"> Cod. </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-id"> {{element.IdCategoria}} </mat-cell>
      </ng-container>
  
      <!-- Categoria Column -->
      <ng-container matColumnDef="DescCategoria">
        <mat-header-cell *matHeaderCellDef class="mat-column mat-column-select"> Categoria </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-select"> {{element.DescCategoria}} </mat-cell>
      </ng-container>
  
      <!-- Descripcion Column -->
      <ng-container matColumnDef="Descripcion">
        <mat-header-cell *matHeaderCellDef class="mat-column mat-column-select"> Descripción </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-select"> {{element.Descripcion}} </mat-cell>
      </ng-container>
  
      <!-- AplicaBeneficio Column -->
      <ng-container matColumnDef="AplicaBeneficio">
        <mat-header-cell *matHeaderCellDef> Con Beneficio </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox"> 
          <span  class="label label-lg label-light-{{ (element.AplicaBeneficio)?'success':'dark' }} label-inline">{{ (element.AplicaBeneficio)?'Si':'No' }}</span>
        </mat-cell>
      </ng-container>
  
      <!-- Titular Column -->
    <!--       <ng-container matColumnDef="Titular">
        <mat-header-cell *matHeaderCellDef> 32 - 49 </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Titular}} </mat-cell>
      </ng-container> -->
  
      <!-- Hombre Column -->
      <ng-container matColumnDef="Hombre">
        <mat-header-cell *matHeaderCellDef> Hombre </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox">
          <span  class="label label-lg label-light-{{ (element.Hombre)?'success':'dark' }} label-inline">{{ (element.Hombre)?'Si':'No' }}</span>
        </mat-cell>
      </ng-container>
  
      <!-- Mujer Column -->
      <ng-container matColumnDef="Mujer">
        <mat-header-cell *matHeaderCellDef> Mujer </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox"> 
          <span  class="label label-lg label-light-{{ (element.Mujer)?'success':'dark' }} label-inline">{{ (element.Mujer)?'Si':'No' }}</span>
        </mat-cell>
      </ng-container>
  
      <!-- TitularDesde Column -->
      <ng-container matColumnDef="TitularDesde">
        <mat-header-cell *matHeaderCellDef> Titular Desde (edad)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.TitularDesde}} </mat-cell>
      </ng-container>
  
      <!-- TitularHasta Column -->
      <ng-container matColumnDef="TitularHasta">
        <mat-header-cell *matHeaderCellDef> Titular Hasta (edad)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.TitularHasta}} </mat-cell>
      </ng-container>

        <!-- Conyuge Column -->
        <ng-container matColumnDef="Conyugue">
         <mat-header-cell *matHeaderCellDef> Cónyuge</mat-header-cell>
         <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox">
          <span  class="label label-lg label-light-{{ (element.Conyugue)?'success':'dark' }} label-inline">{{ (element.Conyugue)?'Si':'No' }}</span>
         </mat-cell>
        </ng-container>

        <!-- Maternidad Column -->
        <ng-container matColumnDef="Maternidad">
         <mat-header-cell *matHeaderCellDef> Maternidad</mat-header-cell>
         <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox">
          <span  class="label label-lg label-light-{{ (element.Maternidad)?'success':'dark' }} label-inline">{{ (element.Maternidad)?'Si':'No' }}</span>
         </mat-cell>
        </ng-container>

        <!-- Dependientes Column -->
        <ng-container matColumnDef="Dependientes">
         <mat-header-cell *matHeaderCellDef > Dependientes</mat-header-cell>
         <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox">
          <span  class="label label-lg label-light-{{ (element.Dependientes)?'success':'dark' }} label-inline">{{ (element.Dependientes)?'Si':'No' }}</span>
         </mat-cell>
        </ng-container>


        <!-- DepentiendesDesde Column -->
        <ng-container matColumnDef="DependienteDesde">
         <mat-header-cell *matHeaderCellDef> Dependiente Desde (edad)</mat-header-cell>
         <mat-cell *matCellDef="let element"> {{element.DependienteDesde}} </mat-cell>
        </ng-container>

        <!-- DependientesHasta Column -->
        <ng-container matColumnDef="DependientesHasta">
         <mat-header-cell *matHeaderCellDef> Depentiende Hasta (edad)</mat-header-cell>
         <mat-cell *matCellDef="let element"> {{element.DependientesHasta}} </mat-cell>
        </ng-container>

        <!-- PersonaDesde Column -->
        <ng-container matColumnDef="PersonasDesde">
         <mat-header-cell *matHeaderCellDef> Persona Desde (edad)</mat-header-cell>
         <mat-cell *matCellDef="let element"> {{element.PersonasDesde}} </mat-cell>
        </ng-container>
   
        <!-- PersonaHasta Column -->
        <ng-container matColumnDef="PersonasHasta">
         <mat-header-cell *matHeaderCellDef> Persona Hasta (edad)</mat-header-cell>
         <mat-cell *matCellDef="let element"> {{element.PersonasHasta}} </mat-cell>
        </ng-container>        
  
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  
      </mat-table>
  
  </div>