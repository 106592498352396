import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {RateKidsPutModel} from '../../../../../../../core/quotation/_models/rate-kids-put.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlansKidsRateService} from '../../../../../../../core/quotation/_services/product_subs/plans-kids-rate.service';


@Component({
  selector: 'kt-kids-rate',
  templateUrl: './kids-rate.component.html',
  styles: [
  ]
})
export class KidsRateComponent implements OnInit {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  // Definimos la variable de datos que seran pintados en la tabla;
  dataSource = new MatTableDataSource();
  // Iniciamos el controlador del formulario
  formGroup: FormGroup;
  // Iniciamos la variable que mantendra la informacion temporal antes de ir al servidor.
  RateNSoloForEdit: RateKidsPutModel; // OJO: Que no se confunda = con  : El primero asigna un valor mientras que : asigna un modelo.
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  rangos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public load = this.loadRange.bind(this);
  public save = this.saveUpdated.bind(this);

  formBackup: any;
  columnRange: string [] = [];
  displayedColumns: string [] = [];

  constructor(private plansNSoloServices: PlansKidsRateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService) { }

    ngOnInit(): void {

      // Trae el ID del producto padre.
      this.productId$.subscribe(res => {
        if (!res) {
          return;
        }

        this.productId = res;
        //this.loadData(res);
        //this.createFormGroup();
      });
    }

    ngOnDestroy() {
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }

    // Carga los datos y los setea en la tabla.
    loadData(id){
      this.plansNSoloServices.get(id).subscribe( next =>{
        const RateNSolo: RateKidsPutModel[] = [];
        var element: RateKidsPutModel = new RateKidsPutModel();
        element.IdTarifaSolo = next.IdTarifaSolo;
        element.IdPlan = next.IdPlan;
        element.RangoN1 = next.Rangos[0].Valor;
        element.RangoN2 = next.Rangos[1].Valor;
        RateNSolo.push(element);
        this.dataSource = new MatTableDataSource(RateNSolo);
      });
    }

    public getRango(): Observable<any[]>{
      return this.rangos$.asObservable();
    }

    loadRange(id: number){
      return this.plansNSoloServices.getTarifaRango(id);
    }

    saveUpdated(data: any[]) {
      return this.plansNSoloServices.updateRangoKids(data);
    }


    onAlertClose($event) {
      this.hasFormErrors = false;
      this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
      this.type = 'warn';
    }

}
