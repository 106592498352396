import { BaseModel } from '../../_base/crud';

export class CharacteristicModel extends BaseModel {
    IdCaracteristica: number;
    Caracteristica: string;
    Estado: number;



    clear() {
        this.IdCaracteristica = 0;
        this.Caracteristica = '';
        this.Estado = 0;
    }
}
