import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {PlanStateModel} from '../../_models/plan-state.model';
import {HttpUtilsService} from '../../../_base/crud';
import {GenderRateModel} from '../../_models/gender-rate.model';
import { RateModel } from '../../_models/rate.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';

@Injectable({
  providedIn: 'root'
})

export class PlansGenderRateService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<GenderRateModel[]>(URL_API.url + 'GetTarifasGenerosPlan?idPlan=' + id, options);
  }

  update(Gender: GenderRateModel): Observable<any> {
    const GenderRate: GenderRateModel[] = [];
    GenderRate.push(Gender);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifasGenero', GenderRate, {headers: httpHeaders});
  }

  updateRangoGender(data: any[]): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaRangoEdad', data, {
      headers: httpHeaders
    });
  }
 
  getTarifaRango(id: number){
    const options = {};
    return this.http.get<RateModel[]>(URL_API.url + 'GetTarifaRangoGenero?idplan=' + id, options);
  }

}
