// Angular
import {Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../../core/_base/crud';
// Services and Models
import {
	ProductModel,
	ProductsDataSource,
	ProductsPageRequested,
	selectProductsPageLastQuery
} from '../../../../../../core/quotation';
import {PlanStateModel} from '../../../../../../core/quotation/_models/plan-state.model';
import {ProductsService} from '../../../../../../core/quotation';

// Table with EDIT item in new page
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-products-list',
	templateUrl: './products-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsListComponent implements OnInit, OnDestroy {
	// Table fields
	dataSource: ProductsDataSource;
	displayedColumns = ['IdPlan', 'DescPlan', 'VersionNum', 'DescProducto', 'Descripcion', 'AplicaBeneficio', 'IdTipoTarifa', 'Region', 'Estado', 'actions'];
	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
	// Filter fields
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	filterStatus = '';
	filterCondition = '';
	lastQuery: QueryParamsModel;
	// Selection
	selection = new SelectionModel<ProductModel>(true, []);
	productsResult: ProductModel[] = [];
	private subscriptions: Subscription[] = [];

  alertType = 'danger';

	/**
	 * Component constructor
	 *
	 * @param dialog: MatDialog
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: LayoutUtilsService
	 * @param store: Store<AppState>
	 */
	constructor(public dialog: MatDialog,
		           private activatedRoute: ActivatedRoute,
		           private router: Router,
		           private subheaderService: SubheaderService,
               private route: ActivatedRoute,
               private ps: ProductsService,
		           private layoutUtilsService: LayoutUtilsService,
		           private store: Store<AppState>,
              private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(this.subheaderService.upload$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){

          this.loadProductsList();
          this.cdr.detectChanges();
        }
      });
    }));
  }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// If the user changes the sort order, reset back to the first page.
		const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
		this.subscriptions.push(sortSubscription);

		/* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
		const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadProductsList())
		)
		.subscribe();
		this.subscriptions.push(paginatorSubscriptions);

		// Filtration, bind to searchInput
		const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(150),
			distinctUntilChanged(),
			tap(() => {
				this.paginator.pageIndex = 0;
				this.loadProductsList();
			})
		)
		.subscribe();
		this.subscriptions.push(searchSubscription);

		// Set title to page breadCrumbs
		this.subheaderService.setTitle('Productos', 'Estos son sus productos');

		// Init DataSource
		this.dataSource = new ProductsDataSource(this.store);
		const entitiesSubscription = this.dataSource.entitySubject.pipe(
			skip(1),
			distinctUntilChanged()
		).subscribe(res => {
			this.productsResult = res;
		});
		this.subscriptions.push(entitiesSubscription);
		const lastQuerySubscription = this.store.pipe(select(selectProductsPageLastQuery)).subscribe(res => this.lastQuery = res);
		// Load last query from store
		this.subscriptions.push(lastQuerySubscription);

		// Read from URL itemId, for restore previous state
		const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
			if (params.id) {
				this.restoreState(this.lastQuery, +params.id);
			}

			// First load
				this.loadProductsList();
		});
		this.subscriptions.push(routeSubscription);
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}

	/**
	 * Load Products List
	 */
	loadProductsList() {
		this.selection.clear();
		const queryParams = new QueryParamsModel(
			this.filterConfiguration(),
			this.sort.direction,
			this.sort.active,
			this.paginator.pageIndex,
			this.paginator.pageSize
		);
		// Call request from server
		this.store.dispatch(new ProductsPageRequested({ page: queryParams }));
		this.selection.clear();
	}

	/**
	 * Returns object for filter
	 */
	filterConfiguration(): any {
		const filter: any = {};
		const searchText: string = this.searchInput.nativeElement.value;

		if (this.filterStatus && this.filterStatus.length > 0) {
			filter.status = +this.filterStatus;
		}

		if (this.filterCondition && this.filterCondition.length > 0) {
			filter.condition = +this.filterCondition;
		}

		filter.DescPlan = searchText;
		filter.DescTipoTarifa = searchText;

		return filter;
	}

	/**
	 * Restore state
	 *
	 * @param queryParams: QueryParamsModel
	 * @param id: number
	 */
	restoreState(queryParams: QueryParamsModel, id: number) {

		if (!queryParams.filter) {
			return;
		}

		if ('condition' in queryParams.filter) {
			this.filterCondition = queryParams.filter.condition.toString();
		}

		if ('status' in queryParams.filter) {
			this.filterStatus = queryParams.filter.status.toString();
		}

		if (queryParams.filter.model) {
			this.searchInput.nativeElement.value = queryParams.filter.model;
		}
	}

	/** ACTIONS */
	/**
	 * Delete product
	 *
	 * @param _item: ProductModel
	 */

	enable(p: ProductModel){
    const _state = new PlanStateModel();
    _state.clear();
    _state.IdPlan = p.IdPlan;
    _state.Estado = 1;
    _state.isBorrador = false;

    const msg = 'El plan ' + p.DescPlan + ' ha sido activado';

    this.update(_state, msg);
  }

  disable(p: ProductModel){
    const _state = new PlanStateModel();
    _state.clear();
    _state.IdPlan = p.IdPlan;
    _state.Estado = 2;
    _state.isBorrador = false;

    const msg = 'El plan ' + p.DescPlan + ' ha sido desactivado';

    this.update(_state, msg);
  }

  share(p: ProductModel){
	  const _state = new PlanStateModel();
	  _state.clear();
	  _state.IdPlan = p.IdPlan;
	  _state.Estado = 1;
	  _state.isBorrador = true;

    const msg = p.DescPlan + ' fue publico con exito';

    this.update(_state, msg);
  }

  update(p: PlanStateModel, msg: string){
    this.ps.update(p).subscribe(v => {
      this.loadProductsList();

      this.layoutUtilsService.showActionNotification(msg, MessageType.Read, 10000, true, false);
    }, error =>{
      if(error.error.Mensaje){
        this.layoutUtilsService.showActionNotification(error.error.Mensaje, MessageType.Read, 10000, true, false);
      }else{
        this.layoutUtilsService.showActionNotification('Ocurrio un problema intente de nuevo.', MessageType.Read, 10000, true, false);
      }
    });

  }

  crate(p: PlanStateModel){

    const _state = new PlanStateModel();
    _state.clear();
    _state.IdPlan = p.IdPlan;
    _state.isBorrador = false;

    this.ps.create(_state).subscribe(v => {
      this.loadProductsList();
      const url = `/productos/editar/plan/${v.IdPlan}`;
      this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });

    }, error =>{
      if(error.error.Mensaje){
        this.layoutUtilsService.showActionNotification(error.error.Mensaje, MessageType.Read, 10000, true, false);
      }else{
        this.layoutUtilsService.showActionNotification('Ocurrio un problema intente de nuevo.', MessageType.Read, 10000, true, false);
      }
    });

  }


	/**
	 * Redirect to edit page
	 *
	 * @param id: any
	 */
	editProduct(id) {
		this.router.navigate(['../editar/plan/', id], { relativeTo: this.activatedRoute });
	}


  closeAlertCe() {
  }

	/**
	 * Check all rows are selected
	 */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.productsResult.length;
		return numSelected === numRows;
	}

	/**
	 * Selects all rows if they are not all selected; otherwise clear selection
	 */
	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.productsResult.forEach(row => this.selection.select(row));
		}
	}


  getItemTarifaString(status: number = 1): string {
    switch (status) {
      case 1:
        return 'Por Categoria';
      case 2:
        return 'Por Rango de Edad';
      case 3:
        return 'Por Genero';
	  case 4:
		return 'Por Cada Edad';
    }
    return '';
  }

	/* UI */
	/**
	 * Returns status string
	 *
	 * @param status: number
	 */
	getItemStatusString(status: number = 1): string {
		switch (status) {
			case 1:
				return 'Activo';
			case 2:
				return 'Inactivo';
      case 3:
        return 'Borrador';
		}
		return '';
	}

	getItemRegionString(id: number) {
		switch (id) {
			case 1:
				return 'Costa';
			case 2:
				return 'Sierra';
		}
		return '';
	}
	/**
	 * Returns CSS Class by status
	 *
	 * @param status: number
	 */
	getItemCssClassByStatus(status: number = 1): string {
		switch (status) {
			case 1:
				return 'success';
      case 2:
        return 'dark';
			case 3:
				return 'metal';
		}
		return '';
	}

	/**
	 * Rerurns condition string
	 *
	 * @param condition: number
	 */
	getItemConditionString(condition: boolean = true): string {
	  return (condition)? 'Aplica' : 'No Aplica';
	}

	/**
	 * Returns CSS Class by condition
	 *
	 * @param condition: number
	 */
	getItemCssClassByCondition(condition: number = 0): string {
    return (condition)? 'success' : 'danger';
	}
}
