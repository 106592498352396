<kt-portlet>
	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
				<div class="row align-items-center">
					<div class="col-md-4 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<input matInput placeholder="Buscar Plan" #searchInput placeholder="Buscar..."
								class="mat-form-field mat-form-field-fluid">
							<mat-hint align="start">
								<strong>Buscar</strong>
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</div>
			<!-- end::FILTERS -->

		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table-wrapper">
			<!-- Checkbox Column -->
			<!-- Table with selection -->
			<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="id" matSortDirection="asc" matSortDisableClear>
				<!-- Checkbox Column -->

				<ng-container matColumnDef="IdPlan">
					<mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-vinCode">Código plan
					</mat-header-cell>
					<mat-cell *matCellDef="let product" class="mat-column-vinCode">{{product.IdPlan}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="DescPlan">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Plan</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.DescPlan}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="VersionNum">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Version</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.VersionNum}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="DescProducto">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Producto</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.DescProducto}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="Descripcion">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Decripción</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.Descripcion}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="AplicaBeneficio">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Titular con beneficio</mat-header-cell>
					<mat-cell *matCellDef="let product" >
            <span
              class="font-bold font-{{ getItemCssClassByCondition(product.AplicaBeneficio) }}">{{ getItemConditionString(product.AplicaBeneficio) }}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="IdTipoTarifa">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Tarifacion</mat-header-cell>
					<mat-cell *matCellDef="let product">{{ getItemTarifaString(product.IdTipoTarifa) }}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="Region">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Región</mat-header-cell>
					<mat-cell *matCellDef="let product"> {{getItemRegionString(product.IdRegion)}} </mat-cell>
				</ng-container>
				
				<ng-container matColumnDef="Estado">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Estado</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<span
							class="label label-lg label-light-{{ getItemCssClassByStatus(product.Estado) }} label-inline">{{ getItemStatusString(product.Estado) }}</span>
					</mat-cell>
				</ng-container>


				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>
						Acciones
					</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<button *ngIf="product.Estado === 3" type="button" routerLink="/productos/editar/plan/{{product.IdPlan}}" mat-icon-button color="primary"
							matTooltip="Editar Plan">
							<mat-icon>create</mat-icon>
						</button>
            <button *ngIf="product.Estado !== 3" type="button" routerLink="/productos/editar/plan/{{product.IdPlan}}" mat-icon-button color="primary"
                    matTooltip="Ver plan">
              <mat-icon>visibility</mat-icon>
            </button>
						<button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button (click)="enable(product)" *ngIf="product.Estado === 2" mat-menu-item type="button">
								<mat-icon>done_outline</mat-icon>
								<span>Activar</span>
							</button>
              <button (click)="disable(product)" *ngIf="product.Estado === 1" mat-menu-item type="button">
                <mat-icon>remove_circle</mat-icon>
                <span>Desactivar</span>
              </button>
              <button (click)="share(product)" *ngIf="product.Estado === 3" mat-menu-item type="button">
                <mat-icon>share</mat-icon>
                <span>Publicar</span>
              </button>
              <button *ngIf="product.Estado !== 2" (click)="crate(product)" mat-menu-item type="button">
                <mat-icon>subject</mat-icon>
                <span>Crear uno nuevo a partir de este</span>
              </button>
						</mat-menu>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No se encontraron productos</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Por favor espere....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>
