<div class="container">
  <div class="card card-custom card-stretch">
    <!--begin::Form-->
    <form class="form" [formGroup]="settingsForm">
      <!--begin::Body-->
      <div class="card-body">
        <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
          {{ ms }}
        </kt-alert>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_Des_Contado.Descripcion }}</label>
              <input type="number" min="0" class="form-control" formControlName="Conf_Des_Contado" name="DescBeneficio">
              <span class="form-text text-muted">{{ Conf_Des_Contado.Observacion }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_SSC.Descripcion }}</label>
              <input type="number" min="0" formControlName="Conf_SSC" class="form-control"/>
              <span class="form-text text-muted">{{ Conf_SSC.Observacion }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ UsuarioInhabilitado.Descripcion }}</label>
              <input type="number" min="0" class="form-control" formControlName="UsuarioInhabilitado" name="DescBeneficio">
              <span class="form-text text-muted">{{ UsuarioInhabilitado.Observacion }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ PeriodoPrueba.Descripcion }}</label>
              <input type="number" min="0" formControlName="PeriodoPrueba" class="form-control"/>
              <span class="form-text text-muted">{{ PeriodoPrueba.Observacion }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_Valor_Max_Dependientes.Descripcion }}</label>
              <input type="number" min="0" class="form-control" formControlName="Conf_Valor_Max_Dependientes" name="DescBeneficio">
              <span class="form-text text-muted">{{ Conf_Valor_Max_Dependientes.Observacion }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_plan_comparar.Descripcion }}</label>
              <input type="number" min="0" formControlName="Conf_plan_comparar" class="form-control"/>
              <span class="form-text text-muted">{{ Conf_plan_comparar.Observacion }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_edad_dep_max.Descripcion }}</label>
              <input type="number" min="0" class="form-control" formControlName="Conf_edad_dep_max" name="DescBeneficio">
              <span class="form-text text-muted">{{ Conf_edad_dep_max.Observacion }}</span>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_Edad_Cotiza.Descripcion }}</label>
              <input type="number" min="0" formControlName="Conf_Edad_Cotiza" class="form-control"/>
              <span class="form-text text-muted">{{ Conf_Edad_Cotiza.Observacion }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>{{ Conf_edad_ninosolo_max.Descripcion }}</label>
              <input type="number" min="0" class="form-control" formControlName="Conf_edad_ninosolo_max" name="DescBeneficio">
              <span class="form-text text-muted">{{ Conf_edad_ninosolo_max.Observacion }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="form-group">
              <label>Declaraciones Legales</label>
              <textarea class="form-control" formControlName="Conf_Observaciones_Pdf" rows="5"></textarea>
              <span class="form-text text-muted">Esto sera visible al pie de las cotizaciones PDF</span>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</div>
