<kt-portlet>
	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
				<div class="row align-items-center">
          <div class="col-md-2 mr-5">
            <mat-form-field>
              <mat-select [(value)]="filterAngency" (selectionChange)="filter($event)">
                <mat-option value="*">Todas</mat-option>
                <ng-container *ngFor="let t of brokers">
                  <mat-option [value]="t.IdBroker">
                    {{ t.Broker }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-hint align="start">
                <strong>Buscar por Agencia</strong>
              </mat-hint>
            </mat-form-field>
          </div>

          <!--<div class="col-md-2 ml-5 mr-5">
            <mat-form-field>
              <mat-select [(value)]="filterStatus" (change)="filter()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="Activo">Activo</mat-option>
                <mat-option value="Inactivo">Inactivo</mat-option>
                <mat-option value="Denegado">Denegado</mat-option>
                <mat-option value="Pendiente">Pendiente</mat-option>
                <mat-option value="Suspendido">Suspendido</mat-option>
              </mat-select>
              <mat-hint align="start">
                <strong>Filtrar</strong> por estado
              </mat-hint>
            </mat-form-field>
          </div>-->

          <div class="col-md-2 ml-4 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Buscar Usuario" #input [(value)]="filterGeneral" (keyup)="applyFilter($event)"/>
              <mat-hint align="start">
                <strong>Busqueda General</strong>
              </mat-hint>
            </mat-form-field>
          </div>

        </div>
      </div>
      <!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
			<div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}">
				<!-- We show 'Group Actions' div if smth are selected -->
				<div class="col-xl-12">
					<div class="form-group form-group-inline">
						<div class="form-label form-label-no-wrap">
							<label class="font-bold font-danger">
								<span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
								{{ selection.selected.length }}
							</label>
							<!-- selectedCountsTitle => function from codeBehind (customer-list.component.ts file) -->
							<!-- selectedCountsTitle => just returns title of selected items count -->
							<!-- for example: Selected records count: 4 -->
						</div>
						<div>
							<ng-template ngxPermissionsOnly="canDeleteECommerceData">
								<button mat-raised-button color="warn"
									matTooltip="Delete selected customers" class="mat-button-mt-4">
									<mat-icon>delete</mat-icon>
									Delete All
								</button>&nbsp;
								<!-- Call 'delete-entity-dialog' from _shared folder -->
							</ng-template>

							<button (click)="fetchCustomers()" mat-raised-button matTooltip="Fetch selected customers"
								class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected
							</button>&nbsp;
							<!-- Call 'fetch-entity-dialog' from _shared folder -->
							<button (click)="updateStatusForCustomers()" mat-raised-button
								matTooltip="Update status for selected customers" class="mat-button-mt-4">
								<mat-icon>update</mat-icon>
								Update status
							</button><!-- Call 'update-stated-dialog' from _shared folder -->
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->

      <mat-table [dataSource]="dataSource" matSort>
        <!-- ID Column -->
        <ng-container matColumnDef="Identificacion">
          <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
          <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Identificación</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="mat-column">{{customer.Identificacion}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Broker">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Bróker</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="mat-column">{{customer.NombreAgencia}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Nombres">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Nombres</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="mat-column">{{customer.Nombres}} {{customer.Apellidos}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Correo">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Correo</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="email-cell">
            <a href="mailto:{{customer.Correo}}" class="font-weight-bold">{{customer.Correo}}</a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="IdEstado">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-checkbox">Estado</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="mat-column mat-column-checkbox">
						<span
              class="label label-lg label-light-{{ getItemCssClassByStatus(customer.IdEstado) }} label-inline">{{  getItemStatusString(customer.IdEstado) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="mat-column mat-column-actions">Acciones</mat-header-cell>
          <mat-cell *matCellDef="let customer" class="mat-column mat-column-actions">
            <button mat-icon-button color="primary" matTooltip="Editar"
                    (click)="editCustomer(customer)">
              <mat-icon>create</mat-icon>
            </button>&nbsp;

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]"></mat-paginator>


		<!-- start: BOTTOM -->
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->

</kt-portlet>
