import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../../../core/auth';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {QuoteService} from '../../../../../../../core/quotation/_services/quote.service';
import {PdkMaker} from '../../../../../../../core/quotation/_services/pdf-make';
import { Injectable } from '@angular/core';

@Component({
  selector: 'kt-send-mail',
  templateUrl: './send-mail.component.html',
  styles: [
  ]
})
export class SendMailComponent implements OnInit {
  viewLoading = false;
  sendQ: FormGroup;
  constructor(
    private fb: FormBuilder,
    private pdfService: PdkMaker,
    private qs: QuoteService,
    private us: AuthService,
    public dialogRef: MatDialogRef<SendMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    let ms = 'Estimado ' + this.data.q.Cliente + ',\n' +
      '\n' +
      'Adjunto sírvase encontrar cotización de servicios de medicina prepagada.\n' +
      '\n' +
      'Saludos Cordiales.\n'+
      this.data.q.Agente + '\n' +
      this.data.q.Agencia;
    this.createForm(ms);
  }

  createForm(ms) {
    this.sendQ = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ]),
      ],
      msj: [ms, Validators.required]
    });
  }

  onYesClick() {
    const controls = this.sendQ.controls;

    // check form
    if (this.sendQ.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.viewLoading = true;

    this.pdfService.generatePdf2(this.data.q, controls.email.value, 'Cotizacion Asisken', controls.msj.value).then(son => {
      console.log('son', son);
    })
      .catch(err => {
        console.log(err);
      });
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.sendQ.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}
