import { BaseModel } from '../../_base/crud';

export class PoolRateModel extends BaseModel {
  
    IdTarifaPool: number;
    IdPlan: number;
    IdCategoria: number;
    Categoria: string;
    IdTarifaGen: number;
    CantidadMin: number;
    Valor: number;

    clear() {
        this.IdTarifaPool = 0;
        this.IdPlan = 0;
        this.IdCategoria = 0;
        this.Categoria = '';
        this.IdTarifaGen = 0;
        this.CantidadMin = 0;
        this.Valor = 0;
      }
}