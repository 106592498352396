import { CharecteristicsModel } from './../_models/charecteristics.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CharecteristicsTemplateModel} from '../_models/charecteristics-template.model';

const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
  providedIn: 'root'
})

export class CharecteristicsService {
  constructor(private http: HttpClient) {}

  get(idTemplate: number){
    const options = {};
    return this.http.get<CharecteristicsModel[]>(URL_API.url + 'GetPlantillasCaracteristicas?tipoPlantilla=' + idTemplate , options);
  }

  add(charecteristic: CharecteristicsModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<CharecteristicsModel>(URL_API.url + 'IngPlantillasCaracteristicas', charecteristic, {headers: httpHeaders});
  }

  update(charecteristic: CharecteristicsModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<CharecteristicsModel>(URL_API.url + 'ActPlantillasCaracteristicas', charecteristic, {headers: httpHeaders});
  }

  getSeccion(isPool: boolean){
    const options = {};
    return this.http.get<CharecteristicsModel[]>(URL_API.url + 'GetSecciones?isPool=' + isPool , options);
  }

  getTemplate(isPool: boolean = false) {
    const options = {};
    return this.http.get<CharecteristicsTemplateModel[]>(URL_API.url + 'ConsultarCaracteristicasSecciones?isPool=' + isPool , options);
  }

  addCharacteristics(charecteristic: CharecteristicsTemplateModel[]) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<CharecteristicsTemplateModel[]>(URL_API.url + 'GuardarCaracteristicasSecciones', charecteristic, { headers: httpHeaders });
  }
}
