
  <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
    {{ ms }}
  </kt-alert>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-6">
        <h4 class="card-title align-items-start flex-column"><span class="card-label font-weight-bolder text-dark">Desde 5 hasta 15 titulares</span></h4>
        <mat-table [dataSource]="dataSource">

          <!-- Numero Titulares Column -->
          <ng-container matColumnDef="Categoria">
            <mat-header-cell *matHeaderCellDef class="mat-column"> Tarifa </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column"> {{element.Categoria}} </mat-cell>
          </ng-container>

          <!-- Valor Column -->
          <ng-container matColumnDef="Valor">
            <mat-header-cell *matHeaderCellDef class="mat-column mat-column-checkbox mr-5"> Valor </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox mr-5">
              <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                <input matInput type="number" placeholder="Tarifa" formControlName="Valor">
                <mat-hint align="start">
                  Tarifa
                </mat-hint>
              </mat-form-field>
              <span *ngIf="!element._isEditMode">{{element.Valor}}</span>
            </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="acctions" >
            <mat-header-cell *matHeaderCellDef [hidden]="!editable" class="mat-column mat-column-checkbox mr-5">
              Accion
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox mr-5">
              <button *ngIf="!element._isEditMode && editable" (click)="editButtonOnClick(element)" [disabled]="isSwitchedToEditMode" type="button" mat-icon-button
                      color="primary"  matTooltip="Edit remark">
                <mat-icon>create</mat-icon>
              </button>
              <button *ngIf="element._isEditMode && !loadingAfterSubmit" (click)="saveUpdated(element)" mat-icon-button
                      color="primary" type="button"
                      matTooltip="Save changes">
                <mat-icon>done</mat-icon>
              </button>
              <mat-spinner *ngIf="element._isEditMode && loadingAfterSubmit" [diameter]="20"></mat-spinner>

              <button *ngIf="element._isEditMode" (click)="cancelEditButtonOnClick(element)" type="button" mat-icon-button color="warn"
                      matTooltip="Cancel changes">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-cell>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          </ng-container>
        </mat-table>
      </div>
      <div class="col-6">
        <h4 class="card-title align-items-start flex-column"><span class="card-label font-weight-bolder text-dark">Desde 16 titulares en adelante</span></h4>
          <mat-table [dataSource]="dataSource2">

            <!-- Numero Titulares Column -->
            <ng-container matColumnDef="Categoria">
              <mat-header-cell *matHeaderCellDef class="mat-column"> Tarifa </mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-column"> {{element.Categoria}} </mat-cell>
            </ng-container>

            <!-- Valor Column -->
            <ng-container matColumnDef="Valor">
              <mat-header-cell *matHeaderCellDef class="mat-column mat-column-checkbox mr-5"> Valor </mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox mr-5">
                <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                  <input matInput type="number" placeholder="Tarifa" formControlName="Valor">
                  <mat-hint align="start">
                    Tarifa
                  </mat-hint>
                </mat-form-field>
                <span *ngIf="!element._isEditMode">{{element.Valor}}</span>
              </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="acctions" >
              <mat-header-cell *matHeaderCellDef class="mat-column mat-column-checkbox mr-5">
                Accion
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="mat-column mat-column-checkbox mr-5">
                <button *ngIf="!element._isEditMode && editable" (click)="editButtonOnClick(element)" [disabled]="isSwitchedToEditMode" type="button" mat-icon-button
                        color="primary"  matTooltip="Edit remark">
                  <mat-icon>create</mat-icon>
                </button>
                <button *ngIf="element._isEditMode && !loadingAfterSubmit" (click)="saveUpdated(element)" mat-icon-button
                        color="primary" type="button"
                        matTooltip="Save changes">
                  <mat-icon>done</mat-icon>
                </button>
                <mat-spinner *ngIf="element._isEditMode && loadingAfterSubmit" [diameter]="20"></mat-spinner>

                <button *ngIf="element._isEditMode" (click)="cancelEditButtonOnClick(element)" type="button" mat-icon-button color="warn"
                        matTooltip="Cancel changes">
                  <mat-icon>clear</mat-icon>
                </button>
              </mat-cell>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            </ng-container>
          </mat-table>
      </div>
    </div>



  </form>
