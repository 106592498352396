import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ProductModel} from '../_models/product.model';
import {BackUserModel} from '../_models/_out/back-user.model';
import {Observable, of} from 'rxjs';
import {BackTarifasResModel} from '../_models/_in/back-tarifas-res.model';
import {PlanStateModel} from '../_models/plan-state.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {mergeMap} from 'rxjs/operators';
import {ProductCharecteristicsModel} from '../_models/product-charecteristics.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';

@Injectable({
  providedIn: 'root'
})

export class ProductsCharecteristicService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  getAll(id: number){
    const options = {};
    return this.http.get<ProductCharecteristicsModel[]>(URL_API.url + 'GetCaracteristicasNew?idPlan=' + id, options);
  }

  findProductCharacteristics(
    queryParams: QueryParamsModel,
    productId: number
  ): Observable<QueryResultsModel> {
    return this.getAll(productId).pipe(
      mergeMap(res => {
        const result = this.httpUtils.baseFilter(res, queryParams, []);
        return of(result);
      })
    );
  }

  updateProductCharacteristics(Characteristics: ProductCharecteristicsModel): Observable<any> {
    // Note: Add headers if needed (tokens/bearer)
    // tslint:disable-next-line:prefer-const
    let characters: ProductCharecteristicsModel[] = [];
    characters.push(Characteristics);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActCaracteristicas', characters, {
      headers: httpHeaders
    });
  }

}
