import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BackTarifaNormalModel} from '../_models/_out/back-tarifa-normal.model';
import {BackTarifasResModel} from '../_models/_in/back-tarifas-res.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'Cotizaciones';

@Injectable({
  providedIn: 'root'
})

export class RateService {
  constructor(private http: HttpClient) {}

  rate(_client: BackTarifaNormalModel){

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackTarifasResModel>(URL_API.url + 'GetCalculaTarifas', _client, {headers: httpHeaders});
  }

  ratePool(_data){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackTarifasResModel>(URL_API.url + 'GetCalculaTarifasPool', _data, {headers: httpHeaders});
  }



}
