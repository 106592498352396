import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {PoolRateModel} from '../../_models/pool-rate.model';
import {Observable} from 'rxjs';
import {BackTarifasResModel} from '../../_models/_in/back-tarifas-res.model';
import {PlanStateModel} from '../../_models/plan-state.model';
import {HttpUtilsService} from '../../../_base/crud';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';

@Injectable({
  providedIn: 'root'
})

export class PlansPoolRateService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<PoolRateModel[]>(URL_API.url + 'GetTarifasPoolPlan?idPlan=' + id, options);
  }

  update(Pool: PoolRateModel): Observable<any> {
    const PoolRate: PoolRateModel[] = [];
    PoolRate.push(Pool);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put<PoolRateModel>(URL_API.url + 'ActTarifasPool', PoolRate, {headers: httpHeaders});
  }

}
