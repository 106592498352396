
<div class="mat-table-wrapper">
  <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
    {{ ms }}
  </kt-alert>
    <mat-table [dataSource]="dataSource" class="lmat-elevation-z8">

    <!-- IdSeccion Column -->
    <ng-container matColumnDef="IdSeccion">
      <mat-header-cell *matHeaderCellDef class="mat-column mat-column-id"> Cod. </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-column mat-column-id"> {{element.IdSeccion}} </mat-cell>
    </ng-container>

    <!-- Seccion Column -->
    <ng-container matColumnDef="Seccion">
      <mat-header-cell *matHeaderCellDef class="mat-column mat-column-select"> Sección </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-column mat-column-select"> {{element.Seccion}} </mat-cell>
    </ng-container>

    <!-- IdPlantillaCaract Column -->
    <ng-container matColumnDef="IdPlantillaCaract">
      <mat-header-cell *matHeaderCellDef class="mat-column mat-column-id"> Cod. Plantilla </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-column mat-column-id"> {{element.IdPlantillaCaract}} </mat-cell>
    </ng-container>

    <!-- Descripcion Column -->
    <ng-container matColumnDef="Descripcion">
      <mat-header-cell *matHeaderCellDef class="mat-column mat-column-select"> Descripcion </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-column mat-column-select"> {{element.Descripcion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
      <mat-cell *matCellDef="let b">
        <button (click)="edit(b)" mat-icon-button color="primary" matTooltip="Editar">
          <mat-icon>create</mat-icon>
        </button>&nbsp;

      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    </mat-table>

</div>
