import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {PlanStateModel} from '../../_models/plan-state.model';
import {HttpUtilsService} from '../../../_base/crud';
import { AnotherRateModel } from '../../_models/another-rate.model';

const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';

@Injectable({
  providedIn: 'root'
})

export class PlansAnotherAgeModel {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<AnotherRateModel[]>(URL_API.url + 'AnotherAgeModel?idplan=' + id, options);
  }

  update(Age: AnotherRateModel): Observable<any> {
    const AnotherAge: AnotherRateModel[] = [];
    AnotherAge.push(Age);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActAnotherAgeModel ', AnotherAge, {headers: httpHeaders});
  }

}