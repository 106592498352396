<kt-portlet>
  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="form mb-3">
      <!-- start::FILTERS -->
      <!-- end::FILTERS -->

    </div>
    <!-- end::FILTERS & GROUP ACTIONS -->

    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->

    <mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="Identificacion">
        <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
        <mat-header-cell *matHeaderCellDef mat-sort-header>Identificación</mat-header-cell>
        <mat-cell *matCellDef="let customer">{{customer.Identificacion}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Nombres">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Nombres</mat-header-cell>
        <mat-cell *matCellDef="let customer">{{customer.Nombres}} {{customer.Apellidos}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="NombreAgencia">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Broker</mat-header-cell>
        <mat-cell *matCellDef="let customer" class="email-cell">
          {{customer.NombreAgencia}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
        <mat-cell *matCellDef="let customer">

          <button mat-icon-button color="primary" matTooltip="ver"
                  (click)="views(customer)">
            <mat-icon>visibility</mat-icon>
          </button>&nbsp;

          <button mat-icon-button color="accent" matTooltip="Aprobar"
                  (click)="approval(customer)">
            <mat-icon>done_outline</mat-icon>
          </button>&nbsp;

          <ng-template [ngIf]="customer.IdUsuario !== 1">
            <button mat-icon-button color="warn" matTooltip="Desaprobar" type="button"
                    (click)="deleteCustomer(customer)">
              <mat-icon>cancel</mat-icon>
            </button>&nbsp;
          </ng-template>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]"></mat-paginator>


    <!-- start: BOTTOM -->
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->

</kt-portlet>
