export class PlanStateModel  {

  IdPlan: number;
  Estado: number;
  isBorrador: boolean;

  clear() {
    this.IdPlan = 0;
    this.Estado = 0;
    this.isBorrador = false;
  }
}
