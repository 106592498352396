import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AnotherRateConfigModel } from 'src/app/core/quotation/_models/another-rate-config.model';
import { ForAnotherAgeRangeService } from 'src/app/core/quotation/_services/forAntoherAgeRange.service';
import { MessageType } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout';
import { ForDependentsEditComponent } from '../for-dependents-edit/for-dependents-edit.component';

@Component({
  selector: 'kt-for-dependents-list',
  templateUrl: './for-dependents-list.component.html',
  styles: [
  ]
})
export class ForDependentsListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<AnotherRateConfigModel>;
  displayedColumns = ['Id', 'Rango', 'Estado', 'actions'];
  private subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
              private subheaderService: SubheaderService,
              private cdr: ChangeDetectorRef,
              private forAnotherAgeRangeService: ForAnotherAgeRangeService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista de Rangos', 'Estas son sus rangos.');
    
    this.forAnotherAgeRangeService.getAll().subscribe( data => {
      this.dataSource = new MatTableDataSource(data);
      this.cdr.detectChanges();
    });

    this.subscriptions.push(this.subheaderService.setNewCatalogue$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if (bc) {
          this.add();
          this.subheaderService.setNewCatalogue(false);
        }
      });
    }));
  }
  
  /**
 * On Destroy
 */
   ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  add() {
    const catalogue = new AnotherRateConfigModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }

  edit(benefits: AnotherRateConfigModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdRango > 0 ? 'Agregar rango' : 'Nuevo rango';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdRango > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(ForDependentsEditComponent, { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.forAnotherAgeRangeService.getAll().subscribe(next => {
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      });
    });
  }
}
