export class SocialNetworks {

  constructor () {
    this.clear();
  }

  linkedin: string;
  facebook: string;
  twitter: string;
  instagram: string;

  clear() {
    this.linkedin = '';
    this.facebook = '';
    this.twitter = '';
    this.instagram = '';
  }
}
