import { BaseModel } from '../../_base/crud';

export class BrokerModel  extends BaseModel {
  IdBroker: number;
  IdTipo: number;
  Broker: string;
  Direccion: string;
  Estado: true;
  EstadoDescripcion: string;
  Telefono: string;
  Ruc: string;
  Mail: string;
  Descripcion: string;

  clear() {
    this.IdBroker = 0;
    this.IdTipo = 0;
    this.Broker = '';
    this.Direccion = '';
    this.Estado = true;
    this.EstadoDescripcion = '';
    this.Telefono = '';
    this.Ruc = '';
    this.Mail = '';
    this.Descripcion = '';
  }
}
