import {Component, Inject, OnInit} from '@angular/core';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {BrokerModel} from '../../../../../../core/quotation/_models/broker.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {AdditionalBenefitsService} from '../../../../../../core/quotation/_services/additional-benefits.service';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {ProductsService} from '../../../../../../core/quotation/_services/products.service';
import {BrokersService} from '../../../../../../core/quotation/_services/brokers.service';

@Component({
  selector: 'kt-broker-edit',
  templateUrl: './broker-edit.component.html',
  styles: [
  ]
})
export class BrokerEditComponent implements OnInit {
  broker: BrokerModel;
  viewLoading = false;
  hasFormErrors = false;
  brokerForm: FormGroup;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  constructor(public dialogRef: MatDialogRef<BrokerEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private layoutUtilsService: LayoutUtilsService,
              private bs: BrokersService,
              private cbs: CatalogueBenefitsService,
              private ps: ProductsService) { }

  ngOnInit(): void {
    console.log(this.data);
    this.broker = this.data.broker;
    console.log(this.broker);
    this.createForm();
  }

  getTitle(): string {
    if (this.broker.IdBroker > 0) {
      return 'Editar agencia';
    } else {
      this.brokerForm.get('statu').disable();
      return 'Agregar nueva agencia';
    }

  }

  createForm() {
      this.brokerForm = this.fb.group({
        ruc: [this.broker.Ruc, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        brokerName: [this.broker.Broker, Validators.required],
        brokerPhone: [this.broker.Telefono, Validators.required],
        des: [this.broker.Descripcion, Validators.required],
        address: [this.broker.Direccion, Validators.required],
        statu: [this.broker.Estado ],
        email: [this.broker.Mail, Validators.required]
      });
  }

  save() {
    this.hasFormErrors = false;
    const controls = this.brokerForm.controls;
    /** check form */
    if (this.brokerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    this.viewLoading = true;
    const _broker: BrokerModel = new BrokerModel();
    _broker.clear();
    if (this.broker.IdBroker > 0) {
      _broker.IdBroker = this.broker.IdBroker;
      _broker.Broker = controls.brokerName.value;
      _broker.Direccion = controls.address.value;
      _broker.Telefono = controls.brokerPhone.value;
      _broker.Ruc =  controls.ruc.value;
      _broker.Mail = controls.email.value;
      _broker.Descripcion = controls.des.value;
      _broker.Estado = controls.statu.value;
      this.update(_broker);
    } else {

      _broker.Broker = controls.brokerName.value;
      _broker.Direccion = controls.address.value;
      _broker.Telefono = controls.brokerPhone.value;
      _broker.Ruc =  controls.ruc.value;
      _broker.Mail = controls.email.value;
      _broker.Descripcion = controls.des.value;
      _broker.Estado = controls.statu.value;
      this.add(_broker);
    }
  }

  add(broker: BrokerModel){
    const res = this.validateRuc(broker.Ruc);
    if (res) {
      this.bs.add(broker).subscribe( next =>{
      console.log(next);
      const message = 'El broker ' + broker.Broker + ' fue agregado con exito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ broker, isEdit: false });
    }, e=> {
      console.log(e);
      if(e.error.Mensaje == 'El correo ya se encuentra registrado'){
        const message = e.error.Mensaje;
        this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
        this.viewLoading = false;
//        this.dialogRef.close({ broker, isEdit: false });
      } else {
        this.viewLoading = false;
        this.hasFormErrors = true;
      }
    });
    } else {
      const message = 'El ruc ' + broker.Ruc + ' no es válido';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.viewLoading = false;
    }
  }

  update(broker: BrokerModel){
    debugger;
    this.bs.update(broker).subscribe( next =>{
      console.log(next);
        const message = 'El broker ' + broker.Broker + ' fue actualizado con exito.';
        this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
        this.dialogRef.close({ broker, isEdit: false });
    }, e =>{
      console.log(e);
      if(e.error.Mensaje == 'El correo ya se encuentra registrado'){
        const message = e.error.Mensaje;
        this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
        this.viewLoading = false;
//        this.dialogRef.close({ broker, isEdit: false });
      } else {
        this.viewLoading = false;
        this.hasFormErrors = true;
      }

    } );
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.brokerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  validateRuc(ruc){
    if (ruc.substr(-3) == '000' || ruc.substr(-3) > '009') {
      return false;
    } else {
      if (ruc[2] < 6) {
        const provinceNum = parseInt(ruc.substring(0, 2));
        if(provinceNum < 25 && provinceNum > 0) {
          let total = 0;
          let arrayCoeficientes = [2,1,2,1,2,1,2,1,2];
          let digitVeriRec = parseInt(ruc.charAt(9) + '');
          for (let i = 0; i < arrayCoeficientes.length; i++) {
            let valor = parseInt(arrayCoeficientes[i] + '') * parseInt(ruc.charAt(i) + '');
            total = valor >= 10 ? total + (valor - 9) : total + valor;
            console.log(valor);
          }
          console.log(total);
          let digitVeriObt = total >= 10 ? (total % 10) != 0 ? 10 - (total % 10) : (total % 10) : total;
          if (digitVeriObt == digitVeriRec) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (ruc[2] == '6') {
        const provinceNum = parseInt(ruc.substring(0, 2));
        if(provinceNum < 25 && provinceNum > 0) {
          if (ruc[9] == '0'){
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (ruc[2] == '9'){
        const provinceNum = parseInt(ruc.substring(0, 2));
        if(provinceNum < 25 && provinceNum > 0) {
          let total = 0;
          let arrayCoeficientes = [4,3,2,7,6,5,4,3,2];
          let digitVeriRec = parseInt(ruc.charAt(9) + '');
          for (let i = 0; i < arrayCoeficientes.length; i++) {
            let valor = parseInt(arrayCoeficientes[i] + '') * parseInt(ruc.charAt(i) + '');
            total = valor >= 10 ? total + (valor - 9) : total + valor;
          }
          let digitVeriObt = total >= 10 ? (total % 11) != 0 ? 10 - (total % 11) : (total % 11) : total;
          // Se suprime la validacion del digito verificador
          return true;
          /*
          if (digitVeriObt == digitVeriRec) {
            return true;
          } else {
            return false;
          }
          */
        } else {
          return false;
        }
      }
    }
  }

}
