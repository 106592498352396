// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../../../../core/quotation/_services/users.service';
import {MessageType} from '../../../../../core/_base/crud';
import {AuthService} from '../../../../../core/auth';

@Component({
	selector: 'kt-delete-entity-dialog',
	templateUrl: './delete-entity-dialog.component.html'
})
export class DeleteEntityDialogComponent implements OnInit {
	// Public properties
	viewLoading = false;
  obser: FormGroup;

	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	constructor(
    private fb: FormBuilder,
		private us: AuthService,
		public dialogRef: MatDialogRef<DeleteEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,

	) { }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	  console.log(this.data);
    this.createForm();
	}

  createForm() {
    this.obser = this.fb.group({
      o: ['', Validators.required]
    });
  }

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		/* Server loading imitation. Remove this */
    this.viewLoading = true;
    const _user = this.data.userModel;
    _user.IdEstado = 3;
    _user.ObservacionEstado = this.obser.controls.o.value;


    this.us.desapproval(_user).subscribe(v => {
        this.dialogRef.close(true);
    }, error =>{
      this.viewLoading = false;
    });

	}
}
