<kt-portlet>
	<kt-portlet-body>
		<mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="product">
			<mat-tab label="Informacion basica">
				<ng-template matTabContent>
					<div *ngIf="product">
						<form [formGroup]="productForm" *ngIf="this.productForm" class="form form-group-seperator-dashed">

							<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
								Rayos! Ago salio mal por favor intente de nuevo.
							</kt-alert>

              <kt-alert *ngIf="saveError" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
                Este plan ya posee un Plan borrador generado.
              </kt-alert>

							<div class="form-section form-section-first">
								<div class="form-group">
									<div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Nombre del plan" formControlName="DescPlan" [readonly]='!isEditableEval()'>
                        <mat-error>
                          <strong>Ingrese un nombre para el plan</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Tipo de producto" formControlName="IdProducto" (selectionChange)="select()" [(ngModel)]="product.IdProducto" [disabled]='!isEditableEval()'>
                          <mat-option>--</mat-option>
                          <mat-option *ngFor="let t of Productos"  [value]="t.id">
                            {{ t.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          <strong>Identifique el tipo de producto</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Descripcion del plan" formControlName="Descripcion" [readonly]='!isEditableEval()'>
                        <mat-error>
                          <strong>Ingrese una descripcion para el plan</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isPool">
                      <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Region" formControlName="Region" [(ngModel)]="product.IdRegion" [disabled]="!isEditableEval()">
                          <mat-option>--</mat-option>
                          <mat-option *ngFor="let r of region" [value]="r.RegionID">{{r.Descripcion}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <strong>Ingrese una región</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isPool">
                      <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Texto Version" formControlName="VersionText" [readonly]='!isEditableEval()'>
                        <mat-error>
                          <strong>Ingrese texto para la versión</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

									</div>
								</div>
								<div class="form-group">
									<div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-checkbox class="example-margin" formControlName="ConBeneficios" [disabled]='!isEditableEval()'>Aplica niño solo</mat-checkbox>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Tarifación" formControlName="tipoTarifa" [(ngModel)]="product.IdTipoTarifa" (selectionChange)="selectNewTarifa()" [disabled]=!isEditableEval()>
                          <mat-option *ngFor="let t of Tarifas"  [value]="t.IdTipoTarifa">
                          {{ t.DescTipoTarifa }}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          <strong>Seleccione un tipo de Tarifa</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-checkbox class="example-margin" formControlName="ObligaMaternidad" [disabled]='!isEditableEval()'>Maternidad obligatoria</mat-checkbox>
                    </div>
									</div>
								</div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-form-field class="mat-form-field-fluid">
                        <input type="number" matInput formControlName="EdadMaxMaternidad" placeholder="Edad maxima de maternidad" [readonly]='!isEditableEval()'>
                        <mat-error>
                          <strong>Ingrese la edad maxima de maternidad.</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Tipo de caracteristicas" formControlName="TipoPlantilla"  [(ngModel)]="product.TipoPlantilla" (selectionChange)="selectNewTemplate()" [disabled]='!isEditableEval()'>
                          <mat-option>--</mat-option>
                          <mat-option *ngFor="let t of TipoCaracteristicas"  [value]="t.id">
                            {{ t.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          <strong>Identifique el tipo de plantillas para las caracteristicas</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-form-field class="mat-form-field-fluid">
                        <mat-select placeholder="Region" formControlName="Region" [(ngModel)]="product.IdRegion" [disabled]="!isEditableEval()">
                          <mat-option>--</mat-option>
                          <mat-option *ngFor="let r of region" [value]="r.RegionID">{{r.Descripcion}}</mat-option>
                        </mat-select>
                        <mat-error>
                          <strong>Ingrese una región</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="isIndividual">
                      <mat-form-field class="mat-form-field-fluid">
                        <input matInput placeholder="Texto Version" formControlName="VersionText" [readonly]='!isEditableEval()'>
                        <mat-error>
                          <strong>Ingrese texto para la versión</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
							</div>
						</form>
					</div>

					<!--end::Form-->
				</ng-template>
			</mat-tab>
      <mat-tab label="Caracteristicas" *ngIf="product.IdProducto === 1 && charecteristic" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-charecteristics [productId$]="productId$" [editable]="product.isBorrador"></kt-charecteristics>
        </ng-template>
      </mat-tab>
      <mat-tab label="Caracteristicas Pool" *ngIf="product.IdProducto === 2 && charecteristic" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-charecteristics [productId$]="productId$" [editable]="product.isBorrador"></kt-charecteristics>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa" *ngIf="product.IdProducto === 2" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-pool-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-pool-rate>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa Categoria" *ngIf="product.IdProducto === 1 && idTarifacion === 1" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-category-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-category-rate>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa Categoria niño solo" *ngIf="product.IdProducto === 1 && idTarifacion === 1 && product.ConBeneficios" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-kids-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-kids-rate>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa rango de edades" *ngIf="product.IdProducto === 1 && idTarifacion === 2" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-age-range [productId$]="productId$" [editable]="product.isBorrador"></kt-age-range>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa rango de edades niño solo" *ngIf="product.IdProducto === 1 && idTarifacion === 2" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-kids-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-kids-rate>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa por genero" *ngIf="product.IdProducto === 1 && idTarifacion === 3" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-gender-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-gender-rate>
        </ng-template>
      </mat-tab>
      <mat-tab label="Otras Tarifas" *ngIf="product.IdProducto === 1 && idTarifacion === 3" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-dependents [productId$]="productId$" [editable]="product.isBorrador"></kt-dependents>
        </ng-template>
      </mat-tab>
      <mat-tab label="Tarifa cada edad" *ngIf="product.IdProducto === 1 && idTarifacion === 4" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-for-age [productId$]="productId$" [editable]="product.isBorrador"></kt-for-age>
        </ng-template>
      </mat-tab>
      <mat-tab label="Otras" *ngIf="product.IdProducto === 1 && idTarifacion === 4" [disabled]="!product || !product.IdPlan">
        <ng-template matTabContent>
          <kt-another-rate [productId$]="productId$" [editable]="product.isBorrador"></kt-another-rate>
        </ng-template>
      </mat-tab>
		</mat-tab-group>
	</kt-portlet-body>
</kt-portlet>
