<div class="login-content d-flex flex-column pt-lg-0 pt-12">
  <!--begin::Logo-->
  <a href="#" class="login-logo pb-xl-20 pb-15">
    <img src="assets/media/logos/logo-4.png" class="max-h-70px" alt="">
  </a>
  <!--end::Logo-->
  <!--begin::Signin-->
  <div class="login-form" >
    <form class="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_forgot_form" action="" novalidate="novalidate" [formGroup]="forgotPasswordForm" autocomplete="off">
      <!--begin::Title-->
      <div class="pb-5 pb-lg-15">
        <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">{{ 'AUTH.FORGOT.TITLE' | translate }}</h3>
        <p class="text-muted font-weight-bold font-size-h4">{{ 'AUTH.FORGOT.DESC' | translate }}</p>
      </div>
      <!--end::Title-->
      <!--begin::Form group-->
      <kt-auth-notice></kt-auth-notice>
      <div class="form-group fv-plugins-icon-container">
        <input class="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6" type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" name="email" formControlName="email" autocomplete="off">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'email')">{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 5</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</div>
      </div>
      <!--end::Form group-->
      <!--begin::Form group-->
      <div class="form-group d-flex flex-wrap">
        <button (click)="submit()"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" id="kt_login_forgot_form_submit_button" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4">{{ "AUTH.FORGOT.BUTTON" | translate }}</button>
        <a routerLink="/entrar" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">{{ "AUTH.GENERAL.BACK_BUTTON" | translate }}</a>
      </div>
      <!--end::Form group-->
      <input type="hidden"><div></div>
    </form>
  </div>
  <!--end::Signin-->
</div>

