<!-- begin:: Header Topbar -->
<ng-container>
  <ng-container *ngIf=false>
    <!--begin: Search -->
    <div class="topbar-item" ngbTooltip="Buscar">
      <div [routerLink]="['/consultation']" class="btn btn-icon btn-clean btn-lg mr-1">
        <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="'./assets/media/svg/icons/General/Search.svg'"></span>
      </div>
    </div>
    <!--end: Search -->
  </ng-container>

  <ng-container *ngIf="notificationsDisplay">
    <!--begin: Notifications -->
      <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/svg/icons/Code/Compiling.svg'" [useSVG]="true"></kt-notification>
    <!--end: Notifications -->
  </ng-container>

  <ng-container *ngIf="quickActionsDisplay">
	  <!--begin: Quick actions -->
	  <kt-quick-action [bgImage]="'./assets/media/misc/bg-2.jpg'" [skin]="'dark'" [icon]="'./assets/media/svg/icons/Media/Equalizer.svg'" [useSVG]="true"></kt-quick-action>
	  <!--end: Quick actions -->
  </ng-container>

  <ng-container *ngIf="cartDisplay">
    <!--begin: My Cart -->
    <kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'" [icon]="'./assets/media/svg/icons/Shopping/Cart3.svg'" [useSVG]="true"></kt-cart>
    <!--end: My Cart -->
  </ng-container>

  <ng-container *ngIf=false>
    <!--begin: Quick panel toggle -->
    <div class="topbar-item" ngbTooltip="Lista de consultas">
      <div class="btn btn-icon btn-clean btn-lg mr-1" id="kt_quick_panel_toggle">
          <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="'./assets/media/svg/icons/Communication/Clipboard-list.svg'"></span>
      </div>
    </div>
    <!--end: Quick panel toggle -->
  </ng-container>

  <ng-container *ngIf="languagesDisplay">
    <!--begin: Language bar -->
    <kt-language-selector></kt-language-selector>
    <!--end: Language bar -->
  </ng-container>

  <ng-container *ngIf="userDisplay">
    <ng-container *ngIf="userLayout === 'dropdown'">
      <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
    </ng-container>
    <ng-container *ngIf="userLayout === 'offcanvas'">
      <!--begin: User bar -->
      <kt-user-profile4></kt-user-profile4>
      <!--end: User bar -->
    </ng-container>
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
