<!--begin::Wrapper-->
<div class="login-content d-flex flex-column pt-lg-0 pt-12">
  <!--begin::Logo-->
  <div class="login-logo pb-xl-20 pb-15">
    <img src="assets/media/logos/logo-4.png" class="max-h-70px" alt="" />
  </div>
  <!--end::Logo-->
  <!--begin::Signin-->
  <div class="login-form">
    <!--begin::Form-->
    <form
      class="form fv-plugins-bootstrap fv-plugins-framework"
      [formGroup]="loginForm"
      autocomplete="off"
      novalidate="novalidate"
      id="kt_login_form"
    >
      <div class="pb-1 pb-lg-5">
        <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
          {{ "AUTH.LOGIN.TITLE" | translate }}
        </h3>
        <div class="text-muted font-weight-bold font-size-h4">
          {{ "AUTH.LOGIN.QUESTION" | translate }}
          <a
            routerLink="/entrar/registrar"
            class="text-primary font-weight-bolder"
            >{{ "AUTH.LOGIN.QUIESTION_LINK" | translate }}</a
          >
        </div>
      </div>
      <kt-auth-notice></kt-auth-notice>
      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.DNI" | translate }}</label>
        <input formControlName="dni" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" value="text" name="dni" placeholder="{{ 'AUTH.INPUT.DNI_DES' | translate }}" maxlength="15" autocomplete="off" tabindex="1">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 15</div>
      </div>

        <div class="form-group fv-plugins-icon-container has-success">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ "AUTH.INPUT.PASSWORD" | translate }}</label>
          <a routerLink="/entrar/recuperar-contrasena" class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5">{{ "AUTH.LOGIN.FORGOT_BUTTON" | translate }}</a>
        </div>
        <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 invalid" type="password" formControlName="password" name="password" autocomplete="off" tabindex="2">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</div>
      </div>

      <div class="checkbox-inline">
        <label class="checkbox checkbox-lg">
          <input type="checkbox" name="Checkboxes3_1">
          <span></span>{{ "AUTH.INPUT.KEEP_SESSIONS" | translate }}</label>
      </div>

      <div class="pb-lg-0 pb-5">
        <button id="kt_login_signin_submit"
                (click)="submit()"
                [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">{{ "AUTH.LOGIN.BUTTON" | translate }}</button>
      </div>




      <!--begin::Action-->
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
<!--end::Wrapper-->
