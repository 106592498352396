import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
  constructor () {
    super();
    this.clear();
  }
  id: number;
  dni: string;
  IdUsuario: number;
  TipoIdentificacion: string;
  Identificacion: string;
  IdTipo: number;
  Apellidos: string;
  Nombres: string;
  Correo: string;
  Clave: string;
  Direccion: string;
  IdBroker: number;
  Telefono: string;
  Genero: string;
  FechaNacimineto: string;
  IdEstado: number;
  IdRol: number;
  ObservacionEstado: string;
  DescripcionEstado: string;
  NombreAgencia: string;
  EstadoAgencia: boolean;

  accessToken: string;
  refreshToken: string;
  pic: string;

  address: Address;
  socialNetworks: SocialNetworks;

  IdProvincia: number;
  Provincia: string;

  clear(): void {

    this.dni = '';
    this.IdUsuario = 0;
    this.TipoIdentificacion = '';
    this.Identificacion = '';
    this.IdTipo = 0;
    this.Apellidos = '';
    this.Nombres = '';
    this.Correo = '';
    this.Clave = '';
    this.Direccion = '';
    this.IdBroker = 0;
    this.Telefono = '';
    this.Genero = '';
    this.FechaNacimineto = '';
    this.IdEstado = 0;
    this.IdRol = 0;
    this.ObservacionEstado = '';
    this.DescripcionEstado = '';
    this.NombreAgencia = '';
    this.EstadoAgencia = false;
    this.IdProvincia = 1;
    this.Provincia = '';

    this.accessToken = 'access-token-' + Math.random();
    this.refreshToken = 'access-token-' + Math.random();
    this.pic = './assets/media/users/default.jpg';

    // form
    this.address = new Address();
    this.socialNetworks = new SocialNetworks();

    this.socialNetworks.clear();
    this.address.clear();
  }
}
