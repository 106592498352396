
<div class="mat-table-wrapper">
    <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
      {{ ms }}
    </kt-alert>
    <kt-table-range
      [productId]="productId$"
      [editable]="editable"
      [hasFormErrors]="hasFormErrors"
      [type]="type"
      [ms]="ms"
      [load]="load"
      [save]="save"
      [onlyKids]="true"
  ></kt-table-range>
  </div>

