<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Comparar Plan</h3>
    </div>
  </div>
  <div class="form">
    <div class="card-body">
      Los planes que ve a continuación cumplen con el mismo criterio que el plan actualmente seleccionado, si desea seleccionar un plan con diferentes característica debe ir a la página de selección de plan.
      Puede seleccionar hasta {{ 3-1 }} planes adicionales.
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
        <div class="form-group">
          <mat-form-field appearance="fill" style="width:100%;">
            <mat-label>Seleccione los planes que desea comparar.</mat-label>
            <mat-select [formControl]="planes" multiple>
              <mat-select-trigger>
                {{planes.value ? planes.value[0] : ''}}
                <span *ngIf="planes.value?.length > 1" class="example-additional-selection">
                  (+{{planes.value.length - 1}} {{planes.value?.length === 2 ? 'Otro' : 'Otros'}})
                </span>
              </mat-select-trigger>
              <ng-container *ngFor="let plan of plans; index as i">
                <mat-option *ngIf="validate(plan)" [value]="plan.IdPlan">{{ plan.IdPlan + ' - ' + plan.DescPlan + ' - ' + plan.Region }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <div class="fv-plugins-message-container" *ngIf="error">Debe seleccionar hasta dos planes para se comparados</div>
        </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button mat-button (click)="onNoClick()">Cancelar</button>&nbsp;
          <button mat-button color="primary" cdkFocusInitial (click)="onYesClick()" [disabled]="viewLoading">Comparar</button>
        </div>
      </div>
    </div>
  </div>
</div>
