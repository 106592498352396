import { LayoutUtilsService } from './../../../../../../core/_base/crud/utils/layout-utils.service';
import { CategoryModel } from './../../../../../../core/quotation/_models/category.model';
import { CategoryService } from './../../../../../../core/quotation/_services/category.service';
import {Component, OnInit, Input, ChangeDetectorRef, ViewRef } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubheaderService} from '../../../../../../core/_base/layout';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'kt-category',
  templateUrl: './category.component.html',
  styles: [
  ]
})

export class CategoryComponent implements OnInit {
  @Input() productId$: Observable<number>; // Obserbable del ID del producto que viene del padre. product-edit.component.html Linea 97
  productId: number;
  displayedColumns: string[] = ['IdCategoria', 'DescCategoria', 'Descripcion', 'AplicaBeneficio', 'Hombre', 'Mujer','TitularDesde', 'TitularHasta', 'Conyugue', 'Maternidad', 'Dependientes', 'DependienteDesde', 'DependientesHasta', 'PersonasDesde', 'PersonasHasta'];
  dataSource = new MatTableDataSource();
  formGroup: FormGroup;
  categoryForEdit: CategoryModel;
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  constructor(private categoryService: CategoryService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private subheaderService: SubheaderService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista Categoria', 'Estas son sus categorias');
      this.loadData();

  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

  }

  loadData(){
    this.categoryService.get().subscribe( next =>{
      console.log(next);
      this.dataSource = new MatTableDataSource(next);
      this.cdr.detectChanges();
    }, e => {
      console.log(e);
    });

  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }

}
