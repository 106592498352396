<div class="card card-custom"
     [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Seleccione las características para la sección [NOMBRE_SECCION]</h3>
    </div>
  </div>
  <form class="form" [formGroup]="CharecteristicsSelectForm" *ngIf="this.CharecteristicsSelectForm">
    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <kt-alert type="warn" *ngIf="hasFormErrors" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
        Oh!.
      </kt-alert>
      <div class="row">
        <div class="col-xl-12">
          <div class="form-group">
            <mat-form-field appearance="fill" [style.width.%]="100">
              <mat-label>Caracteristicas</mat-label>
              <mat-select formControlName="topping" multiple>
                <mat-option *ngFor="let topping of charecteristics" [value]="topping.IdCaracteristica">{{topping.Caracteristica}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" (click)="cerrar()" mat-raised-button cdkFocusInitial >
            Cancelar
          </button>&nbsp;
          <button type="button" (click)="guardar()" [mat-dialog-close]="data" mat-raised-button color="primary" [disabled]="viewLoading">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
