<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Enviar cotizacion a {{ data.q.Cliente }}</h3>
    </div>
  </div>
  <div class="form">
    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <form [formGroup]="sendQ">
        <div class="form-group col-lg-12">
          <label>Direccion de correo</label>
          <input type="text" class="form-control" formControlName="email" name="DescBeneficio">
          <span class="form-text text-muted">Direccion de correo del cliente</span>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'required')">Este campo es requerido.</div>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'email')">Ingrese un email valido.</div>
        </div>
        <div class="form-group col-lg-12">
          <label>Mensaje</label>
          <textarea class="form-control" formControlName="msj" rows="4"></textarea>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('msj', 'required')">Este campo es requerido.</div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button mat-button (click)="onNoClick()">Cancelar</button>&nbsp;
          <button mat-button color="primary" cdkFocusInitial (click)="onYesClick()" [disabled]="viewLoading">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</div>
