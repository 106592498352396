<div class="card card-custom overflow-hidden">
  <div class="card-body p-0">
    <!-- begin: Invoice-->
    <!-- begin: Invoice header-->
    <div class="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style="background-image: url(../../../../../../assets/media/bg/bg-2.jpg);">
      <div class="col-md-9">
        <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
          <h1 class="display-4 text-white font-weight-boldest mb-10">{{ info.productName }}</h1>
          <div class="d-flex flex-column align-items-md-end px-0">
            <!--begin::Logo-->
            <a class="mb-5">
              <img src="../../../../../../assets/media/logos/ir-logo-light.png" alt="">
            </a>
            <!--end::Logo-->
            <span class="text-white d-flex flex-column align-items-md-end opacity-70">
															<span>Sistema para la gestión de cotizaciones.</span>
															<span>Herramienta de productividad</span>
														</span>
          </div>
        </div>
        <div class="border-bottom w-100 opacity-20"></div>
        <div class="d-flex justify-content-between text-white pt-6">
          <div class="d-flex flex-column flex-root">
            <span class="font-weight-bolde mb-2r">Lanzamiento</span>
            <span class="opacity-70">2021</span>
          </div>
          <div class="d-flex flex-column flex-root">
            <span class="font-weight-bolder mb-2">Version</span>
            <span class="opacity-70">{{ info.version }}</span>
          </div>
          <div class="d-flex flex-column flex-root">
            <span class="font-weight-bolder mb-2">Desarrollado por</span>
            <span class="opacity-70">iRoute Solutions
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- end: Invoice header-->
    <!-- begin: Invoice action-->
    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <p><b>{{ info.productName }}</b> fue desarrollado por <a href="https://iroute.com.ec/" target="_blank">iRoute Solutions</a> para <b>{{ info.owner.name }}</b></p>
        </div>
      </div>
    </div>
    <!-- end: Invoice action-->
    <!-- end: Invoice-->
  </div>
</div>
