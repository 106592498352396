import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewRef} from '@angular/core';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductModel} from '../../../../../../core/quotation/_models/product.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {environment} from '../../../../../../../environments/environment';
import {BackTarifaNormalModel} from '../../../../../../core/quotation/_models/_out/back-tarifa-normal.model';
import {RateService} from '../../../../../../core/quotation/_services/rate.service';
import {Subject, Subscription} from 'rxjs';
import {BenefitsService} from '../../../../../../core/quotation/_services/benefits.service';
import {Back_benefistResModel} from '../../../../../../core/quotation/_models/_in/back_benefist-res.model';
import {QuoteService} from '../../../../../../core/quotation/_services/quote.service';
import {BackQuoteResModel} from '../../../../../../core/quotation/_models/_in/back-quote-res.model';
import {SendMailComponent} from '../_subs/send-mail/send-mail.component';
import {MatDialog} from '@angular/material/dialog';
import {ChangePlanComponent} from '../_subs/change-plan/change-plan.component';
import {CompareSelectComponent} from '../_subs/compare-select/compare-select.component';
import {PdkMaker} from '../../../../../../core/quotation/_services/pdf-make';

@Component({
  selector: 'kt-quotes-add',
  templateUrl: './quotes-add.component.html',
  styleUrls: ['./quotes-add.component.scss']
})
export class QuotesAddComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('wizard', {static: true}) el: ElementRef;

  quoteForm: FormGroup;
  quotePoolForm: FormGroup;
  quoteDependentForm: FormGroup;
  quoteBenefitsForm: FormGroup;
  plan: ProductModel;
  loading = false;
  loadingToBenefist = false;
  loadingToRate = false;
  loadingToSave = false;
  g = 'M';
  benefist = false;
  idQuote = 0;

  ValorAnual = 0;
  ValorContado = 0;
  ValorMensual = 0;
  ValorSemestral = 0;
  ValorTrimestral = 0;
  age = [];
  benefits: Back_benefistResModel[];
  benefistsIndex = [];

  ageTitular = 2;
  inputnumber = 0;
  q: BackQuoteResModel;

  submitted = false;
  individual: boolean;
  private destroyed = false;
  private subscriptions: Subscription[] = [];

  ConBeneficio = false;

  private unsubscribe: Subject<any>;

  today = new Date(new Date().valueOf()-(((365*18)+5)*24*60*60*1000)).toJSON().split('T')[0];
  private formChangesSubscription: any;

  constructor(
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private rs: RateService,
    private router: Router,
    private bn: BenefitsService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private qs: QuoteService,
    private pdfService: PdkMaker
  ) {

  }

  ngOnInit(): void {

    this.subheaderService.setTitle('Formulario de Cotizacion');
    // tslint:disable-next-line:triple-equals
    this.ConBeneficio = (this.activatedRoute.snapshot.paramMap.get('beneficio') == 'conBeneficios');
    this.route.data
      .subscribe((data) => {
        this.subheaderService.setTitle('Formulario de Cotizacion', `${data.plan.DescPlan} - ${data.plan.Region.toUpperCase()} - Versión:${data.plan.IdPlan}.${data.plan.VersionNum}`);
        this.plan = data.plan;
        this.individual = (data.plan.IdProducto == 1);

        this.initRegisterForm();
        if(this.ConBeneficio){
          this.getAditionals(data.plan.IdPlan);
        }

        if(localStorage.getItem('_data') !== null){
          this.loadData();
        }

      });

    this.subscriptions.push(this.subheaderService.reset$.subscribe(bc => {
      this.reset();
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.subheaderService.change$.subscribe(bc => {
      this.change();
      this.cdr.detectChanges();
    }));
  }

  /*
  reset(){
    this.initRegisterForm();
    if(this.ConBeneficio){
      this.getAditionals(this.plan.IdPlan);
    }

    if(this.benefits && this.benefits.length > 0){
      this.initBenefitsForm();
    }
    this.tarifaIni();
  }
  */
  reset(){
    this.initRegisterForm();
    if(this.ConBeneficio){
      this.getAditionals(this.plan.IdPlan);
    }else{
      this.quoteDependentForm.reset();
      this.quoteForm.reset();
    }

    if(this.benefits && this.benefits.length > 0){
      this.initBenefitsForm();
    }
    this.tarifaIni();
  }

  loadData(){

    const _data: any = JSON.parse(localStorage.getItem('_data'));
    localStorage.removeItem('_data');
    if(this.individual){
      this.quoteForm.get('clientName').setValue(_data.Cliente);
      if(this.ConBeneficio){

        this.quoteForm.get('gender').setValue(_data.TitularGenero);
        this.quoteForm.get('maternity').setValue(_data.TitularMaternidad);

        if(_data.TitularEdad >0 ){
          this.quoteForm.get('ageClient').setValue(_data.TitularEdad);
          this.setDateBirth();
        }

        if(_data.ConyugueEdad >0 ){
          this.quoteForm.get('ageSponse').setValue(_data.ConyugueEdad);
          this.setDateBirthS();
          this.quoteForm.get('sponseGender').setValue(_data.ConyugueGenero);
        }

        if(_data.Dependientes.length>0){
          _data.Dependientes.forEach(
            element =>{
              this.addDependient(Number(element));
            }
          );
        }
        if(_data.CoberturasAdicionales.length>0){

          this.layoutUtilsService.showActionNotification(
            'Atencion! al cambiar de plan algunos beneficios adicionales pueden que ya no se encuentren disponible.',
            MessageType.Read,
            10000,
            true,
            false
          );
        }
        this.save(false);
      }
    } else {

       this.quotePoolForm.get('clientPoolName').setValue(_data.Cliente);
       this.quotePoolForm.get('actEconomy').setValue(_data.Actividad);

      if(_data.CategoriaPools.length>0){
        _data.CategoriaPools.forEach(
          element =>{
            if(element.IdCategoria === 1 ){
              this.quotePoolForm.get('alones').setValue(element.CantidadMin);
            }

            if(element.IdCategoria === 3 ){
              this.quotePoolForm.get('plusOne').setValue(element.CantidadMin);
            }

            if(element.IdCategoria === 5 ){
              this.quotePoolForm.get('whitFamily').setValue(element.CantidadMin);
            }
          }
        );
      }

       this.save(false);
    }


  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  setG(){
    this.g = this.quoteForm.controls.gender.value;
    this.quoteForm.get('maternity').setValue(false);
  }

  initRegisterForm() {
    if(this.individual){
      if(this.ConBeneficio){

        let _client = '';
        let _clientBirth: Date;
        let _clientGender = '';
        let _clientmaternity = false;
        let _sponseGender = '';
        let _sponseBirth: Date;
        let _ageClient = 0;
        let _ageSponse = 0;

        if (this.plan.Cliente){
          _client = this.plan.Cliente;
        }
        if (this.plan.TitularNacimiento){
          _clientBirth = new Date(this.plan.TitularNacimiento);
        }

        if (this.plan.TitularGenero){
          _clientGender = this.plan.TitularGenero;
        }

        if (this.plan.TitularMaternidad){
          _clientmaternity = Boolean(this.plan.TitularMaternidad);
        }

        if (this.plan.ConyugueGenero){
          _sponseGender = this.plan.ConyugueGenero;
        }

        if (this.plan.ConyugueFechaNacimiento){
          _sponseBirth = new Date(this.plan.ConyugueFechaNacimiento);
        }

        if (this.plan.TitularEdad){
          _ageClient = this.plan.TitularEdad;
        }

        if (this.plan.ConyugueEdad){
          _ageSponse = this.plan.ConyugueEdad;
        }


        this.quoteForm = this.fb.group({
          clientName: [_client, [Validators.required]],
          clientBirth: [_clientBirth, [Validators.required]],
          gender: [_clientGender, [Validators.required]],
          maternity: [_clientmaternity],
          sponseGender: [_sponseGender],
          sponseBirth: [_sponseBirth],
          ageClient: [_ageClient, [Validators.min(18)]],
          ageSponse: [_ageSponse]
        });
      }else{

        let _client = '';

        if (this.plan.Cliente){
          _client = this.plan.Cliente;
        }

        this.quoteForm = this.fb.group({
          clientName: [_client, [Validators.required]]
        });
      }
      this.quoteDependentForm = new FormGroup({
        ages: new FormArray([]),
      });

    } else {
      let _client = '';
      let _Actividad = '';

      if (this.plan.Cliente){
        _client = this.plan.Cliente;
      }

      if (this.plan.Actividad){
        _Actividad = this.plan.Actividad;
      }


      this.quotePoolForm = this.fb.group({
        clientPoolName: [_client, [Validators.required]],
        actEconomy: [_Actividad, [Validators.required]],
        alones: [0, [Validators.required]],
        plusOne: [0, [Validators.required]],
        whitFamily: [0, [Validators.required]],
      });
    }

  }

  setDateBirth(){
    const datebirth = new Date(new Date().valueOf()-(((365*(this.quoteForm.get('ageClient').value))+5)*24*60*60*1000)).toJSON().split('T')[0];
    this.quoteForm.get('clientBirth').setValue(datebirth);
  }

  plusAgeT(){
      this.quoteForm.get('ageClient').setValue(parseInt(this.quoteForm.get('ageClient').value, 0) + 1);
    this.setDateBirth();
  }

  minuAgeT(){
    if(this.quoteForm.get('ageClient').value>18){
      this.quoteForm.get('ageClient').setValue(parseInt(this.quoteForm.get('ageClient').value, 0) - 1);
      this.setDateBirth();
    }
  }

  setDateBirthS(){
    const datebirth = new Date(new Date().valueOf()-(((365*(this.quoteForm.get('ageSponse').value))+5)*24*60*60*1000)).toJSON().split('T')[0];
    this.quoteForm.get('sponseBirth').setValue(datebirth);
  }

  plusAgeS(){
    this.quoteForm.get('ageSponse').setValue(parseInt(this.quoteForm.get('ageSponse').value, 0) + 1);
    this.setDateBirthS();
  }

  minuAgeS(){
    if(this.quoteForm.get('ageSponse').value>18){
      this.quoteForm.get('ageSponse').setValue(parseInt(this.quoteForm.get('ageSponse').value, 0) - 1);
      this.setDateBirthS();
    }
  }

  initBenefitsForm(){
    this.quoteBenefitsForm = this.fb.group({
      benefit: this.fb.array([])
    });
  }

  setCheckboxBenefists(i){
    const index = this.benefistsIndex.indexOf(i);
    if (index > -1) {
        this.benefistsIndex.splice(index, 1);
    }else{
      this.benefistsIndex.push(i);
    }
    console.log(this.benefistsIndex);
  }

  getIDBeneficios(){
    const arr1 = this.benefistsIndex;
    const arr2 = [];

    arr1.forEach( (v, i) => {
      arr2.push(this.benefits[v].IdBeneficio);
    });
    return arr2;
  }

  getIDCataBeneficios(){
    const arr1 = this.benefistsIndex;
    const arr2 = [];

    arr1.forEach( (v, i) => {
      arr2.push(this.benefits[v].IdCatalogoBeneficio);
    });
    return arr2;
  }

  get ages(): FormArray {
    return this.quoteDependentForm.get('ages') as FormArray;
  }

  addDependient(v: number = 0) {
    const controls = this.quoteDependentForm.controls;
    if (this.quoteDependentForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }else{
      this.ages.push(new FormControl(v, [Validators.required]));
    }
  }

  deleteDependient(i: number) {
    this.ages.removeAt(i);
    console.log(this.ages.value);
  }

  getAditionals(a){
    this.loadingToBenefist = true;
    this.benefits = [];
    this.bn.GetBenefist(a).subscribe(v => {
      this.loadingToBenefist = false;
      this.benefits = v;
      this.cdr.detectChanges();
    }, error =>{
      console.log(error);
      this.layoutUtilsService.showActionNotification(
        'Atencion! ocurio un error cargando los benficios adicionales del plan',
        MessageType.Read,
        10000,
        true,
        false
      );
      this.loadingToBenefist = false;
    });

  }

  plusAlone(){
    this.quotePoolForm.get('alones').setValue(parseInt(this.quotePoolForm.get('alones').value, 0) + 1);
  }

  minusAlone(){
    if(this.quotePoolForm.get('alones').value>0){
      this.quotePoolForm.get('alones').setValue(parseInt(this.quotePoolForm.get('alones').value, 0) - 1);
    }
  }

  plusPlusOne(){
    this.quotePoolForm.get('plusOne').setValue(parseInt(this.quotePoolForm.get('plusOne').value, 0) + 1);
  }

  minusPluOne(){
    if(this.quotePoolForm.get('plusOne').value>0){
      this.quotePoolForm.get('plusOne').setValue(parseInt(this.quotePoolForm.get('plusOne').value, 0) - 1);
    }
  }

  plusWhitFamily(){
    this.quotePoolForm.get('whitFamily').setValue(parseInt(this.quotePoolForm.get('whitFamily').value, 0) + 1);
  }

  minusWhitFamily(){
    if(this.quotePoolForm.get('whitFamily').value>0){
      this.quotePoolForm.get('whitFamily').setValue(parseInt(this.quotePoolForm.get('whitFamily').value, 0) - 1);
    }
  }

  calcularTarifa(){
    this.tarifaIni();
    if(this.individual){
      const controls = this.quoteForm.controls;
      if (this.quoteForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );

        this.layoutUtilsService.showActionNotification(
          'Asegurese de Agregar todos los datos del formulario',
          MessageType.Read,
          10000,
          true,
          false
        );
        return;
      }

      if (this.quoteDependentForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        return;
      }


      if(Number(this.plan.IdProducto ) === 1 ){
        if(this.loadingToRate !== true){
          this.loadingToRate = true;
          this.tarifaIndividualConBeneficios(controls);
        }
      }else{
        this.layoutUtilsService.showActionNotification(
          'Debe agregar al menos un dependiente',
          MessageType.Read,
          10000,
          true,
          false
        );
        this.loadingToRate = false;
        return;
      }
    }else{
      const controls = this.quotePoolForm.controls;
      if (this.quotePoolForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );

        this.layoutUtilsService.showActionNotification(
          'Asegurese de Agregar todos los datos del formulario',
          MessageType.Read,
          10000,
          true,
          false
        );
        return;
      }

      const temp = this.PreparatObjetoPool(controls, false);
      this.tarifaPool(temp.CategoriaPools);
    }

  }

  tarifaPool(a: any){
    this.loadingToRate = true;
    this.rs.ratePool(a).subscribe(v => {

      this.loadingToRate = false;
      this.ValorAnual = v.ValorAnual;
      this.ValorContado = v.ValorContado;
      this.ValorMensual = v.ValorMensual;
      this.ValorSemestral = v.ValorSemestral;
      this.ValorTrimestral = v.ValorTrimestral;
      this.cdr.detectChanges();

    }, error =>{
      this.layoutUtilsService.showActionNotification(
        error.error.Mensaje,
        MessageType.Read,
        10000,
        true,
        false
      );
    });
    this.loadingToRate = false;
  }

  setAgeTitular(e){
    const controls = this.quoteForm.controls;
    this.quoteForm.controls.ageClient.setValue(this.calcularEdad(controls.clientBirth.value));
  }

  setAgeSponse(e){
    this.ageSponse.setValue(this.calcularEdad(this.sponseBirth.value));
  }

  resetSponse(){
    this.sponseBirth.reset();
    this.ageSponse.reset();
    this.sponseGender.reset();
  }

  get sponseBirth(): any { return this.quoteForm.get('sponseBirth'); }
  get ageSponse(): any { return this.quoteForm.get('ageSponse'); }
  get sponseGender(): any { return this.quoteForm.get('sponseGender'); }
  get ageClient(): any { return this.quoteForm.get('ageClient'); }


  tarifaIndividualConBeneficios(controls){
    const _tarifa = this.PreparatObjetoIndividual(controls, false);

    this.rs.rate(_tarifa).subscribe(v => {

      this.loadingToRate = false;
      this.ValorAnual = v.ValorAnual;
      this.ValorContado = v.ValorContado;
      this.ValorMensual = v.ValorMensual;
      this.ValorSemestral = v.ValorSemestral;
      this.ValorTrimestral = v.ValorTrimestral;
      this.cdr.detectChanges();

    }, error =>{
      this.layoutUtilsService.showActionNotification(
        error.error.Mensaje,
        MessageType.Read,
        10000,
        true,
        false
      );
    });
    this.loadingToRate = false;

  }

  save(saved: boolean = true){
    if(this.individual){
      const controls = this.quoteForm.controls;
      if (this.quoteForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );

        this.layoutUtilsService.showActionNotification(
          'Asegurese de Agregar todos los datos del formulario',
          MessageType.Read,
          10000,
          true,
          false
        );
        return;
      }

      if (this.quoteDependentForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        return;
      }

      this.saveIndividual(controls, saved);
    }else{
      this.savePool(saved);
    }
  }

  PreparatObjetoIndividual(controls, saved){

    const _tarifa: BackTarifaNormalModel = new BackTarifaNormalModel();
    _tarifa.clear();

    _tarifa.IdPlan = this.plan.IdPlan;
    _tarifa.TitularBeneficio = this.ConBeneficio;
    _tarifa.Dependientes = this.ages.value;
    _tarifa.Estado = true;
    _tarifa.IdUsuario = parseInt(localStorage.getItem(environment.n), 0);
    _tarifa.Cliente = controls.clientName.value;
    _tarifa.GuardarCotizacion = saved;

    if(this.ConBeneficio){
      _tarifa.TitularEdad = controls.ageClient.value;
      _tarifa.TitularNacimiento = controls.clientBirth.value;
      _tarifa.TitularGenero = controls.gender.value;
      _tarifa.TitularMaternidad = controls.maternity.value;
      if(controls.sponseBirth.value !== null ){
        _tarifa.ConyugueEdad = controls.ageSponse.value;
        _tarifa.ConyugueGenero = controls.sponseGender.value;
        _tarifa.ConyugueFechaNacimiento = controls.sponseBirth.value;
      }
      _tarifa.CoberturasAdicionales = this.getIDBeneficios();
      _tarifa.CatBeneficiosAdicionales = this.getIDCataBeneficios();
    }

    return _tarifa;
  }

  saveIndividual(controls, saved){
    this.loadingToSave = true;

    const _tarifa = this.PreparatObjetoIndividual(controls, saved);
    this.qs.save(_tarifa).subscribe(v => {
      this.q = v;
      this.idQuote = v.IdCotizacion;
      localStorage.setItem('q', JSON.stringify(v));

      this.ValorAnual = v.TarifasResultado.ValorAnual;
      this.ValorContado = v.TarifasResultado.ValorContado;
      this.ValorMensual = v.TarifasResultado.ValorMensual;
      this.ValorSemestral = v.TarifasResultado.ValorSemestral;
      this.ValorTrimestral = v.TarifasResultado.ValorTrimestral;
      this.cdr.detectChanges();
      this.loadingToRate = false;

    }, error =>{
      this.layoutUtilsService.showActionNotification(
        error.error.Mensaje,
        MessageType.Read,
        10000,
        true,
        false
      );
    });
    this.loadingToSave = false;

  }

  PreparatObjetoPool(controls, saved){
    const obj: any = {};
    const temp = [];

    obj.IdPlan = this.plan.IdPlan;
    obj.Cliente = controls.clientPoolName.value;
    obj.Actividad = controls.actEconomy.value;
    obj.Estado = true;

    obj.IdUsuario = parseInt(localStorage.getItem(environment.n), 0);

    if(controls.alones.value > 0){

      temp.push({
        IdPlan :this.plan.IdPlan,
        IdCategoria : 1,
        CantidadMin: controls.alones.value,
        Cantidad: controls.alones.value
      });
    }

    if(controls.plusOne.value > 0){
      temp.push({
        IdPlan : this.plan.IdPlan,
        IdCategoria : 3,
        CantidadMin: controls.plusOne.value,
        Cantidad: controls.plusOne.value
      });
    }

    if(controls.whitFamily.value > 0){
      temp.push({
        IdPlan : this.plan.IdPlan,
        IdCategoria : 5,
        CantidadMin: controls.whitFamily.value,
        Cantidad: controls.whitFamily.value
      });
    }

    obj.CategoriaPools = temp;
    return obj;
  }

  savePool(saved){

    const controls = this.quotePoolForm.controls;
    if (this.quotePoolForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );

      this.layoutUtilsService.showActionNotification(
        'Asegurese de Agregar todos los datos del formulario',
        MessageType.Read,
        10000,
        true,
        false
      );
      return;
    }

    const obj = this.PreparatObjetoPool(controls, saved);

    this.qs.savePool(obj).subscribe(v => {
      this.q = v;
      this.idQuote = v.IdCotizacion;
      localStorage.setItem('q', JSON.stringify(v));
      this.ValorAnual = v.TarifasResultado.ValorAnual;
      this.ValorContado = v.TarifasResultado.ValorContado;
      this.ValorMensual = v.TarifasResultado.ValorMensual;
      this.ValorSemestral = v.TarifasResultado.ValorSemestral;
      this.ValorTrimestral = v.TarifasResultado.ValorTrimestral;
      this.cdr.detectChanges();

    }, error =>{
      this.layoutUtilsService.showActionNotification(
        error.error.Mensaje,
        MessageType.Read,
        10000,
        true,
        false
      );
    });
    this.loadingToSave = false;

  }

  view() {
    this.q.IdPlan = this.plan.IdPlan;
    //console.log(this.plan); 
    this.pdfService.generatePdf(this.q, false);
    /*const q = this.q;
    console.log(this.q); 
    console.log('editar');

    let a = 'sinBeneficios';
    let b = 'false';

    if(q.TitularBeneficio){
      a = 'conBeneficios';
    }

    if(q.IdProducto == 2){
      b = 'true';
    }

    const url = '/cotizar/ver/'+ q.IdCotizacion +'/'+ b + '/pdf';
    console.log(url);
    // this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
    const win = window.open(url, '_blank');
    win.focus();*/
  }

  calcularEdad(fecha) {
    const hoy = new Date();
    const cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    const m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    return edad;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.quoteForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isControlPoolHasError(controlName: string, validationType: string): boolean {
    const control = this.quotePoolForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isControlDependientesHasError(controlName: any, validationType: string): boolean {
    const control = this.quoteDependentForm.controls.age[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  tarifaIni(){
    this.ValorAnual = 0;
    this.ValorContado = 0;
    this.ValorMensual = 0;
    this.ValorSemestral = 0;
    this.ValorTrimestral = 0;
  }

  send() {
    const q = this.q;
    const dialogRef = this.dialog.open(SendMailComponent,  {
      data: { q },
      width: '50%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
    });
  }

  change() {
    let q: any;
    if(this.individual){
      const controls = this.quoteForm.controls;
       q = this.PreparatObjetoIndividual(controls, false);
    }else{
      const controls = this.quotePoolForm.controls;
       q = this.PreparatObjetoPool(controls, false);
    }

    const dialogRef = this.dialog.open(ChangePlanComponent,  {
      data: {
        q,
        ConBeneficio: this.ConBeneficio,
        IdPlan: this.plan.IdPlan,
        IdProducto: this.plan.IdProducto,
        IdPlantilla: this.plan.TipoPlantilla,
        Estado: this.plan.Estado,
        conBeneficio: this.ConBeneficio
      },
      width: '50%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
    });
  }

  compare() {
    const dialogRef = this.dialog.open(CompareSelectComponent,  {
      data: {
        IdQ: this.idQuote,
        IdProducto: this.plan.IdProducto,
        ConBeneficio: this.ConBeneficio,
        IdPlan: this.plan.IdPlan,
        IdPlantilla: this.plan.TipoPlantilla,
        Estado: this.plan.Estado,
        conBeneficio: this.ConBeneficio
      },
      width: '50%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
    });
  }


}
