import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BrokerModel} from '../_models/broker.model';
import {CatalogueBenefitsModel} from '../_models/catalogue-benefits.model';
import {BackResModel} from '../_models/_in/back-res.model';
import {GeneralConfigModel} from '../_models/general-config.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'BrokerAgencia';

@Injectable({
  providedIn: 'root'
})

export class GeneralConfigService {
  constructor(private http: HttpClient) {}

  get(){
    const options = {};
    return this.http.get<GeneralConfigModel[]>(URL_API.url + 'GetConfiguraciones', options);
  }

  update(config: GeneralConfigModel[]){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackResModel>(URL_API.url + 'ActConfiguraciones', config, {headers: httpHeaders});
  }

}
