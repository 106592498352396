<div class="card card-custom overflow-hidden">
  <div class="card-body p-0" id="htmlData">
    <!-- begin: Invoice-->
    <!-- begin: Invoice header-->
    <div class="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style="background-image: url(/assets/media/bg/bg-3.jpg);">
      <div class="col-md-9">
        <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
          <h1 class="display-4 font-weight-boldest mb-10">{{ q.NombrePlan + ' - '+ q.Region + ' - '+ q.IdPlan +'.'+ q.VersionPlan}}</h1>
          <div class="d-flex flex-column align-items-md-end px-0">
            <!--begin::Logo-->
            <a class="mb-5">
              <img src="assets/media/logos/logo-default-inverse.png" alt="">
            </a>
            <!--end::Logo-->
            <span class="d-flex flex-column align-items-md-end opacity-70">
															<span>Agencia {{ q.Agencia }}</span>
															<span>Dirección: {{ q.Direccion }}</span>
														</span>
          </div>
        </div>
        <div class="border-bottom w-100 opacity-20"></div>
        <div class="d-flex justify-content-between pt-6">
          <div class="d-flex flex-column flex-root">
            <span class="font-weight-bolde mb-2r">Cotización NO: {{ q.IdCotizacion }}</span>
            <span class="font-weight-bolder mb-2">Fecha: {{ q.FechaCotizacion | date }}</span>
            <span class="font-weight-bolder mb-2">Cliente: {{ q.Cliente }}.</span>
            <span *ngIf="q.TitularEdad>0" class="font-weight-bolde mb-2r">Edad: {{ q.TitularEdad }}</span>
            <span *ngIf="q.ConyugueEdad" class="font-weight-bolde mb-2r">Edad del Cónyuge: {{ q.ConyugueEdad }}</span>
            <span class="font-weight-bolde mb-2r">Agente: {{ q.Agente }}</span>
            <span class="font-weight-bolde mb-2r">Telefono: {{ q.Telefono }}</span>
            <span class="font-weight-bolde mb-2r">Dirección: {{ q.Direccion }}</span>
          </div>
          <div class="d-flex flex-column flex-root">
          </div>
          <div class="d-flex flex-column flex-root">
            <ng-template *ngIf="q.Dependientes.length > 0" ></ng-template>
            <span *ngFor="let d of q.Dependientes; index as i" class="font-weight-bolde mb-2r">Dependiente {{ i + 1 }}: {{ d }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end: Invoice header-->
    <!-- begin: Invoice body-->
    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
      <div class="col-md-9">
        <div class="table-responsive" *ngFor="let s of q.SeccionBloquesPlantillas; index as i">
          <table class="table">
            <thead>
            <tr>
              <th class="pl-0 font-weight-bold text-muted text-uppercase">{{ s.Seccion }}</th>
              <th class="text-right pr-0 font-weight-bold text-muted text-uppercase"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let p of s.Plantillas; index as o" class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">{{p.Descripcion}}</td>
              <td class="text-right pr-0 pt-7 text-right">{{p.Valor}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- end: Invoice body-->
    <!-- begin: Invoice footer-->
    <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
      <div class="col-md-9">
        <div class="d-flex justify-content-between flex-column flex-md-row font-size-lg">
          <div class="d-flex flex-column mb-10 mb-md-0">
            <div class="font-weight-bolder font-size-lg mb-3">Tarifas</div>
            <div class="d-flex justify-content-between mb-3">
              <span class="mr-15 font-weight-bold">Valor Mensual:</span>
              <span class="text-right">{{ q.TarifasResultado.ValorMensual }}</span>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span class="mr-15 font-weight-bold">Valor Trimestral:</span>
              <span class="text-right">{{ q.TarifasResultado.ValorTrimestral }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="mr-15 font-weight-bold">Valor Semestral:</span>
              <span class="text-right">{{ q.TarifasResultado.ValorSemestral}}</span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="mr-15 font-weight-bold">Valor Anual:</span>
              <span class="text-right">{{ q.TarifasResultado.ValorAnual }}</span>
            </div>
          </div>
          <div class="d-flex flex-column text-md-right">
            <span class="font-size-lg font-weight-bolder mb-1">Valor de contado y diferido</span>
            <span class="font-size-h2 font-weight-boldest text-right mb-1">{{ q.TarifasResultado.ValorContado }}</span>
            <span>Impuestos incluidos</span>
          </div>
        </div>
      </div>
      <div class="col-md-9 mt-4">
        <div class="d-flex justify-content-between flex-column flex-md-row font-size-lg">
          <p class="font-size-sm">{{ q.Conf_Observaciones_Pdf }}</p>
        </div>
      </div>
    </div>
    <!-- end: Invoice footer-->
    <!-- begin: Invoice action-->
    <!--<div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <button type="button" class="btn btn-light-primary font-weight-bold" onclick="window.print();">Descargar</button>
          <button type="button" class="btn btn-primary font-weight-bold" onclick="window.print();">Imprimir</button>
        </div>
      </div>
    </div>-->
    <!-- end: Invoice action-->
    <!-- end: Invoice-->
  </div>
</div>
