import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';

import { mergeMap, take } from 'rxjs/operators';
import {ProductsService} from '../../../quotation/_services';

@Injectable({
  providedIn: 'root'
})
export class PlanResolverService implements Resolve<any> {
  constructor (private cs: ProductsService, private router: Router) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const IdPlan = route.paramMap.get('IdPlan');

    return this.cs.getProductById(IdPlan).pipe(
      take(1),
      mergeMap(crisis => {

        if(crisis.Estado == 2){
          window.alert(`El plan ${crisis.DescPlan} versión ${crisis.IdPlan}.${crisis.VersionNum} se encuentra inactivo`);
          this.router.navigate(['cotizar/nuevo']);
          return of(crisis);
        }

        if (crisis) {

          return of(crisis);

        } else {

          this.router.navigate(['/oops']);
          return EMPTY;

        }
      })
    );
  }
}
