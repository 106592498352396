export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Inicio',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/inicio',
          bullet: 'dot',
        },
        {
          title: 'Cotizador',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-browser-2',
          submenu: [
            {
              title: 'Generar Cotizacion',
              page: '/cotizar/nuevo'
            },
            {
              title: 'Cotizaciones',
              page: '/cotizar/listar'
            }
          ]
        },
        {
          title: 'Usuarios',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-users-1',
          submenu: [
            {
              title: 'Usuarios',
              page: '/cotizar/usuarios'
            },
            {
              title: 'Aprobar usuarios',
              page: '/cotizar/aprobacion/usuarios'
            }
          ]
        },
        {
          title: 'Productos',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-delivery-package',
          submenu: [
            {
              title: 'Planes',
              page: '/productos/listar/planes'
            },
            {
              title: 'Configuraciones Generales',
              page: '/productos/configuracion/general'
            },
            {
              title: 'Catálogo de beneficios',
              page: '/productos/beneficio/catalogo'
            },
            {
              title: 'Beneficios por plan',
              page: '/productos/beneficio/plan'
            },
            {
              title: 'Categorias',
              page: '/productos/listarCategoria'
            },
            {
              title: 'Ordenar Caracteristica',
              page: '/productos/OrdenarCaracteristica'
            },
            {
              title: 'Catálogo de Secciones',
              page: '/productos/seccion/catalogo'
            },
            {
              title: 'Catálogo de Características',
              page: '/productos/caracteristicas/catalogo'
            },
            {
              title: 'Conf. de Rango de Edades',
              page: '/productos/configuracion/rangoEdades'
            },
            {
              title: 'Conf. de Dependientes y Niños Solo',
              page: '/productos/configuracion/rangoDependientesNiños'
            }
          ]
        },
        {
          title: 'Brokers',
          root: true,
          icon: 'flaticon-presentation-1',
          page: '/brokers/agencias',
          bullet: 'dot',
        }
      ],
      items2: [
        {
          title: 'Inicio',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/inicio',
          bullet: 'dot',
        },
        {
          title: 'Cotizador',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-browser-2',
          submenu: [
            {
              title: 'Generar Cotizacion',
              page: '/cotizar/nuevo'
            },
            {
              title: 'Cotizaciones',
              page: '/cotizar/listar'
            }
          ]
        }
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
