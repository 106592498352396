import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ForAgeConfigModel } from 'src/app/core/quotation/_models/for-age-config.model';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { ForAgeRangeService } from 'src/app/core/quotation/_services/forAgeRange.service';

@Component({
  selector: 'kt-for-age-edit',
  templateUrl: './for-age-edit.component.html',
  styles: [
  ]
})
export class ForAgeEditComponent implements OnInit {

  forAgeConfig: ForAgeConfigModel;
  hasFormErrors = false;
  benefitsForm: FormGroup;
  viewLoading = false;
  ms = 'Rayos! Algo salio mal por favor intente de nuevo.';
  type = 'warn';

  constructor(public dialogRef: MatDialogRef<ForAgeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private forAgeRangeService: ForAgeRangeService) { }

  private componentSubscriptions: Subscription;
  ngOnInit(): void {
    this.forAgeConfig = this.data.benefits;
    this.createForm();
    this.forAgeConfig.Rango.length === 0 ? this.benefitsForm.get('Estado').setValue('1') : null;
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.forAgeConfig.IdRango > 0) {
      return 'Editar rango';
    }

    return 'Agregar nuevo rango';
  }

  createForm() {
    let rango = this.forAgeConfig.Rango.split('-');
    this.benefitsForm = this.fb.group({
      DescRango1: [rango[0], Validators.required],
      DescRango2: [rango[1], Validators.required],
      Estado: [this.forAgeConfig.Estado.toString(), Validators.required]
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.benefitsForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  save() {
    const controls = this.benefitsForm.controls;

    if (this.benefitsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    if (this.forAgeConfig.IdRango > 0) {
      const benefitPreparate = new ForAgeConfigModel();
      benefitPreparate.clear();
      benefitPreparate.Rango = controls.DescRango1.value + '-' + controls.DescRango2.value;
      benefitPreparate.RangoMinimo = parseInt(controls.DescRango1.value);
      benefitPreparate.RangoMaximo = parseInt(controls.DescRango2.value);
      benefitPreparate.IdRango = this.forAgeConfig.IdRango;
      benefitPreparate.Estado = parseInt(controls.Estado.value);
      this.update(benefitPreparate);
    } else {
      const benefitPreparate = new ForAgeConfigModel();
      benefitPreparate.clear();
      benefitPreparate.RangoMinimo = parseInt(controls.DescRango1.value);
      benefitPreparate.RangoMaximo = parseInt(controls.DescRango2.value);
      benefitPreparate.Rango = controls.DescRango1.value + '-' + controls.DescRango2.value;
      benefitPreparate.Estado = parseInt(controls.Estado.value);
      this.create(benefitPreparate);
    }
  }

  preparate(controls) {
    const benefitPreparate = new ForAgeConfigModel();
    benefitPreparate.clear();
    benefitPreparate.Rango = controls.DescRango;

    return benefitPreparate;
  }

  create(benefits: ForAgeConfigModel) {
    console.log(benefits);
    this.forAgeRangeService.add(benefits).subscribe(next => {
      console.log(next);
      const message = `El nuevo rango fue agregado con exito.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e => {
      if (e.error.Mensaje) {
        this.mensaje(e.error.Mensaje);
      } else {
        this.mensaje('Rayos! Algo salio mal en el servidor por favor intente de nuevo.');
      }
    });
  }

  update(benefits: ForAgeConfigModel) {
    console.log(benefits);
    this.forAgeRangeService.update(benefits).subscribe(next => {
      console.log(next);
      const message = 'El rango ' + benefits.IdRango + ' fue actualizado con exito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e => {
      if (e.error.Mensaje) {
        this.mensaje(e.error.Mensaje);
      } else {
        this.mensaje('Rayos! Algo salio mal en el servidor por favor intente de nuevo.');
      }
    });
  }

  mensaje(ms, type = 'warn') {
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }
}
