import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {RateAgeModel} from '../../_models/rate-age.model';
import {Observable} from 'rxjs';
import {HttpUtilsService} from '../../../_base/crud';
import { RateModel } from '../../_models/rate.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetTarifaEdadPlan?idPlan=';

@Injectable({
  providedIn: 'root'
})

export class PlansAgeRangeService {
  constructor(private http: HttpClient,
    private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<RateAgeModel>(URL_API.url + URL_API_GET_QUOTES + id, options);
  }

  update(Rates: RateAgeModel): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaEdad', Rates, {
      headers: httpHeaders
    });
  }

  updateRangoEdad(Rates: any[]): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaRangoEdad', Rates, {
      headers: httpHeaders
    });
  }
 
  getTarifaRango(id: number){
    const options = {};
    return this.http.get<RateModel[]>(URL_API.url + 'GetTarifaRangoPlan?idplan=' + id, options);
  }
}
