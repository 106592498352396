import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductModel, ProductsService} from '../../../../../../../core/quotation';
import {PdkMaker} from '../../../../../../../core/quotation/_services/pdf-make';
import {QuoteService} from '../../../../../../../core/quotation/_services/quote.service';
import {AuthService} from '../../../../../../../core/auth';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
@Component({
  selector: 'kt-compare-select',
  templateUrl: './compare-select.component.html',
  styles: [
  ]
})
export class CompareSelectComponent implements OnInit {
  planes = new FormControl();
  viewLoading = false;
  error = false;
  formChange: FormGroup;
  plans: ProductModel[];
  rol: number;
  constructor(
    private fb: FormBuilder,
    private pdfService: PdkMaker,
    private qs: QuoteService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ps: ProductsService,
    private us: AuthService,
    public dialogRef: MatDialogRef<CompareSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.rol = Number(localStorage.getItem('p'));
    this.ps.getAll().subscribe( next=>{
      this.plans = next;
    } );
  }


  validate(plan: ProductModel){
    // tslint:disable-next-line:triple-equals
    if(this.data.IdPlan == plan.IdPlan){
      return false;
    }

    if(this.rol === 2){
      if(plan.Estado !== 1){
        return false;
      }
    }else{
      if(plan.Estado === 2){
        return false;
      }
    }

    // tslint:disable-next-line:triple-equals
    if(this.data.IdProducto != plan.IdProducto){
      return false;
    }
    if(Boolean(!this.data.conBeneficio) && Number(this.data.IdProducto) !== 2){
      // tslint:disable-next-line:triple-equals
      if(!plan.ConBeneficios){
        return false;
      }
    }
    return true;
  }

  onYesClick() {
    this.error = false;
    if (this.planes.value.length > 2 || this.planes.value.length < 1){
      this.error = true;
      return false;
    }
    this.viewLoading = true;
    let a = '';
    if(this.data.IdProducto == 1){
      a = 'Individual';
    }else{
      a = 'Pool';
    }
    let a1 = 0;
    let a2 = 0;
    if(this.planes.value.length == 1){
      a1 = this.planes.value[0];
    }else{
      a1 = this.planes.value[0];
      a2 = this.planes.value[1];
    }

    const url = '/cotizar/comparar/'+ a +'/'+ this.data.IdQ +'/'+ a1 +'/'+ a2;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
    this.dialogRef.close();
    this.viewLoading = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
