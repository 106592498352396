import { BaseModel } from '../../_base/crud';

export class ForAgeModel  extends BaseModel {

  IdTarifa: number;
  IdPlan: number;
  Edad: number;
  FemeninoSinMaternidad: number;
  FemeninoConMaternidad: number;
  Masculino: number;
  Estado: number;

  clear() {
    this.IdTarifa = 0;
    this.IdPlan = 0;
    this.Edad = 0;
    this.FemeninoSinMaternidad = 0;
    this.FemeninoConMaternidad = 0;
    this.Masculino = 0;
    this.Estado = 0;
  }
}
