// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { ProductsCharecteristicService } from '../_services/';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    ProductCharecteristicsActionsType,
    ProductCharacteristicsPageRequested,
    ProductCharacteristicsPageLoaded,
    ProductCharacteristicsPageToggleLoading,
    ProductCharacteristicUpdated,
} from '../_actions/product-charecteristics.actions';

@Injectable()
export class ProductCharacteristicEffects {
  // showLoadingDistpatcher = new ProductCharacteristicsPageToggleLoading({ isLoading: true });
  hideLoadingDistpatcher = new ProductCharacteristicsPageToggleLoading({isLoading: false});

  @Effect()
  loadProductCharacteristicsPage$ = this.actions$
    .pipe(
      ofType<ProductCharacteristicsPageRequested>(ProductCharecteristicsActionsType.ProductCharacteristicsPageRequested),
      mergeMap(({payload}) => {
        return this.productCharacteristicsService.findProductCharacteristics(payload.page, payload.productId);
      }),
      map((result: QueryResultsModel) => {
        return new ProductCharacteristicsPageLoaded({
          productCharacteristics: result.items,
          totalCount: result.totalCount
        });
      }),
    )

  @Effect()
  ProductCharacteristicsPageLoaded$ = this.actions$
    .pipe(
      ofType<ProductCharacteristicsPageRequested>(ProductCharecteristicsActionsType.ProductCharacteristicsPageRequested),
      mergeMap(({payload}) => {
        return this.productCharacteristicsService.findProductCharacteristics(payload.page, payload.productId);
      }),
      map((result: QueryResultsModel) => {
        return new ProductCharacteristicsPageLoaded({
          productCharacteristics: result.items,
          totalCount: result.totalCount
        });
      }),
    );

  @Effect()
  updateProductCharacteristic$ = this.actions$
    .pipe(
      ofType<ProductCharacteristicUpdated>(ProductCharecteristicsActionsType.ProductCharacteristicUpdated),
      mergeMap(({payload}) => {
        this.store.dispatch(new ProductCharacteristicsPageToggleLoading({isLoading: true}));
        return this.productCharacteristicsService.updateProductCharacteristics(payload.productCharacteristic);
      }),
      map(() => {
        return this.hideLoadingDistpatcher;
      }),
    );


  constructor(private actions$: Actions, private productCharacteristicsService: ProductsCharecteristicService, private store: Store<AppState>) {
  }
}
