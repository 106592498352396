import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BackTarifaNormalModel} from '../_models/_out/back-tarifa-normal.model';
import {BackTarifasResModel} from '../_models/_in/back-tarifas-res.model';
import {BackQuoteResModel} from '../_models/_in/back-quote-res.model';
import {QuoteModel} from '../_models/quote.model';
import {Observable} from 'rxjs';
import {ProductModel} from '../_models/product.model';
import { BackPaginatedResponse } from '../_models/_in/back-paginated-response-model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES_BY_USER = 'GetCotizaciones';
const URL_API_GET_QUOTES = 'Cotizaciones';

@Injectable({
  providedIn: 'root'
})

export class QuoteService {
  constructor(private http: HttpClient) {}

  getQuotes(userId){

    const options = userId ?
      {
        params: new HttpParams().set('idUsuario', userId.toString())
      } : {};

    return this.http.get<BackQuoteResModel[]>(URL_API.url + URL_API_GET_QUOTES, options);
  }

  getQuotesByUser(pageSize, pageIndex, userId, fechaDesde?, fechaHasta?, idPlan?){

    let params = new HttpParams();
    params = params.append('pageSize', pageSize?.toString());
    params = params.append('pageIndex', pageIndex?.toString());
    params = params.append('idUsuario', userId?.toString());
    if(fechaDesde){
      params = params.append('fechaDesde', fechaDesde.toString());
    }else{
      params = params.append('fechaDesde', '');
    }
    if(fechaHasta){
      params = params.append('fechaHasta', fechaHasta.toString());
    }else{
      params = params.append('fechaHasta', '');
    }
    params = params.append('idPlan', idPlan?.toString()??'0');
    
    return this.http.get<BackPaginatedResponse<BackQuoteResModel>>(URL_API.url + URL_API_GET_QUOTES_BY_USER, {params});
  }

  save(_client: BackTarifaNormalModel){

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackQuoteResModel>(URL_API.url + 'GeneraCotizacionesIndividual', _client, {headers: httpHeaders});
  }

  savePool(_a: any){

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackQuoteResModel>(URL_API.url + 'GeneraCotizacionesPool', _a, {headers: httpHeaders});
  }

  send(file, client, email, asunto, msj, id){

    const body: any = {};
    body.Cliente = client;
    body.Destinatario = email;
    body.Contenido = msj;
    body.ArchivoBase64 = file;
    body.ExtensionArchivo = 'pdf';
    body.Asunto = 'Cotizacion Nro.' + id +' de ' + client;
    body.NombreArchivo = 'Cotizacion_' + id;
    console.log(body);

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackQuoteResModel>(URL_API.url + 'EnvioCotizacionAdjuntos', body, {headers: httpHeaders});
  }

  getQuote(QId: number, isPool: string){

    const options = QId ?
      {
        params: new HttpParams()
          .set('idcotizacion', QId.toString())
          .set('isPool', isPool.toString())
      } : {};

    return this.http.get<QuoteModel[]>(URL_API.url + 'ConsultaCotizacion', options);
  }

  compare(a){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackQuoteResModel>(URL_API.url + `CompararPlanes`,a, {headers: httpHeaders});
  }

  comparePool(a){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackQuoteResModel>(URL_API.url + `CompararPlanesPool`,a, {headers: httpHeaders});
  }



}
