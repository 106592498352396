import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {RateKidsModel} from '../../_models/rate-kids.model';
import {Observable} from 'rxjs';
import {RateKidsPutModel} from '../../_models/rate-kids-put.model';
import { HttpUtilsService } from 'src/app/core/_base/crud';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetTarifaNSoloPlan?idPlan=';

@Injectable({
  providedIn: 'root'
})

export class PlansKidsRateService {
  constructor(private http: HttpClient,
    private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<RateKidsModel>(URL_API.url + URL_API_GET_QUOTES + id, options);
  }

  update(planStateModel: RateKidsPutModel): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(URL_API.url + 'ActTarifasNSolo', planStateModel, {headers: httpHeaders});
  }

  updateRangoKids(data: any[]): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaRangoDSolo', data, {
      headers: httpHeaders
    });
  }
 
  getTarifaRango(id: number){
    const options = {};
    return this.http.get<any[]>(URL_API.url + 'GetTarRangoDSolo?idplan=' + id, options);
  }

}
