// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { ProductCharecteristicsModel } from '../_models/product-charecteristics.model';

export enum ProductCharecteristicsActionsType {
  ProductCharacteristicUpdated = '[Edit ProductCharacteristic Dialog] ProductCharacteristic Updated',
  ProductCharacteristicStoreUpdated = '[Edit ProductCharacteristic Dialog] ProductCharacteristic Updated | Only on storage',
  ProductCharacteristicsPageRequested = '[Product Characteristics List Page] Product Characteristics Page Requested',
  ProductCharacteristicsPageLoaded = '[Product Characteristics API] Product Characteristics Page Loaded',
  ProductCharacteristicsPageCancelled = '[Product Characteristics API] Product Characteristics Page Cancelled',
  ProductCharacteristicsPageToggleLoading = '[Product Characteristics] Product Characteristics Page Toggle Loading'
}

export class ProductCharacteristicUpdated implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicUpdated;

  constructor(public payload: {
    partialProductCharacteristic: Update<ProductCharecteristicsModel>, // For State update
    productCharacteristic: ProductCharecteristicsModel // For Server update (through service)
  }) {
  }
}

export class ProductCharacteristicStoreUpdated implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicStoreUpdated;

  constructor(public payload: {
    productCharacteristic: Update<ProductCharecteristicsModel>, // For State update
  }) {
  }
}

export class ProductCharacteristicsPageRequested implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicsPageRequested;

  constructor(public payload: { page: QueryParamsModel, productId: number }) {
  }
}

export class ProductCharacteristicsPageLoaded implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicsPageLoaded;
  constructor(public payload: { productCharacteristics: ProductCharecteristicsModel[], totalCount: number }) {
  }
}

export class ProductCharacteristicsPageCancelled implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicsPageCancelled;
}

export class ProductCharacteristicsPageToggleLoading implements Action {
  readonly type = ProductCharecteristicsActionsType.ProductCharacteristicsPageToggleLoading;

  constructor(public payload: { isLoading: boolean }) {
  }
}

export type ProductCharecteristicsActions = ProductCharacteristicStoreUpdated
| ProductCharacteristicUpdated
| ProductCharacteristicsPageRequested
| ProductCharacteristicsPageLoaded
| ProductCharacteristicsPageCancelled
| ProductCharacteristicsPageToggleLoading;
