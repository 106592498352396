import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ForAgeModel } from 'src/app/core/quotation/_models/for-age.model';
import { PlansForAgeModel } from 'src/app/core/quotation/_services/product_subs/plans_forAgeModel.service';
import { LayoutUtilsService, MessageType } from '../../../../../../../core/_base/crud';

@Component({
  selector: 'kt-for-age',
  templateUrl: './for-age.component.html',
  styles: [
  ]
})


export class ForAgeComponent implements OnInit {

  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  displayedColumns: string[] = ['Edad', 'FemeninoSinMaternidad', 'FemeninoConMaternidad', 'Masculino', 'acctions'];
  dataSource: MatTableDataSource<ForAgeModel>;
  formGroup: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  hasFormErrors = false;
  forAgeForEdit: ForAgeModel;
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
  constructor(private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private plansAgeServices: PlansForAgeModel) { }

  ngOnInit(): void {
    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

      this.productId = res;
      this.loadData(res);
      this.createFormGroup();
    });
  }

  loadData(id) {
    this.plansAgeServices.get(id).subscribe(next => {
      this.dataSource = new MatTableDataSource(next);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  createFormGroup() {
    // 'edit' prefix - for item editing
    this.formGroup = this.fb.group({
      FemeninoSinMaternidad: [0, Validators.compose([Validators.required])],
      FemeninoConMaternidad: [0, Validators.compose([Validators.required])],
      Masculino: [0, Validators.compose([Validators.required])]
    });
    this.clearEditForm();
  }

  clearEditForm() {
    const controls = this.formGroup.controls;
    controls.FemeninoSinMaternidad.setValue(0);
    controls.FemeninoConMaternidad.setValue(0);
    controls.Masculino.setValue(0);

    this.forAgeForEdit = new ForAgeModel();
    this.forAgeForEdit._isEditMode = false;
  }

  editButtonOnClick(_item: ForAgeModel) {
    const controls = this.formGroup.controls;
    controls.FemeninoSinMaternidad.setValue(_item.FemeninoSinMaternidad);
    controls.FemeninoConMaternidad.setValue(_item.FemeninoConMaternidad);
    controls.Masculino.setValue(_item.Masculino);
    _item._isEditMode = true;
    this.isSwitchedToEditMode = true;
  }

  cancelEditButtonOnClick(_item: ForAgeModel) {
    _item._isEditMode = false;
    this.isSwitchedToEditMode = false;
  }

  saveUpdated(_item: ForAgeModel) {

    this.loadingAfterSubmit = true;
    const controls = this.formGroup.controls;
    const objectForUpdate = new ForAgeModel();
    objectForUpdate.IdPlan = this.productId;
    objectForUpdate.IdTarifa = _item.IdTarifa;
    objectForUpdate.Edad = _item.Edad;
    objectForUpdate.FemeninoSinMaternidad = controls.FemeninoSinMaternidad.value;
    objectForUpdate.FemeninoConMaternidad = controls.FemeninoConMaternidad.value;
    objectForUpdate.Masculino = controls.Masculino.value;
    objectForUpdate._isEditMode = false;

    console.log(objectForUpdate)
       this.plansAgeServices.update(objectForUpdate).subscribe( next =>{
          this.loadData(this.productId);
          this.loadingAfterSubmit = false;
          this.isSwitchedToEditMode = false;
          this.layoutUtilsService.showActionNotification('Actualizacion Tarifa con exito', MessageType.Update, 10000, true, false);
         this.cdr.detectChanges();// Detectar el cambio
        }, error => {
         this.loadingAfterSubmit = false;
         this.isSwitchedToEditMode = false;
         if(error.error.Mensaje){
           this.mensaje(error.error.Mensaje);
           this.cdr.detectChanges();
         } else {
           this.mensaje('Rayos! Ago salio mal en el servidor por favor intente de nuevo.');
           this.cdr.detectChanges();
         }
       });



    /*  const saveMessage = `Categoria actualizada`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, false);*/
  }

  mensaje(ms, type = 'warn') {
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }
}
