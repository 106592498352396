import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {RateAgeModel} from '../../../../../../../core/quotation/_models/rate-age.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlansAgeRangeService} from '../../../../../../../core/quotation/_services/product_subs/plans-age-range.service';
import { ForAgeRangeService } from 'src/app/core/quotation/_services/forAgeRange.service';


@Component({
  selector: 'kt-age-range',
  templateUrl: './age-range.component.html',
  styles: [
  ]
})
export class AgeRangeComponent implements OnInit {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  // Definimos las columnas de la tabla.
  //displayedColumns: string[] = ['IdTarifaEdad', 'RangoE1', 'RangoE2', 'RangoE3', 'RangoE4', 'RangoE5', 'RangoE6', 'RangoE7', 'RangoE8', 'RangoE9', 'RangoE10','RangoE11', 'RangoE12', 'RangoE13', 'RangoE14', 'RangoE15', 'acctions'];
  // Definimos la variable de datos que seran pintados en la tabla;
  dataSource = new MatTableDataSource();
  // Iniciamos el controlador del formulario
  formGroup: FormGroup;
  // Iniciamos la variable que mantendra la informacion temporal antes de ir al servidor.
  RateAgeForEdit: RateAgeModel; // OJO: Que no se confunda = con  : El primero asigna un valor mientras que : asigna un modelo.
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;

  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';


  rangos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public load = this.loadRange.bind(this);
  public save = this.saveUpdated.bind(this);

  formBackup: any;
  columnRange: string [] = [];
  displayedColumns: string [] = [];



  constructor(private plansAgesServices: PlansAgeRangeService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private forAgeRangeService :ForAgeRangeService) { }

    ngOnInit(): void {
      
      // Trae el ID del producto padre.
      this.productId$.subscribe(res => {
        if (!res) {
          return;
        }

        this.productId = res;
        //this.loadRange(res);
        //this.loadData(res);
        //this.createFormGroup();
      });
    }

    ngOnDestroy() {
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }

    // Carga los datos y los setea en la tabla.
    loadData(id: number){
      this.plansAgesServices.get(id).subscribe( next =>{
        const RateAge: RateAgeModel[] = [];
        RateAge.push(next);
        this.dataSource = new MatTableDataSource(RateAge);
      });
    }


    public getRango(): Observable<any[]>{
      return this.rangos$.asObservable();
    }

    loadRange(id: number){
      return this.plansAgesServices.getTarifaRango(id);
    }

    saveUpdated(data: any[]) {
      return this.plansAgesServices.updateRangoEdad(data);
    }

    onAlertClose($event) {
      this.hasFormErrors = false;
      this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
      this.type = 'warn';
    }

}
