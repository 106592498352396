import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { AnotherRateModel } from 'src/app/core/quotation/_models/another-rate.model';
import { PlansAnotherAgeModel } from 'src/app/core/quotation/_services/product_subs/plans_anotherAgeModel.service';
import { LayoutUtilsService, MessageType } from '../../../../../../../core/_base/crud';

@Component({
  selector: 'kt-another-rate',
  templateUrl: './another-rate.component.html',
  styles: [
  ]
})
export class AnotherRateComponent implements OnInit {

  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  displayedColumns: string[] = ['Edad', 'Dependientes', 'NinosSolo', 'acctions'];
  dataSource: MatTableDataSource<AnotherRateModel>;
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  formGroup: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  hasFormErrors = false;
  anotherRateForEdit: AnotherRateModel;
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
  constructor(private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private plansAnotherAgeServices: PlansAnotherAgeModel) { }

  ngOnInit(): void {
    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

      this.productId = res;
      this.loadData(res);
      this.createFormGroup();
    });
  }

  loadData(id) {
    this.plansAnotherAgeServices.get(id).subscribe(next => {
      this.dataSource = new MatTableDataSource(next);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  createFormGroup() {
    // 'edit' prefix - for item editing
    this.formGroup = this.fb.group({
      Dependientes: [0, Validators.compose([Validators.required])],
      NinosSolo: [0, Validators.compose([Validators.required])],
    });
    this.clearEditForm();
  }

  clearEditForm() {
    const controls = this.formGroup.controls;
    controls.Dependientes.setValue(0);
    controls.NinosSolo.setValue(0);

    this.anotherRateForEdit = new AnotherRateModel();
    this.anotherRateForEdit._isEditMode = false;
  }

  editButtonOnClick(_item: AnotherRateModel) {
    const controls = this.formGroup.controls;
    controls.Dependientes.setValue(_item.Dependientes);
    controls.NinosSolo.setValue(_item.NSolo);
    _item._isEditMode = true;
    this.isSwitchedToEditMode = true;
  }

  cancelEditButtonOnClick(_item: AnotherRateModel) {
    _item._isEditMode = false;
    this.isSwitchedToEditMode = false;
  }

  saveUpdated(_item: AnotherRateModel) {

    this.loadingAfterSubmit = true;
    const controls = this.formGroup.controls;
    const objectForUpdate = new AnotherRateModel();
    objectForUpdate.IdPlan = this.productId;
    objectForUpdate.IdTarifa = _item.IdTarifa
    objectForUpdate.Edad = _item.Edad;
    objectForUpdate.Dependientes = controls.Dependientes.value;
    objectForUpdate.NSolo = controls.NinosSolo.value;
    //objectForUpdate.Estado = _item.Estado;
    objectForUpdate._isEditMode = false;

    console.log(objectForUpdate)
       this.plansAnotherAgeServices.update(objectForUpdate).subscribe( next =>{
          this.loadData(this.productId);
          this.loadingAfterSubmit = false;
          this.isSwitchedToEditMode = false;
          this.layoutUtilsService.showActionNotification('Actualizacion Tarifa con exito', MessageType.Update, 10000, true, false);
         this.cdr.detectChanges();// Detectar el cambio
        }, error => {
         this.loadingAfterSubmit = false;
         this.isSwitchedToEditMode = false;
         if(error.error.Mensaje){
           this.mensaje(error.error.Mensaje);
           this.cdr.detectChanges();
         } else {
           this.mensaje('Rayos! Ago salio mal en el servidor por favor intente de nuevo.');
           this.cdr.detectChanges();
         }
       });



    /*  const saveMessage = `Categoria actualizada`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, false);*/
  }

  mensaje(ms, type = 'warn') {
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }
}
