import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { SubheaderService } from 'src/app/core/_base/layout/services/subheader.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { ForAgeEditComponent } from '../for-age-edit/for-age-edit.component';
import { ForAgeConfigModel } from 'src/app/core/quotation/_models/for-age-config.model';
import { MatTableDataSource } from '@angular/material/table';
import { ForAgeRangeService } from 'src/app/core/quotation/_services/forAgeRange.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kt-for-age-list',
  templateUrl: './for-age-list.component.html',
  styles: [
  ]
})
export class ForAgeListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<ForAgeConfigModel>;
  displayedColumns = ['Id', 'Rango', 'Estado', 'actions'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  private subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
              private subheaderService: SubheaderService,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private forAgeRangeService: ForAgeRangeService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista de Rangos', 'Estas son sus rangos.');

    this.forAgeRangeService.getAll().subscribe( data => {
      this.dataSource = new MatTableDataSource(data);
      this.cdr.detectChanges();
    });

    this.subscriptions.push(this.subheaderService.setNewCatalogue$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if (bc) {
          this.add();
          this.subheaderService.setNewCatalogue(false);
        }
      });
    }));
  }

  /**
 * On Destroy
 */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  add() {
    const catalogue = new ForAgeConfigModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }

  edit(benefits: ForAgeConfigModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdRango > 0 ? 'Agregar rango' : 'Nuevo rango';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdRango > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(ForAgeEditComponent, { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.forAgeRangeService.getAll().subscribe(next => {
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      });
    });
  }

}
