// Angular
import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
// RxJS
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
// Object-Path
import * as objectPath from "object-path";
// Services
import { PageConfigService } from "./page-config.service";
import { MenuConfigService } from "./menu-config.service";

export interface Breadcrumb {
  title: string;
  page: string | any;
}

export interface BreadcrumbTitle {
  title: string;
  desc?: string;
}

@Injectable()
export class SubheaderService {
  // Public properties
  title$: BehaviorSubject<BreadcrumbTitle> = new BehaviorSubject<BreadcrumbTitle>(
    { title: "", desc: "" }
  );
  breadcrumbs$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<
    Breadcrumb[]
  >([]);
  disabled$: Subject<boolean> = new Subject<boolean>();
  newUser$: Subject<boolean> = new Subject<boolean>();
  setNewCatalogue$: Subject<boolean> = new Subject<boolean>();
  setNewBenefit$: Subject<boolean> = new Subject<boolean>();
  setNewBroker$: Subject<boolean> = new Subject<boolean>();
  setNewCharecteristic$: Subject<boolean> = new Subject<boolean>();
  save$: Subject<boolean> = new Subject<boolean>();
  send$: Subject<boolean> = new Subject<boolean>();
  download$: Subject<boolean> = new Subject<boolean>();
  print$: Subject<boolean> = new Subject<boolean>();
  change$: Subject<boolean> = new Subject<boolean>();
  reset$: Subject<boolean> = new Subject<boolean>();
  upload$: Subject<boolean> = new Subject<boolean>();
  template$: Subject<number> = new Subject<number>();
  filterb$: Subject<object> = new Subject<object>();

  // Private properties
  private manualBreadcrumbs: any = {};
  private appendingBreadcrumbs: any = {};
  private manualTitle: any = {};

  private asideMenus: any;
  private headerMenus: any;
  private pageConfig: any;

  /**
   * Service Constructor
   *
   * @param router: Router
   * @param pageConfigService: PageConfigServie
   * @param menuConfigService: MenuConfigService
   */
  constructor(
    private router: Router,
    private pageConfigService: PageConfigService,
    private menuConfigService: MenuConfigService
  ) {
    const initBreadcrumb = () => {
      // get updated title current page config
      this.pageConfig = this.pageConfigService.getCurrentPageConfig();

      this.headerMenus = objectPath.get(
        this.menuConfigService.getMenus(),
        "header"
      );
      this.asideMenus = objectPath.get(
        this.menuConfigService.getMenus(),
        "aside"
      );

      // update breadcrumb on initial page load
      this.updateBreadcrumbs();

      if (objectPath.get(this.manualTitle, this.router.url)) {
        this.setTitle(
          this.manualTitle[this.router.url].title,
          this.manualTitle[this.router.url].desc
        );
      } else {
        // get updated page title on every route changed
        this.title$.next(objectPath.get(this.pageConfig, "page"));

        // subheader enable/disable
        const hideSubheader = objectPath.get(this.pageConfig, "page.subheader");
        this.disabled$.next(
          typeof hideSubheader !== "undefined" && !hideSubheader
        );

        // get updated breadcrumbs on every route changed
        this.updateBreadcrumbs();
        // breadcrumbs was appended before, reuse it for this page
        if (objectPath.get(this.appendingBreadcrumbs, this.router.url)) {
          this.appendBreadcrumbs(this.appendingBreadcrumbs[this.router.url]);
        }
      }
    };

    initBreadcrumb();

    // subscribe to router events
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(initBreadcrumb);
  }

  /**
   * Update breadCrumbs
   */
  updateBreadcrumbs() {
    // get breadcrumbs from header menu
    let breadcrumbs = this.getBreadcrumbs(this.headerMenus);
    // if breadcrumbs empty from header menu
    if (breadcrumbs.length === 0) {
      // get breadcrumbs from aside menu
      breadcrumbs = this.getBreadcrumbs(this.asideMenus);
    }

    if (
      // if breadcrumb has only 1 item
      breadcrumbs.length === 1 &&
      // and breadcrumb title is same as current page title
      breadcrumbs[0].title.indexOf(
        objectPath.get(this.pageConfig, "page.title")
      ) !== -1
    ) {
      // no need to display on frontend
      breadcrumbs = [];
    }

    this.breadcrumbs$.next(breadcrumbs);
  }

  /**
   * Manually set full breadcrumb paths
   */
  setBreadcrumbs(breadcrumbs: Breadcrumb[] | any[]) {
    this.manualBreadcrumbs[this.router.url] = breadcrumbs;
    this.breadcrumbs$.next(breadcrumbs);
  }

  /**
   * Append breadcrumb to the last existing breadcrumbs
   * param breadcrumbs
   */
  appendBreadcrumbs(breadcrumbs: Breadcrumb[] | any[]) {
    this.appendingBreadcrumbs[this.router.url] = breadcrumbs;
    const prev = this.breadcrumbs$.getValue();
    this.breadcrumbs$.next(prev.concat(breadcrumbs));
  }

  /**
   * Get breadcrumbs from menu items
   * param menus
   */
  getBreadcrumbs(menus: any) {
    let url = this.pageConfigService.cleanUrl(this.router.url);
    url = url.replace(new RegExp(/\./, "g"), "/");

    const breadcrumbs = [];
    const menuPath = this.getPath(menus, url) || [];
    menuPath.forEach((key) => {
      menus = menus[key];
      if (typeof menus !== "undefined" && menus.title) {
        breadcrumbs.push(menus);
      }
    });

    return breadcrumbs;
  }

  /**
   * Set title
   *
   * @param title: string
   */
  setTitle(title: string, desc: string = "") {
    this.manualTitle[this.router.url] = { title, desc };
    this.title$.next({ title, desc });
    if (title == "Generar Cotizacion") {
      this.filter(true, 1, "Todas");
    }
  }

  setNewUser(o: boolean) {
    this.newUser$.next(o);
  }

  setNewCatalogue(o: boolean) {
    this.setNewCatalogue$.next(o);
  }

  setNewBanefit(o: boolean) {
    this.setNewBenefit$.next(o);
  }

  setNewBroker(o: boolean) {
    this.setNewBroker$.next(o);
  }

  setNewCharecteristic(o: boolean) {
    this.setNewCharecteristic$.next(o);
  }

  save(o: boolean) {
    this.save$.next(o);
  }

  send(o: boolean) {
    this.send$.next(o);
  }

  download(o: boolean) {
    this.download$.next(o);
  }

  print(o: boolean) {
    this.print$.next(o);
  }

  change(o: boolean) {
    this.change$.next(o);
  }

  reset(o: boolean) {
    console.log("reset en servicio");
    this.reset$.next(o);
  }

  setIdTemplate(o: number) {
    this.template$.next(o);
  }

  uploap(o) {
    this.upload$.next(o);
  }

  filter(a, b, c) {
    const newa = a === "Si" ? true : false;
    const newb = b === "Individual" ? 1 : 2;
    const newc = c === "Todas" ? "Todas" : c;

    this.filterb$.next({ a: newa, b: newb, c: newc });
  }

  /**
   * Get object path by value
   * param obj
   * param value
   */
  getPath(obj, value) {
    if (typeof obj !== "object") {
      return;
    }
    const path = [];
    let found = false;

    const search = (haystack) => {
      // tslint:disable-next-line
      for (let key in haystack) {
        path.push(key);
        if (haystack[key] === value) {
          found = true;
          break;
        }
        if (typeof haystack[key] === "object") {
          search(haystack[key]);
          if (found) {
            break;
          }
        }
        path.pop();
      }
    };

    search(obj);
    return path;
  }
}
