import { BaseModel } from '../../_base/crud';
import {CategoriasPoolsModel} from './categorias-pools.model';

export class ProductModel extends BaseModel {

  IdCobertura: number;
  DescCobertura: string;
  IdProducto: number;
  DescProducto: string;
  IdPlan: number;
  DescPlan: string;
  Descripcion: string;
  AplicaBeneficio?: boolean;
  AplicaNinoSolo?: boolean;
  ConBeneficios: boolean;
  ObligaMaternidad: boolean;
  IdTipoTarifa: number;
  DescTipoTarifa: string;
  IdRegion: number;
  Region: string;
  Estado: number;
  EdadMaxMaternidad: number;
  DescEstado: string;
  isBorrador: boolean;
  TarifaDependientes: boolean;
  PlanAnterior: number;
  TipoPlantilla: number;
  AplicaBeneficioAdicional: boolean;
  Cliente?: string;
  TitularGenero?: string;
  TitularNacimiento?: Date;
  TitularMaternidad?: boolean;
  ConyugueGenero?: string;
  ConyugueFechaNacimiento?: Date;
  TitularEdad?: number;
  ConyugueEdad?: number;
  Actividad?: string;
  CategoriasPools?: CategoriasPoolsModel[];
  VersionNum?: number;
  VersionText?: string;

  clear() {

    this.IdPlan = 0;
    this.IdCobertura = 0;
    this.DescCobertura ='';
    this.IdProducto = 0;
    this.DescProducto = '';
    this.DescPlan = '';
    this.Descripcion = '';
    this.AplicaBeneficio = false;
    this.AplicaNinoSolo = false;
    this.ConBeneficios = false;
    this.ObligaMaternidad = false;
    this.AplicaBeneficioAdicional = false;
    this.IdTipoTarifa = 0;
    this.DescTipoTarifa ='';
    this.Estado = 0;
    this.EdadMaxMaternidad = 0;
    this.DescEstado = '';
    this.isBorrador = false;
    this.TarifaDependientes = false;
    this.PlanAnterior = 0;
    this.TipoPlantilla = 0;
    this.VersionNum = 0;
    this.VersionText = "";
  }
}
