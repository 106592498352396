import { BaseModel } from '../../_base/crud';

export class CatalogueBenefitsModel  extends BaseModel {
  IdCatalogoBeneficio: number;
  IdBeneficio: number;
  DescBeneficio: string;
  IdProducto: number;
  DescProducto: string;
  IdPlan: number;
  DescPlan: string;
  Costo: number;
  Observacion: string;
  Descripcion: string;
  Estado: number;

  clear() {
    this.IdCatalogoBeneficio = 0;
    this.IdBeneficio = 0;
    this.DescBeneficio = '';
    this.IdProducto = 0;
    this.DescProducto = '';
    this.IdPlan = 0;
    this.DescPlan = '';
    this.Costo = 0;
    this.Observacion = '';
    this.Descripcion = '';
    this.Estado = 0;
  }
}
