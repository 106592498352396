import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {BeniftPlanEditComponent} from '../../benefit-plan/benift-plan-edit/benift-plan-edit.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {CharecteristicsSelectComponent} from './charecteristics-select/charecteristics-select.component';
import {ActivatedRoute} from '@angular/router';
import {CharecteristicsTemplateModel} from '../../../../../../core/quotation/_models/charecteristics-template.model';
import {Subscription} from 'rxjs';
import {CharecteristicsService} from '../../../../../../core/quotation/_services/charecteristics.service';

@Component({
  selector: 'kt-sort-features',
  templateUrl: './sort-features.component.html',
  styles: [
  ]
})
export class SortFeaturesComponent implements OnInit, OnDestroy {
  Feactures: CharecteristicsTemplateModel[];
  private subscriptions: Subscription[] = [];
  dirty: boolean;
  isPool: boolean = false;

  constructor(private subheaderService: SubheaderService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private charecteristicsService: CharecteristicsService,
              private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Características por Sección', 'Ordene las caracteristicas');
    this.route.data
      .subscribe((data) => {
        this.Feactures = data.Feactures;
        this.dirty = false;
      });

    this.subscriptions.push(this.subheaderService.filterb$.subscribe(bc => {
      // @ts-ignore
      let res = bc.b !== 1;
      this.isPool = res;
      this.filterIsPool(res);
    }));

    this.subscriptions.push(this.subheaderService.save$.subscribe(bc => {
      if(this.dirty){
        this.save();
      } else {
        this.layoutUtilsService.showActionNotification(
          'No se han detectados cambios para guardar.',
          MessageType.Read,
          10000,
          true,
          false
        );
      }

    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  drop(event: CdkDragDrop<string[]>, i) {
    moveItemInArray(this.Feactures[i].Charecteristics, event.previousIndex, event.currentIndex);
    this.dirty = true;
  }

  add(){
    const dialogRef = this.dialog.open(CharecteristicsSelectComponent,  { data: { isPool: this.isPool } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
    });
  }

  elimina(i, a){
    this.Feactures[i].Charecteristics[a].status = 0;
    this.dirty = true;
    console.log('elimina', this.Feactures[i].Charecteristics[a]);
    const message = `Se eliminará la característica cuando de clic en el botón Guardar.`;
    this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
    }

  add1(i){
    const dialogRef = this.dialog.open(CharecteristicsSelectComponent,  { data:[ {isPool: this.isPool, idSeccion:  this.Feactures[i]} ]});
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        console.log('afterclosed',res);
        return;
      } else {
        console.log('afterclosed',res);
        this.Feactures[i].Charecteristics;
        this.dirty = true;
        console.log(this.Feactures);
      }
    });
  }

  save(){
    console.log('save');
    this.charecteristicsService.addCharacteristics(this.Feactures).subscribe(next => {
      console.log(next);
      const message = `Se actualizo.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.recarga();
    })
    
  }

  recarga(){
    console.log('recarga');
    location.reload();
  }

  filterIsPool(isPool: boolean){
    if(this.dirty){
      const opcion = confirm('Existen cambios que no se han guardados, si continua perderá dichos cambios.');
      if (!opcion) {
        this.layoutUtilsService.showActionNotification(
          'No se han detectados cambios para guardar.',
          MessageType.Read,
          10000,
          true,
          false
        );
        return;
      }
    }
    this.charecteristicsService.getTemplate(isPool).subscribe(Feactures => {
      this.Feactures = Feactures;
      this.dirty = false;
      this.cdr.detectChanges();
      console.log('filter', isPool);
      console.log(this.Feactures);
    });
  }

}
