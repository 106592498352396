import { BaseModel } from '../../_base/crud';

export class CharecteristicsModel extends BaseModel {

  IdSeccion: number;
  Seccion: string;
  IdPlantillaCaract: number;
  TipoPlantilla: number;
  Descripcion: string;

  clear() {
    this.IdSeccion = 0;
    this.Seccion = '';
    this.IdPlantillaCaract= 0;
    this.TipoPlantilla= 0;
    this.Descripcion= '';
  }
}
