import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {PlanStateModel} from '../../_models/plan-state.model';
import {HttpUtilsService} from '../../../_base/crud';
import { ForAgeModel } from '../../_models/for-age.model';

const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';

@Injectable({
  providedIn: 'root'
})

export class PlansForAgeModel {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<ForAgeModel[]>(URL_API.url + 'ForAgeModel?idplan=' + id, options);
  }

  update(Age: ForAgeModel): Observable<any> {
    const ForAge: ForAgeModel[] = [];
    ForAge.push(Age);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActForAgeModel ', ForAge, {headers: httpHeaders});
  }

}