import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {RateCategoryModel} from '../../../../../../../core/quotation/_models/rate-category.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PlansCategoryRateService} from '../../../../../../../core/quotation/_services/product_subs/plans-category-rate.service';


@Component({
  selector: 'kt-category-rate',
  templateUrl: './category-rate.component.html',
  styles: [
  ]
})
export class CategoryRateComponent implements OnInit , OnDestroy {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  // Definimos las columnas de la tabla.
  //displayedColumns: string[] = ['IdTarifaCat', 'DescCategoria', 'Rango1', 'Rango2', 'Rango3', 'Rango4', 'Rango5', 'Rango6', 'Rango7', 'Rango8', 'Rango9', 'Rango10', 'Rango11', 'Rango12', 'Rango13', 'Rango14', 'Rango15', 'acctions'];
  // Definimos la variable de datos que seran pintados en la tabla;
  dataSource = new MatTableDataSource();
  // Iniciamos el controlador del formulario
  formGroup: FormGroup;
  // Iniciamos la variable que mantendra la informacion temporal antes de ir al servidor.
  categoryForEdit: RateCategoryModel; // OJO: Que no se confunda = con  : El primero asigna un valor mientras que : asigna un modelo.
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';



  rangos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public load = this.loadRange.bind(this);
  public save = this.saveUpdated.bind(this);

  formBackup: any;
  columnRange: string [] = [];
  displayedColumns: string [] = [];


  constructor(private plansCategoryServices: PlansCategoryRateService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {
    console.log(this.editable);

    // Trae el ID del producto padre.
    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

      this.productId = res;
      //this.loadRange(res);
      //this.loadData(res);
      //this.createFormGroup();
    });
  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

  }

  // Carga los datos y los setea en la tabla.

  loadData(id){
    this.plansCategoryServices.get(id).subscribe( next =>{
      this.dataSource = new MatTableDataSource(next);
    });
  }

  public getRango(): Observable<any[]>{
    return this.rangos$.asObservable();
  }

  loadRange(id: number){
    return this.plansCategoryServices.getTarifaRango(id);
  }


  saveUpdated(data: any[]) {
    return this.plansCategoryServices.updateRangoCategory(data);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }
}
