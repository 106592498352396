import { BaseModel } from '../../_base/crud';

export class UserModel  extends BaseModel {
  IdUsuario: number;
  TipoIdentificacion: string;
  Identificacion: string;
  IdTipo: number;
  Apellidos: string;
  Nombres: string;
  Correo: string;
  Clave: string;
  Direccion: string;
  IdBroker: number;
  Telefono: string;
  Genero: string;
  FechaNacimineto: Date;
  IdEstado: number;
  IdRol: number;
  ObservacionEstado: string;
  DescripcionEstado: string;
  NombreAgencia: string;
  IdProvincia: number;
  Provincia: string;
  DireccionAgencia: string;

  clear() {
    this.IdUsuario = 0;
    this.TipoIdentificacion = '';
    this.Identificacion = '';
    this.IdTipo = 5;
    this.Apellidos = '';
    this.Nombres = '';
    this.Correo = '';
    this.Clave = '';
    this.Direccion = '';
    this.IdBroker = 0;
    this.Telefono = '';
    this.Genero = '';
    this.FechaNacimineto = new Date();
    this.IdEstado = 0;
    this.IdRol = 0;
    this.ObservacionEstado = '';
    this.DescripcionEstado = '';
    this.NombreAgencia = '';
    this.IdProvincia = 1;
    this.Provincia = '';
    this.DireccionAgencia = '';
  }
}
