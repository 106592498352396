import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BackResModel } from '../_models/_in/back-res.model';
import { CatalogueSectionsModel } from '../_models/catalogue-section.model';
const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
    providedIn: 'root'
})

export class CatalogueSectionService {
    constructor(private http: HttpClient) { }

    getAll() {
        const options = {};
        console.log(URL_API.url)
        return this.http.get<CatalogueSectionsModel[]>(URL_API.url + 'ConsultarCatalogoSecciones?estado=-1', options);
    }

    getByStatus(estado: number){
        const options = {};
        console.log(URL_API.url)
        return this.http.get<CatalogueSectionsModel[]>(URL_API.url + `ConsultarCatalogoSecciones?estado=${estado}`, options);
    }

    add(sections: CatalogueSectionsModel) {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<BackResModel>(URL_API.url + 'GuardarCatalogoSecciones', sections, { headers: httpHeaders });
    }

    update(sections: CatalogueSectionsModel) {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<BackResModel>(URL_API.url + 'GuardarCatalogoSecciones', sections, { headers: httpHeaders });
    }

}
