import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { CatalogueSectionsModel } from 'src/app/core/quotation/_models/catalogue-section.model';
import { CatalogueSectionService } from 'src/app/core/quotation/_services/section-catalogue.service';

import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout/services/subheader.service';
import { SectionCatalogueEditComponent } from '../section-catalogue-edit/section-catalogue-edit.component';

@Component({
  selector: 'kt-section-catalogue-list',
  templateUrl: './section-catalogue-list.component.html',
  styleUrls: []
})
export class SectionCatalogueListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<CatalogueSectionsModel>;
  displayedColumns = ['IdSeccion', 'Seccion','Estado', 'actions'];
  private subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
    private cbs: CatalogueSectionService,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista Secciones', '');

    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.benefits);
      });

    this.subscriptions.push(this.subheaderService.setNewCatalogue$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if (bc) {
          this.add();
          this.subheaderService.setNewCatalogue(false);
        }
      });
    }));
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }
  add() {
    const catalogue = new CatalogueSectionsModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }
  edit(benefits: CatalogueSectionsModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdSeccion > 0 ? 'Agregar usuario' : 'Nuevo Usuario';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdSeccion > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(SectionCatalogueEditComponent, { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.cbs.getAll().subscribe(next => {
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      });
    });
  }
}
