<div class="card card-custom"
     [ngClass]="{'card-body-progress' : viewLoading, 'card-body-progress card-body-progress-overlay' : loadingAfterSubmit }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{ data.descripction }}</h3>
    </div>
  </div>
  <div class="form">
    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Opcion 1.</label>
          <input type="text" formControlName="o" class="form-control"/>
        </div>
        <div class="col-lg-6">
          <label>Opcion 2.</label>
          <input type="text" formControlName="o" class="form-control"/>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-6">
          <label>Opcion 3.</label>
          <input type="text" formControlName="o" class="form-control"/>
        </div>
        <div class="col-lg-6">
          <label>Opcion 4.</label>
          <input type="text" formControlName="o" class="form-control"/>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" mat-raised-button color="primary">Guardar</button>&nbsp;
          <button type="button" (click)="onNoClick()" mat-raised-button>Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</div>
