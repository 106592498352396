import { BaseModel } from '../../_base/crud';

export class RateKidsPutModel extends BaseModel {
  IdTarifaSolo: number;
  IdPlan: number;
  RangoN1: number;
  RangoN2: number;

  clear() {
    this.IdTarifaSolo = 0;
    this.IdPlan = 0;
    this.RangoN1 = 0;
    this.RangoN2 = 0;
  }
}
