// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor (private menuConfigService: MenuConfigService) {
    this.loadMenu();
  }

  /**
   * Load menu list
   */
  loadMenu() {
    // get menu list
    const a = Number(localStorage.getItem('p'));
    let v;
    if(a === 1){
      v = 'aside.items';
    }else{
      v = 'aside.items2';
    }
    const menuItems: any[] = objectPath.get(this.menuConfigService.getMenus(), v);

    this.menuList$.next(menuItems);
  }
}
