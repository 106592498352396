<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{getTitle()}}</h3>
    </div>
  </div>
  <form class="form" [formGroup]="benefitsForm">
    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <kt-alert type="warn" *ngIf="hasFormErrors" [duration]="30000" [showCloseButton]="true"
        (close)="onAlertClose($event)">
        {{ ms }}
      </kt-alert>
      <div class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>Desde</label>
            <input type="text" class="form-control" formControlName="DescRango1" name="DescRango1">
            <!-- <span class="form-text text-muted" *ngIf="!isControlHasError('DescBeneficio', 'required')">Detalle las características de este beneficio.</span> -->
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('DescRango1', 'required')">{{
              "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Hasta</label>
            <input type="text" class="form-control" formControlName="DescRango2" name="DescRango2">
            <!-- <span class="form-text text-muted" *ngIf="!isControlHasError('DescBeneficio', 'required')">Detalle las características de este beneficio.</span> -->
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('DescRango2', 'required')">{{
              "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">


            <label>Estado</label>
            <mat-select class="form-control" formControlName="Estado">
              <mat-option value="1">ACTIVO</mat-option>
              <mat-option value="0">INACTIVO</mat-option>
            </mat-select>

            <div class="fv-plugins-message-container" *ngIf="isControlHasError('Estado', 'required')">{{
              "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
            matTooltip="{{ 'AUTH.GENERAL.CANCEL_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.CANCEL' | translate }}
          </button>&nbsp;
          <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="viewLoading"
            matTooltip="{{ 'AUTH.GENERAL.SAVE_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>