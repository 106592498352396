import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {MatDialog} from '@angular/material/dialog';
import {BeniftCatalogueEditComponent} from '../benift-catalogue-edit/benift-catalogue-edit.component';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'kt-benift-catalogue-list',
  templateUrl: './benift-catalogue-list.component.html',
  styles: [
  ]
})
export class BeniftCatalogueListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<CatalogueBenefitsModel>;
  displayedColumns = ['IdCatalogoBeneficio', 'DescBeneficio', 'Costo', 'actions'];
  private subscriptions: Subscription[] = [];


  constructor(public dialog: MatDialog,
              private cbs: CatalogueBenefitsService,
              private layoutUtilsService: LayoutUtilsService,
              private route: ActivatedRoute,
              private subheaderService: SubheaderService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Catalogo de beneficios', 'Este es el catalogo de benficios');
    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.benefits);
      });

    this.subscriptions.push(this.subheaderService.setNewCatalogue$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){
          this.add();
          this.subheaderService.setNewCatalogue(false);
        }
      });
    }));
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  edit(benefits: CatalogueBenefitsModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdCatalogoBeneficio > 0 ? 'Agregar usuario' : 'Nuevo Usuario';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdCatalogoBeneficio > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(BeniftCatalogueEditComponent,  { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.cbs.getAll().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
    });
  }


  add() {
    const catalogue = new CatalogueBenefitsModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }

}
