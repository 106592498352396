import { BaseModel } from '../../_base/crud';

export class AnotherRateConfigModel extends BaseModel {
    IdRango: number;
    Rango: string;
    RangoMaximo: number;
    RangoMinimo: number;
    Estado: number;

    clear() {
        this.IdRango = 0;
        this.Rango = '';
        this.RangoMaximo = 0;
        this.RangoMinimo = 0;
        this.Estado = 0;
    }
}
