<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Basic demo</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cotiar normal</button>
    <button type="button" class="btn btn-primary">Cotizar como niño solo</button>
  </div>
</ng-template>
<div class="row">
  <div class="col-xl-12">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>place</mat-icon>
            Planes con cobertura nacional
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>


        <div class="row border-bottom">
          <ng-template ngFor let-plan [ngForOf]="plans">
          <div class="col-6" *ngIf="okplan(plan)">
            <!--begin::Mixed Widget 10-->
            <div class="card card-custom gutter-b" style="height: 150px">
              <!--begin::Body-->
              <div class="card-body d-flex align-items-center justify-content-between flex-wrap">
                <div class="mr-2">
                  <h3 class="font-weight-bolder">{{ plan.DescPlan }}</h3>
                  <div class="text-dark-50 font-size-lg mt-2">{{ plan.Descripcion }}</div>
                  <div class="text-dark-50 font-size-lg mt-2">{{ plan.Region }}</div>
                </div>
                <a (click)="goToForm(plan)" *ngIf="plan.Estado != 3" class="btn btn-primary font-weight-bold py-3 px-6">Cotizar Ahora</a>
                <a (click)="goToForm(plan)" *ngIf="plan.Estado == 3"class="btn btn-secondary font-weight-bold py-3 px-6">Cotizar Ahora</a>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Mixed Widget 10-->
          </div>
          </ng-template>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="international">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon>airplanemode_active</mat-icon>
            Planes con cobertura Internacional
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row border-bottom">
          <div class="col-6" *ngFor="let plan of plans | plansfilter:filterargsInter" >
            <!--<div class="col-6" *ngFor="let plan of plans | plansfilter: filterargsInter" >-->
              <!--begin::Mixed Widget 10-->
            <div class="card card-custom gutter-b" style="height: 150px">
              <!--begin::Body-->
              <div class="card-body d-flex align-items-center justify-content-between flex-wrap">
                <div class="mr-2">
                  <h3 class="font-weight-bolder">{{ plan.DescPlan }}</h3>
                  <div class="text-dark-50 font-size-lg mt-2">{{ plan.Descripcion }}</div>
                  <div class="text-dark-50 font-size-lg mt-2">{{ plan.Region }}</div>
                </div>
                <a routerLink="/cotizar/formulario/individual" class="btn btn-info font-weight-bold py-3 px-6">Cotizar Ahora</a>
              </div>
              <!--end::Body-->
            </div>
            <!--end::Mixed Widget 10-->
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


