import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { join } from 'lodash';
import { CharacteristicModel } from 'src/app/core/quotation/_models/catalogue-charecteristics.model';
import { CharecteristicsTemplateItemsModel } from 'src/app/core/quotation/_models/charecteristics-template-items.model';
import { CharecteristicsTemplateModel } from 'src/app/core/quotation/_models/charecteristics-template.model';
import { CatalogueCharacteristicsService } from 'src/app/core/quotation/_services/catalogue-charecteristics.service';
import {LayoutUtilsService} from '../../../../../../../core/_base/crud';

/* export interface DialogData {
  isPool: boolean;
  idSeccion: CharecteristicsTemplateItemsModel[];
} */

@Component({
  selector: 'kt-charecteristics-select',
  templateUrl: './charecteristics-select.component.html',
  styles: []
})
export class CharecteristicsSelectComponent implements OnInit {
  viewLoading = false;
  hasFormErrors = false;
  CharecteristicsSelectForm: FormGroup;
  charecteristics: CharacteristicModel[];
  toppings = new FormControl();
  toppingList: string[] = ['Cobertura ambulatoria', 'Medicina ambulatoria', 'Coertura ambulatoria', 'Cuarto y alimento diario libre elección', 'Limite maximo por incapacidad por persona', 'Cuarto y alimento diario en GHK'];
  catalogues: CharacteristicModel;

  constructor(public dialogRef: MatDialogRef<CharecteristicsSelectComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any = [],
              private fb: FormBuilder,
              private layoutUtilsService: LayoutUtilsService,
              private catalogue: CatalogueCharacteristicsService) { }

  ngOnInit(): void {
    console.log(this.data[0].idSeccion);
    this.catalogue.getByDisponibility(this.data[0].isPool).subscribe((data) => {
      this.charecteristics = data;
      console.log(data);
      this.createForm();
    });
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.CharecteristicsSelectForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  createForm() {
    this.CharecteristicsSelectForm = this.fb.group({
      topping: ['']
    });
  }

  prepareCatalogues() {
    const controls = this.CharecteristicsSelectForm.controls;
    let idC;
    let tempData = [];
    
    idC = controls.topping.value;
    for (let j = 0; j < idC.length; j++) {
      var characteristicSelected = this.charecteristics.find(obj => {return obj.IdCaracteristica === idC[j]});
      if(characteristicSelected != null){
        tempData.push({
          IdCharecteristics: characteristicSelected.IdCaracteristica,
          Charecteristics: characteristicSelected.Caracteristica,
          status: characteristicSelected.Estado,
          Posicion: 0,
          isPool: this.data[0].isPool
        });
      }
    }
    for (let i = 0; i < tempData.length; i++){
      this.data[0].idSeccion.Charecteristics.push(tempData[i]);
    }
  }

  guardar(){
    this.prepareCatalogues();
  }

  cerrar(){
    this.dialogRef.close();
  }
}
