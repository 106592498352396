import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';

@Component({
  selector: 'kt-benift-catalogue-edit',
  templateUrl: './benift-catalogue-edit.component.html',
  styles: [
  ]
})
export class BeniftCatalogueEditComponent implements OnInit, OnDestroy {
  benefits: CatalogueBenefitsModel;
  hasFormErrors = false;
  benefitsForm: FormGroup;
  viewLoading = false;

  constructor(
    public dialogRef: MatDialogRef<BeniftCatalogueEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private cbs: CatalogueBenefitsService
  ) { }

  private componentSubscriptions: Subscription;

  ngOnInit(): void {
    this.benefits = this.data.benefits;
    this.createForm();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getTitle(): string {
    if (this.benefits.IdCatalogoBeneficio > 0) {
      return 'Editar beneficio';
    }

    return 'Nuevo beneficio';
  }

  createForm() {
    this.benefitsForm = this.fb.group({
      DescBeneficio: [this.benefits.DescBeneficio, Validators.required],
      Costo: [this.benefits.Costo, Validators.required]
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.benefitsForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  save(){
    const controls = this.benefitsForm.controls;

    if (this.benefitsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    if (this.benefits.IdCatalogoBeneficio > 0) {
      const benefitPreparate = new CatalogueBenefitsModel();
      benefitPreparate.clear();
      benefitPreparate.DescBeneficio = controls.DescBeneficio.value;
      benefitPreparate.Costo = controls.Costo.value;
      benefitPreparate.IdCatalogoBeneficio = this.benefits.IdCatalogoBeneficio;
      this.update(benefitPreparate);
    } else {
      const benefitPreparate = new CatalogueBenefitsModel();
      benefitPreparate.clear();
      benefitPreparate.DescBeneficio = controls.DescBeneficio.value;
      benefitPreparate.Costo = controls.Costo.value;

      this.create(benefitPreparate);
    }
  }

  preparate(controls){
    const benefitPreparate = new CatalogueBenefitsModel();
    benefitPreparate.clear();
    benefitPreparate.DescBeneficio = controls.DescBeneficio;
    benefitPreparate.Costo = controls.Costo;
    return benefitPreparate;
  }

  create(benefits: CatalogueBenefitsModel) {

    this.cbs.add(benefits).subscribe( next =>{
      console.log(next);
      const message = `El nuevo catalogo fue agregado con exito.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e =>{
      this.hasFormErrors = true;
    } );
  }

  update(benefits: CatalogueBenefitsModel){
    this.cbs.update(benefits).subscribe( next =>{
      console.log(next);
      const message = 'El catalogo ' + benefits.IdCatalogoBeneficio + ' fue actualizado con exito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ benefits, isEdit: false });
    }, e =>{
      this.hasFormErrors = true;
    } );
  }

}
