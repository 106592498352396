import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';

import { mergeMap, take } from 'rxjs/operators';
import {ProductsService} from '../../../quotation/_services';

@Injectable({
  providedIn: 'root'
})
export class ValidatePlansResolverService implements Resolve<any> {
  constructor (private ps: ProductsService, private router: Router) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const IdPlan = route.paramMap.get('id');

    return this.ps.evalById(IdPlan).pipe(

      take(1),
      mergeMap(crisis => {

          return of(crisis);

      })
    );
  }
}
