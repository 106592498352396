import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewRef} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {fromEvent, merge, of, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {debounceTime, delay, distinctUntilChanged, skip, take, tap} from 'rxjs/operators';
import {CustomerEditDialogComponent} from '../../customers/customer-edit/customer-edit.dialog.component';
import {ProductModel, ProductsService, UserModel} from '../../../../../../core/quotation';
import {QuoteService} from '../../../../../../core/quotation/_services/quote.service';
import {QuoteModel} from '../../../../../../core/quotation/_models/quote.model';
import {BackQuoteResModel} from '../../../../../../core/quotation/_models/_in/back-quote-res.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import * as moment from 'moment';
import { BackPlantillasResModel } from 'src/app/core/quotation/_models/_in/back-plantillas-res.model';
import { BackPaginatedResponse } from 'src/app/core/quotation/_models/_in/back-paginated-response-model';

@Component({
  selector: 'kt-quotes-list',
  templateUrl: './quotes-list.component.html',
  styles: [
  ]
})
export class QuotesListComponent implements OnInit, OnDestroy {

  // Table fields
  dataSource: BackPaginatedResponse<BackQuoteResModel>;
  planDataSource: ProductModel[];
  displayedColumns = ['IdContizacion', 'FechaCotizacion', 'Cliente', 'NombreProducto', 'NombrePlan', 'Region', 'Estado', 'actions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  dateSince:Date;
  dateUntil:Date;
  dateNow:Date = new Date();
  filterStatus = '';
  filterPlan = '0';
  filterType = '';
  // Selection
  selection = new SelectionModel<UserModel>(true, []);
  customersResult: UserModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];
  quotes;
  v: any;
  //Others
  loading = true;
  showAlert = false;
  messageAlert='';


  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param snackBar: MatSnackBar
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   */
  constructor(
    public quoteService: QuoteService,
    public planService: ProductsService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef
  ) { }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const a = Number(localStorage.getItem('p'));
    if(a === 1){
      this.v = '0';
    }else{
      this.v = Number(localStorage.getItem('n'))
    }

    this.load();
    this.loadPlan();
    this.subscriptions.push(this.subheaderService.reset$.subscribe(bc => {
      this.paginator?.firstPage();
      this.load();
      this.loadPlan();
      this.cdr.detectChanges();
    }));
    // If the user changes the sort order, reset back to the first page.

    // Init DataSource
    // this.dataSource = this.quoteService.getQuotes(Number(localStorage.getItem('n'))).subscribe(res=>this.quotes=res);;
    /* const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.customersResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadCustomersList();
    }); // Remove this line, just loading imitation */
  }

  load(){
    this.loading = true;
    this.quoteService.getQuotesByUser(
      this.paginator?.pageSize ?? 50,
      this.paginator?.pageIndex ?? 0,
      this.v,
      this.dateSince,
      this.dateUntil,
      this.filterPlan ? this.filterPlan  : 0
      ).subscribe(
      res=> {
        this.loading = false;
        this.dataSource = res;
        this.cdr.detectChanges();
      }
    ); 
  }

  loadPlan(){
    this.planService.getActivePlans().subscribe(
      res=>{
        this.planDataSource = res;
        this.cdr.detectChanges();
      }
    )
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /**
   * Event emitted when the datepickerinput changes.
   **/
  onlyKeyPressDEL(event){
    if(event.code == 127){
      return true;
    }else{
      return false;
    }
  }

   /**
   * call request from server.
   **/
  buttonFilter(){
    this.showAlert = false;
    this.messageAlert = '';
    if(this.dateSince && !this.dateUntil){
      this.showAlert = true;
      this.messageAlert = 'Debe ingresar un rango de fechas';
      return;
    }
    this.paginator?.firstPage();
    this.load();
  }

  /**
   * Closed alert.
   **/
  closeAlert(){
    this.showAlert = false;
    this.messageAlert = '';
  }

  /**
   * Event emitted when the datepickerinput changes.
   **/
  changeDateSince(event){
    this.showAlert = false;
    this.dateSince = event.value?.toISOString();
    if(this.dateSince == undefined){
      this.dateUntil = undefined;
    }

    if(this.dateUntil){
      var since = moment(this.dateSince);
      var until = moment(this.dateUntil);
      var diff = until.diff(since,'days',true);
      if(diff < 0){
        this.dateUntil = undefined;
      }
    }
  }

  /**
   * Event emitted when the datepickerinput changes.
   **/
  changeDateUntil(event){
    this.dateUntil = event.value?.toISOString();
  }

  // changeSelectionPlan(){
  //   this.paginator?.pageIndex == 0;
  //   this.load();
  // }

  /**
   * Load Customers List from service through data-source
   */
  loadCustomersList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    // this.store.dispatch(new CustomersPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.status = +this.filterStatus;
    }

    if (this.filterType && this.filterType.length > 0) {
      filter.type = +this.filterType;
    }

    filter.lastName = searchText;
    if (!searchText) {
      return filter;
    }

    filter.firstName = searchText;
    filter.email = searchText;
    filter.ipAddress = searchText;
    return filter;
  }

  /** ACTIONS */
  /**
   * Delete customer
   *
   * @param _item: CustomerModel
   */
   deleteCustomer(_item: any) {

  }

  /**
   * Delete selected customers
   */
  deleteCustomers() {

  }

  /**
   * Fetch selected customers
   */
  fetchCustomers() {
    const messages = [];
    /* this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.lastName}, ${elem.firstName}`,
        id: elem.id.toString(),
        status: elem.status
      });
    }); */
    this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Show UpdateStatuDialog for selected customers
   */
  updateStatusForCustomers() { /*
    const _title = this.translate.instant('ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE');
    const _updateMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE');
    const _statuses = [{ value: 0, text: 'Suspended' }, { value: 1, text: 'Active' }, { value: 2, text: 'Pending' }];
    const _messages = [];

    this.selection.selected.forEach(elem => {
      _messages.push({
        text: `${elem.lastName}, ${elem.firstName}`,
        id: elem.id.toString(),
        status: elem.status,
        statusTitle: this.getItemStatusString(elem.status),
        statusCssClass: this.getItemCssClassByStatus(elem.status)
      });
    });

    const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        this.selection.clear();
        return;
      }

      this.store.dispatch(new CustomersStatusUpdated({
        status: +res,
        customers: this.selection.selected
      }));

      this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update, 10000, true, true);
      this.selection.clear();
    }); */
  }

  /**
   * Show add customer dialog
   */
  addCustomer() {
    /*
    const newCustomer = new CustomerModel();
    newCustomer.clear(); // Set all defaults fields
    this.editCustomer(newCustomer); */
  }

  /**
   * Show Edit customer dialog and save after success close result
   * @param customer: CustomerModel
   */
  edit(q: BackQuoteResModel) {
    console.log('editar');

    let a = 'sinBeneficios';
    let b = 'false';

    if(q.TitularBeneficio){
      a = 'conBeneficios';
    }

    if(q.IdProducto == 2){
      b = 'true';
    }

    let url = '/cotizar/edit/formulario/'+ q.IdPlan +'/'+ q.IdContizacion +'/'+ a +'/'+ b;
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.customersResult.length;
    return numSelected === numRows;
  }

  view(q: BackQuoteResModel) {
    console.log('editar');

    let a = 'sinBeneficios';
    let b = 'false';

    if(q.TitularBeneficio){
      a = 'conBeneficios';
    }

    if(q.IdProducto == 2){
      b = 'true';
    }

    let url = '/cotizar/ver/'+ q.IdContizacion +'/'+ b + '/pdf';
    this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
  }

  /**
   * Toggle all selections
   */
  masterToggle() {
    if (this.selection.selected.length === this.customersResult.length) {
      this.selection.clear();
    } else {
      this.customersResult.forEach(row => this.selection.select(row));
    }
  }

  /** UI */
  /**
   * Retursn CSS Class Name by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status): string {
    if(!status){
      return 'success';
    }else{
      return 'danger';
    }
  }

  /**
   * Returns Item Status in string
   * @param status: number
   */
  getItemStatusString(status): string {
    if(status){
      return 'Activo';
    }else{
      return 'Inactivo';
    }
  }

  /**
   * Returns CSS Class Name by type
   * @param status: number
   */
  getItemCssClassByType(status: number = 0): string {
    switch (status) {
      case 0:
        return 'primary';
      case 1:
        return 'danger';
      case 2:
        return 'success';
    }
    return 'success';
  }

  /**
   * Returns Item Type in string
   * @param status: number
   */
  getItemTypeString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Business';
      case 1:
        return 'Individual';
    }
    return '';
  }
}
