import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CategoryModel} from '../_models/category.model';
import {HttpUtilsService} from '../../_base/crud';

const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
  providedIn: 'root'
})

export class CategoryService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(){
    const options = {};
    return this.http.get<CategoryModel[]>(URL_API.url + 'GetCategorias' , options);
  }
}
