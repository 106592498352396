// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ProductCharecteristicsActionsType, ProductCharecteristicsActions } from '../_actions/product-charecteristics.actions';
// Models
import { ProductCharecteristicsModel } from '../_models/product-charecteristics.model';
import { QueryParamsModel } from '../../_base/crud';

export interface ProductCharecteristicsState extends EntityState<ProductCharecteristicsModel> {
  productId: number;
  loading: boolean;
  totalCount: number;
  lastCreatedProductCharacteristicId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ProductCharecteristicsModel> = createEntityAdapter<ProductCharecteristicsModel>({
  selectId: nameofclass => nameofclass.IdPlantillaC
});

export const initialProductCharacteristicsState: ProductCharecteristicsState = adapter.getInitialState({
  loading: false,
  totalCount: 0,
  productId: undefined,
  lastCreatedProductCharacteristicId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true
});

export function productCharacteristicsReducer(state = initialProductCharacteristicsState, action: ProductCharecteristicsActions): ProductCharecteristicsState {
  switch (action.type) {
    case ProductCharecteristicsActionsType.ProductCharacteristicsPageToggleLoading:
      return {
        ...state,
        loading: action.payload.isLoading,
        lastCreatedProductCharacteristicId: undefined
      };
    case ProductCharecteristicsActionsType.ProductCharacteristicUpdated:
      return adapter.updateOne(action.payload.partialProductCharacteristic, state);
    case ProductCharecteristicsActionsType.ProductCharacteristicStoreUpdated:
      return adapter.updateOne(action.payload.productCharacteristic, state);
    case ProductCharecteristicsActionsType.ProductCharacteristicsPageCancelled:
      return {...state, totalCount: 0, loading: false, productId: undefined, lastQuery: new QueryParamsModel({})};
    case ProductCharecteristicsActionsType.ProductCharacteristicsPageRequested:
      return {
        ...state,
        totalCount: 0,
        loading: true,
        productId: action.payload.productId,
        lastQuery: action.payload.page
      };
    case ProductCharecteristicsActionsType.ProductCharacteristicsPageLoaded:
      return adapter.addMany(action.payload.productCharacteristics, {
        ...initialProductCharacteristicsState,
        totalCount: action.payload.totalCount,
        loading: false,
        productId: state.productId,
        lastQuery: state.lastQuery,
        showInitWaitingMessage: false
      });
    default:
      return state;
  }
}

export const getProductCharacteristicsState = createFeatureSelector<ProductCharecteristicsModel>('productCharecteristics');

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
