<kt-portlet>
  <kt-portlet-body>

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel *ngFor='let item of Feactures;index as i'>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Seccion #{{ item.IdSeccion }}.
          </mat-panel-title>
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>arrow_upward</mat-icon>
          </button>
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>arrow_downward</mat-icon>
          </button>
          <button (click)="add1(i)" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
            <mat-icon>add_box</mat-icon>
          </button>
          <mat-panel-description>
            {{ item.Seccion }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, i)">
          <div class="example-box" *ngFor="let CaracteristicasSeccion of item.Charecteristics;index as a" cdkDrag> {{CaracteristicasSeccion.Charecteristics}}
            <div *ngIf = "CaracteristicasSeccion.Charecteristics != ''">
            <button (click)="elimina(i,a)" mat-icon-button color="warn" aria-label="Eliminar">
              <mat-icon>delete</mat-icon>
            </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
