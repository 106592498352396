import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GeneralConfigModel} from '../../../../../../core/quotation/_models/general-config.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../../../core/quotation';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {GeneralConfigService} from '../../../../../../core/quotation/_services/general-config.service';
import {UserLoaded} from '../../../../../../core/auth';

@Component({
  selector: 'kt-products-settings',
  templateUrl: './products-settings.component.html',
  styles: [
  ]
})
export class ProductsSettingsComponent implements OnInit, OnDestroy {

  settingsForm: FormGroup;

  Conf_Des_Contado: GeneralConfigModel;
  Conf_SSC: GeneralConfigModel;
  UsuarioInhabilitado: GeneralConfigModel;
  PeriodoPrueba: GeneralConfigModel;
  Conf_Valor_Max_Dependientes: GeneralConfigModel;
  Conf_plan_comparar: GeneralConfigModel;
  Conf_edad_dep_max: GeneralConfigModel;
  Conf_Edad_Cotiza: GeneralConfigModel;
  Conf_edad_ninosolo_max: GeneralConfigModel;
  Conf_Observaciones_Pdf: GeneralConfigModel;
  hasFormErrors = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private gcs: GeneralConfigService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data) => {
        const config: GeneralConfigModel[] = data.config;
        this.Conf_Des_Contado = config.find( c => c.DescConfiguracion == 'Conf_Des_Contado');
        this.Conf_SSC = config.find( c => c.DescConfiguracion == 'Conf_SSC');
        this.UsuarioInhabilitado = config.find( c => c.DescConfiguracion == 'UsuarioInhabilitado');
        this.PeriodoPrueba = config.find( c => c.DescConfiguracion == 'PeriodoPrueba');
        this.Conf_Valor_Max_Dependientes = config.find( c => c.DescConfiguracion == 'Conf_Valor_Max_Dependientes');
        this.Conf_plan_comparar = config.find( c => c.DescConfiguracion == 'Conf_plan_comparar');
        this.Conf_edad_dep_max = config.find( c => c.DescConfiguracion == 'Conf_edad_dep_max');
        this.Conf_Edad_Cotiza = config.find( c => c.DescConfiguracion == 'Conf_Edad_Cotiza');
        this.Conf_edad_ninosolo_max = config.find( c => c.DescConfiguracion == 'Conf_edad_niñosolo_max');
        this.Conf_Observaciones_Pdf = config.find( c => c.DescConfiguracion == 'Conf_Observaciones_Pdf');

        this.initForm();
      });

    this.subscriptions.push(this.subheaderService.save$.subscribe(bc => {
      this.save();
      this.cdr.detectChanges();
    }));
  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.subscriptions.forEach(el => el.unsubscribe());

  }

  initForm(){
    this.settingsForm = this.fb.group({
      Conf_Des_Contado: [this.Conf_Des_Contado.Valor, Validators.compose([Validators.required])],
      Conf_SSC: [this.Conf_SSC.Valor, Validators.compose([Validators.required])],
      UsuarioInhabilitado: [this.UsuarioInhabilitado.Valor, Validators.compose([Validators.required])],
      PeriodoPrueba: [this.PeriodoPrueba.Valor, Validators.compose([Validators.required])],
      Conf_Valor_Max_Dependientes: [this.Conf_Valor_Max_Dependientes.Valor, Validators.compose([Validators.required])],
      Conf_plan_comparar: [this.Conf_plan_comparar.Valor, Validators.compose([Validators.required])],
      Conf_edad_dep_max: [this.Conf_edad_dep_max.Valor, Validators.compose([Validators.required])],
      Conf_Edad_Cotiza: [this.Conf_Edad_Cotiza.Valor, Validators.compose([Validators.required])],
      Conf_edad_ninosolo_max: [this.Conf_edad_ninosolo_max.Valor, Validators.compose([Validators.required])],
      Conf_Observaciones_Pdf: [this.Conf_Observaciones_Pdf.Descripcion, Validators.compose([Validators.required])]
    });
  }

  ourAlert(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }

  save(){
    this.hasFormErrors = false;
    const controls = this.settingsForm.controls;
    if (this.settingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.ourAlert('Debe ingresar todos los valores');
      return;
    }
    const _config: GeneralConfigModel[] = [];

    this.Conf_Des_Contado.Valor = controls.Conf_Des_Contado.value;
    this.Conf_SSC.Valor = controls.Conf_SSC.value;
    this.UsuarioInhabilitado.Valor = controls.UsuarioInhabilitado.value;
    this.PeriodoPrueba.Valor = controls.PeriodoPrueba.value;
    this.Conf_Valor_Max_Dependientes.Valor = controls.Conf_Valor_Max_Dependientes.value;
    this.Conf_plan_comparar.Valor = controls.Conf_plan_comparar.value;
    this.Conf_edad_dep_max.Valor = controls.Conf_edad_dep_max.value;
    this.Conf_Edad_Cotiza.Valor = controls.Conf_Edad_Cotiza.value;
    this.Conf_edad_ninosolo_max.Valor = controls.Conf_edad_ninosolo_max.value;
    this.Conf_Observaciones_Pdf.Descripcion = controls.Conf_Observaciones_Pdf.value;

    _config.push(this.Conf_Des_Contado);
    _config.push(this.Conf_SSC);
    _config.push(this.UsuarioInhabilitado);
    _config.push(this.PeriodoPrueba);
    _config.push(this.Conf_Valor_Max_Dependientes);
    _config.push(this.Conf_plan_comparar);
    _config.push(this.Conf_edad_dep_max);
    _config.push(this.Conf_Edad_Cotiza);
    _config.push(this.Conf_edad_ninosolo_max);
    _config.push(this.Conf_Observaciones_Pdf);

    this.gcs.update(_config).subscribe(
      v => {
        // Actualizacion de configuracion con exito
        if( v.Mensaje === 'Actualizacion de configuracion con exito' ){
          this.ourAlert(v.Mensaje, 'accent');
          this.cdr.detectChanges();
        } else {
          this.ourAlert('Rayos! Ago salio mal por favor intente de nuevo.');
          this.cdr.detectChanges();
        }
        console.log(v);
      },
      e => {
        if(e.error.Mensaje){
          this.ourAlert(e.error.Mensaje);
          this.cdr.detectChanges();
        } else {
          this.ourAlert('Rayos! Ago salio mal en el servidor por favor intente de nuevo.');
          this.cdr.detectChanges();
        }
      }
    )
  }



}
