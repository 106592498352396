// Context
// Models and Consts
export { ProductModel } from './_models/product.model';
export { UserModel } from './_models/user.model';
export { ProductCharecteristicsModel } from './_models/product-charecteristics.model';
export { RateCategoryModel } from './_models/rate-category.model';
export { SPECIFICATIONS_DICTIONARY } from './_consts/specification.dictionary';

// DataSources
export { ProductsDataSource } from './_data-sources/products.datasource';
export { ProductCharecteristicsDatasource } from './_data-sources/product-charecteristics.datasource';

// Actions
// Product actions =>
export {
    ProductActionTypes,
    ProductActions,
    ProductOnServerCreated,
    ProductCreated,
    ProductUpdated,
    ProductsStatusUpdated,
    OneProductDeleted,
    ManyProductsDeleted,
    ProductsPageRequested,
    ProductsPageLoaded,
    ProductsPageCancelled,
    ProductsPageToggleLoading,
    ProductsActionToggleLoading
} from './_actions/product.actions';

export {
  ProductCharecteristicsActionsType,
  ProductCharecteristicsActions,
  ProductCharacteristicUpdated,
  ProductCharacteristicStoreUpdated,
  ProductCharacteristicsPageRequested,
  ProductCharacteristicsPageLoaded,
  ProductCharacteristicsPageCancelled,
  ProductCharacteristicsPageToggleLoading,
} from './_actions/product-charecteristics.actions';

export {
  RateCategoryActionsType,
  RateCategoryActions,
  RateCategoryUpdated,
  RateCategoryStoreUpdated,
  RateCategoryPageRequested,
  RateCategoryPageLoaded,
  RateCategoryPageCancelled,
  RateCategoryPageToggleLoading,
} from './_actions/rate-category.actions';


// Effects
export { ProductEffects } from './_effects/product.effects';
export { ProductCharacteristicEffects } from './_effects/product-characteristic.effects';

// Reducers
export { productsReducer } from './_reducers/product.reducers';
export { productCharacteristicsReducer } from './_reducers/product-charecteristics.reducers';

// Selectors
// Product selectors
export {
    selectProductById,
    selectProductsInStore,
    selectProductsPageLoading,
    selectProductsPageLastQuery,
    selectLastCreatedProductId,
    selectHasProductsInStore,
    selectProductsActionLoading,
    selectProductsInitWaitingMessage
} from './_selectors/product.selectors';

export {
  selectProductCharecteristicsById,
  selectProductCharecteristicsInStore,
  selectProductCharecteristicsPageLoading,
  selectLastCreatedProductCharecteristicsId,
  selectPRShowInitWaitingMessage
} from './_selectors/product-charecteristics.selectors';

// Services
export { ProductsService } from './_services/';
export { ProductsCharecteristicService } from './_services/';
