// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material/dialog';
// RxJS
import {Observable, BehaviorSubject, Subscription, of, Subject} from 'rxjs';
import { map, startWith, delay, first } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import {
	selectLastCreatedProductId,
	ProductModel,
	ProductOnServerCreated,
	ProductUpdated,
	ProductsService
} from '../../../../../../core/quotation';
import {environment} from '../../../../../../../environments/environment';
import { RegionService } from 'src/app/core/quotation/_services/region.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-product-edit',
	templateUrl: './product-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductEditComponent implements OnInit, OnDestroy {
	// Public properties
	product: ProductModel;
	productId$: Observable<number>;
  _editable: boolean;
	oldProduct: ProductModel;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	productForm: FormGroup;
	hasFormErrors = false;
	availableYears: number[] = [];
	filteredColors: Observable<string[]>;
	filteredManufactures: Observable<string[]>;
	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

  private subscriptions: Subscription[] = [];

  Tarifas = [
    {IdTipoTarifa: 1, DescTipoTarifa: 'Por categoría'},
    {IdTipoTarifa: 2, DescTipoTarifa: 'Por rango de edades'},
    {IdTipoTarifa: 3, DescTipoTarifa: 'Por genero'},
	{IdTipoTarifa: 4, DescTipoTarifa: 'Por cada edad'}
  ];

  Productos = [
    {id: 1, name: 'Individual'},
    {id: 2, name: 'Pool'}
  ];

  TipoCaracteristicas = [
    {id: 1, name: 'Individual'},
    {id: 2, name: 'AsisPro'}
  ];


  editable = true;
  saveError = false;
  isNewProduct = true;
  isIndividual = true;
  charecteristic = true;

  idTarifacion: number;
  idTarifacionFor: number;

  SelectedTarifa = 2;
  private sub: Subscription;

  nameRegion: '';
  region: any = [];
  isPool = false;


	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param productFB: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param productService: ProductsService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private productFB: FormBuilder,
		public dialog: MatDialog,
    private route: ActivatedRoute,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private productService: ProductsService,
		private regionService: RegionService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
  this.route.data
      .subscribe((data) => {
        this.editable = (data)?data.editable:true;
      });
	  this.loadRegion();

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
						this.loadProductFromService(id);
			} else {
				const newProduct = new ProductModel();
				newProduct.clear();
				this.loadProduct(newProduct);
			}
		});

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};

    this.subscriptions.push(this.subheaderService.save$.subscribe(bc => {
      this.onSumbit();
      this.cdr.detectChanges();
    }));
	}

	loadRegion(){
		this.regionService.getAllProducts().subscribe(data => {
			this.region = data;	
			console.log(this.region);
		})
	}

	loadProduct(_product, fromService: boolean = false) {
		if (!_product) {
			this.goBack('');
		}
		this.product = _product;
		this.productId$ = of(_product.IdPlan);
    this.charecteristic = true;
		this._editable = this.isEditableEval();
		this.oldProduct = Object.assign({}, _product);
		this.initProduct();
		if (fromService) {
			this.cdr.detectChanges();
		}
	}

  select(){
	  this.isIndividual = this.productForm.controls.IdProducto.value == 1;
	  if(this.productForm.controls.IdProducto.value == 2){
		this.isPool = true;
	  } else {
		  this.isPool = false;
	  }
  }

  selectNewTarifa(){
    this.idTarifacion = 0;
    this.layoutUtilsService.showActionNotification('Al cambiar la tarifacion debe guardar antes de continuar la edicion.', MessageType.Update, 10000, true, false);
  }

  selectNewTemplate(){
    this.charecteristic = false;
    this.layoutUtilsService.showActionNotification('Al cambiar la tarifacion debe guardar antes de continuar la edicion.', MessageType.Update, 10000, true, false);
  }

	// If product didn't find in store
	loadProductFromService(productId) {
		this.productService.getProductById(productId).subscribe(res => {
			this.loadProduct(res, true);
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.subscriptions.forEach(el => el.unsubscribe());
	}

	isEditableEval(){
	  if(this.isNewProduct){
	    return true;
    }else{
	    return this.product.isBorrador;
    }
  }

	/**
	 * Init product
	 */
	initProduct() {
		this.createForm();

		this.idTarifacion = this.product.IdTipoTarifa;
		this.loadingSubject.next(false);
    this.isNewProduct = false;
		if (!this.product.IdPlan) {
		  this.isNewProduct = true;
      this.subheaderService.setTitle('Nuevo Producto');
			return;
		}
		if(this.router.url !== '/productos/listar/planes') {
      this.idTarifacionFor = this.product.IdTipoTarifa;
      this.productForm.controls.tipoTarifa.setValue(this.idTarifacionFor);
		  if(this.product.isBorrador){
        this.subheaderService.setTitle('Editar Borrador', this.subtitle() );
      } else {
        this.subheaderService.setTitle('Ver Plan', this.subtitle() );
      }
    }
	}

	subtitle(){
	  return this.product.DescPlan + ' ' + this.product.Descripcion + ', ' + this.product.DescCobertura + ' (Version: ' + this.product.VersionNum + ")";
  }

	/**
	 * Create form
	 */
	createForm() {
		this.productForm = this.productFB.group({
      DescPlan: [this.product.DescPlan, Validators.required],
      IdProducto: [this.product.IdProducto, Validators.required ],
      Descripcion: [this.product.Descripcion, Validators.required],
      TipoPlantilla: [this.product.TipoPlantilla, Validators.required],
      ConBeneficios: [this.product.ConBeneficios],
      tipoTarifa: [this.product.IdTipoTarifa],
      ObligaMaternidad: [this.product.ObligaMaternidad],
      EdadMaxMaternidad: [this.product.EdadMaxMaternidad],
	  Region: [this.product.IdRegion, Validators.required],
	  VersionText: [this.product.VersionText, Validators.required]
		});



    this.isIndividual = (this.product.IdProducto == 1);

	if(this.product.IdProducto == 2){
		this.isPool = true;
	  } else {
		  this.isPool = false;
	  }

	}

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.productForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

	/**
	 * Filter manufacture
	 *
	 * @param val: string
	 */

	/**
	 * Filter color
	 *
	 * @param val: string
	 */
  compareItems2(i1, i2) {
    return i1 && i2 && i1.IdTipoTarifa===i2.IdTipoTarifa;
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.id===i2.id;
  }
	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/productos/editar/plan/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/productos/listar/planes', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh product
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshProduct(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/productos/editar/plan/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.product = Object.assign({}, this.oldProduct);
		this.createForm();
		this.hasFormErrors = false;
		this.productForm.markAsPristine();
		this.productForm.markAsUntouched();
		this.productForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.productForm.controls;
		/** check form */
		if (this.productForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

    if (typeof this.editable == 'undefined'){
      this.editable = true;

    }



    if(!this.editable){
      this.saveError = true;
      return;
    }


		// tslint:disable-next-line:prefer-const
		let editedProduct = this.prepareProduct();

		if (this.product.isBorrador) {
		  console.log('actualizando borrador');
			this.updateProduct(editedProduct, withBack);
			return;
		}
		this.addProduct(editedProduct, withBack);
	}

	getRegionName(id:any){
		const i = parseInt(id);
		for (let index = 0; index < this.region.length; index++) {
		  if(this.region[index].RegionID === i){
			return this.region[index].Descripcion;
		  }
		}
	  }

	/**
	 * Returns object for saving
	 */
	prepareProduct(): ProductModel {
		const controls = this.productForm.controls;
		const _product = new ProductModel();
		_product.clear();
		_product.DescPlan = controls.DescPlan.value;
		_product.IdProducto = controls.IdProducto.value;
		_product.Descripcion = controls.Descripcion.value;
		_product.ConBeneficios = controls.ConBeneficios.value;
		_product.IdTipoTarifa = (controls.IdProducto.value == 2)?1:controls.tipoTarifa.value;
		_product.Estado = 1;

		_product.VersionText = controls.VersionText.value;

		_product.IdRegion = controls.Region.value;
		_product.Region = this.getRegionName(controls.Region.value);

    _product.ObligaMaternidad = controls.Descripcion.value;
    _product.EdadMaxMaternidad = controls.EdadMaxMaternidad.value;
    _product.isBorrador = true;
    _product.TipoPlantilla = (controls.IdProducto.value == 2)?3:controls.TipoPlantilla.value;
    console.log(controls.TipoPlantilla.value);

    if(this.product.IdPlan){
      _product.Estado = 3;
      _product.isBorrador = true;
      _product.IdPlan = this.product.IdPlan;
    }

		return _product;
	}

	/**
	 * Add product
	 *
	 * @param _product: ProductModel
	 * @param withBack: boolean
	 */
	addProduct(_product: ProductModel, withBack: boolean = false) {
		this.loadingSubject.next(true);
		this.store.dispatch(new ProductOnServerCreated({ product: _product }));
		this.componentSubscriptions = this.store.pipe(
			select(selectLastCreatedProductId)
		).subscribe(newId => {
			if (!newId) {
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Nuevo producto creado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
				this.refreshProduct(true, newId);
			}
		});
	}

	/**
	 * Update product
	 *
	 * @param _product: ProductModel
	 * @param withBack: boolean
	 */
	updateProduct(_product: ProductModel, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateProduct: Update<ProductModel> = {
			id: _product.IdPlan,
			changes: _product
		};
		this.store.dispatch(new ProductUpdated({
			partialProduct: updateProduct,
			product: _product
		}));

			if (withBack) {
				this.goBack(_product.IdPlan);
			} else {
				const message = `Cambios guardados.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false);
				setTimeout (() => {
					this.ngOnInit();
				 }, 500);
				
			}
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Create product';
		if (!this.product || !this.product.IdPlan) {
			return result;
		}

		result = `Editar Plan`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
	  this.saveError = false;
		this.hasFormErrors = false;
	}
}
