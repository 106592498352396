// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { RateCategoryModel } from '../_models/rate-category.model';

export enum RateCategoryActionsType {
  RateCategoryUpdated = '[Edit RateCategory Dialog] RateCategory Updated',
  RateCategoryStoreUpdated = '[Edit RateCategory Dialog] RateCategory Updated | Only on storage',
  RateCategoryPageRequested = '[Rate Category List Page] Rate Category Page Requested',
  RateCategoryPageLoaded = '[Rate Category API] Rate Category Page Loaded',
  RateCategoryPageCancelled = '[Rate Category API] Rate Category Page Cancelled',
  RateCategoryPageToggleLoading = '[Rate Category] Rate Category Page Toggle Loading'
}

export class RateCategoryUpdated implements Action {
  readonly type = RateCategoryActionsType.RateCategoryUpdated;

  constructor(public payload: {
    partialRateCategory: Update<RateCategoryModel>, // For State update
    rateCategory: RateCategoryModel // For Server update (through service)
  }) {
  }
}

export class RateCategoryStoreUpdated implements Action {
  readonly type = RateCategoryActionsType.RateCategoryStoreUpdated;

  constructor(public payload: {
    rateCategory: Update<RateCategoryModel>, // For State update
  }) {
  }
}

export class RateCategoryPageRequested implements Action {
  readonly type = RateCategoryActionsType.RateCategoryPageRequested;

  constructor(public payload: { page: QueryParamsModel, productId: number }) {
  }
}

export class RateCategoryPageLoaded implements Action {
  readonly type = RateCategoryActionsType.RateCategoryPageLoaded;
  constructor(public payload: { rateCategory: RateCategoryModel[], totalCount: number }) {
  }
}

export class RateCategoryPageCancelled implements Action {
  readonly type = RateCategoryActionsType.RateCategoryPageCancelled;
}

export class RateCategoryPageToggleLoading implements Action {
  readonly type = RateCategoryActionsType.RateCategoryPageToggleLoading;

  constructor(public payload: { isLoading: boolean }) {
  }
}

export type RateCategoryActions = RateCategoryStoreUpdated
  | RateCategoryUpdated
  | RateCategoryPageRequested
  | RateCategoryPageLoaded
  | RateCategoryPageCancelled
  | RateCategoryPageToggleLoading;
