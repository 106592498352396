// Spain
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      NEW: 'Nuevo',
      ACTIONS: 'Acciones',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Paginas',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Inicio'
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Entrar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        BACK_BUTTON: 'Atrás',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
        RECOVERY: 'Recuperar',
        SUCCESS: 'Ups! Problemas con el servicio.',
        SERVER_ERROR: 'Ups! Problemas con el servicio.',
        SAVE: 'Guardar',
        SAVE_CHANGE: 'Guardar cambios',
        CANCEL_CHANGE: 'Cancelar cambios',
        CANCEL: 'Cancelar'
      },
      LOGIN: {
        TITLE: 'Iniciar sesión',
        QUESTION: '¿Ya tienes cuenta?',
        QUIESTION_LINK: 'Crea una cuenta',
        BUTTON: 'Entrar',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        BUTTON: 'Recuperar',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña.',
        SUCCESS: 'La contraseña ha sido enviada a su correo.',
        ERROR: 'Problemas con el servidor.',
        NOFOUNT: 'Correo ingresado no tiene un usuario registrado.'
      },
      REGISTER: {
        TITLE: 'Registro de usuario',
        BUTTON: 'Crear cuenta',
        QUESTION: '¿Ya tienes cuenta?',
        QUIESTION_LINK: 'Accede aquí',
        SUCCESS: 'Usuario regitrado exitosamente, se notificara cuando se proceda con su activacion.'
      },
      INPUT: {
        DNI: 'Identificación',
        DNI_DES: 'Cédula ó Pasaporte.',
        DNI_TYPE: 'Tipo de Identificación',
        DNI_TYPE_DES: 'Seleccione su tipo de Identificación',
        CEDULA: 'Cédula',
        CEDULA_DES: 'Seleccione su tipo de Usuario',
        USER_TYPE: 'Tipo de Usuarios',
        USER_TYPE_DES: 'Seleccione tipo de Usuario',
        ADMIN: 'Administrador',
        ASISKEN: 'Asisken',
        BROKER: 'Bróker',
        BROKER_DES: 'Seleccione una Agencia',
        PASSPORT: 'Pasaporte',
        EMAIL: 'Email',
        EMAIL_DES: 'Correo electrónico del vendedor',
        LASTNAME: 'Apellidos',
        LASTNAME_DES: 'Apellidos del Vendedor',
        FIRSTNAME: 'Nombres',
        FIRSTNAME_DES: 'Nombres del Vendedor',
        FULLNAME: 'Fullname',
        ADDRESS: 'Dirección',
        ADDRESS_DES: 'Dirección del Bróker',
        PHONE: 'Teléfono',
        GENDER: 'Genero',
        GENDER_DES: 'Seleccione su genero.',
        BIRTHDAY: 'Fecha nacimiento',
        BIRTHDAY_DES: 'Seleccione su fecha de nacimiento.',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar contraseña',
        USERNAME: 'Usuario',
        KEEP_SESSIONS: 'Mantener la sesión iniciada',
        SAVE: 'Guardar',
        STATUS: 'Estado',
        STATUS_DES: 'Selecciones un estado'
      },
      VALIDATION: {
        DNI_ERROR: 'La identificación ingresada no es correcta.',
        INVALID: '{{name}} es invalido.',
        DNI_TYPE: 'Cédula ó Pasaporte.',
        INVALID_EMAIL: 'Correo invalido.',
        REQUIRED: '{{name}} es requerido.',
        MIN_LENGTH: '{{name}} requiere {{min}} caracteres.',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'Credenciales incorrectas.',
        REQUIRED_FIELD: 'Este campo es requerido',
        MIN_LENGTH_FIELD: 'Cantidad minima de caracteres:',
        MAX_LENGTH_FIELD: 'Cantidad maxima de caracteres:',
        INVALID_FIELD: 'Dato no valido.',
        SPECIAL_CHARACTERS: 'No debe contener caracteres especiales.',
      }
    },
    QUOTATION: {
      GENERAL: {
        NEW_USER: 'Nuevo usuario',
        NEW_QUOTATION: 'Nueva cotizacion',
        NEW_PRODUCT: 'Nuevo Productos',
        BACK: 'Atras',
        CANCEL: 'Cancelar',
        RESET: 'Restablecer',
        SAVE: 'Guardar',
        SAVE_LIKE_DRAFT: 'Guardar como borrador',
        REFRESH: 'Actualizar'
      },
      PRODUCTS:{
        INPUT:''
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
