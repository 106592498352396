import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BrokerModel} from '../_models/broker.model';
import {CatalogueBenefitsModel} from '../_models/catalogue-benefits.model';
import {BackResModel} from '../_models/_in/back-res.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'BrokerAgencia';

@Injectable({
  providedIn: 'root'
})

export class BrokersService {
  constructor(private http: HttpClient) {}

  getAll(){
    const options = {};
    return this.http.get<BrokerModel[]>(URL_API.url + URL_API_GET_QUOTES, options);
  }

  add(benefits: BrokerModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackResModel>(URL_API.url + URL_API_GET_QUOTES, benefits, {headers: httpHeaders});
  }

  update(benefits: BrokerModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackResModel>(URL_API.url + URL_API_GET_QUOTES, benefits, {headers: httpHeaders});
  }

}
