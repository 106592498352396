import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';

import { mergeMap, take } from 'rxjs/operators';
import {ProductsService} from '../../../quotation/_services';
import {QuoteService} from '../quote.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteResolverService implements Resolve<any> {
  constructor (private cs: QuoteService, private router: Router) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const isPool = route.paramMap.get('isPool');
    const IdQ = route.paramMap.get('IdQ');

    return this.cs.getQuote(Number(IdQ), isPool).pipe(
      take(1),
      mergeMap(crisis => {
        console.log(crisis);

        if (crisis) {

          return of(crisis);

        } else {

          this.router.navigate(['/oops']);
          return EMPTY;

        }
      })
    );
  }
}
