<div class="mat-table-wrapper">
    <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000"
        (close)="onAlertClose($event)">
        {{ ms }}
    </kt-alert>
    <form [formGroup]="formGroup">
        <mat-table [dataSource]="dataSource" class="lmat-elevation-z8">


            <!-- Rango1 Column -->
            <ng-container matColumnDef="Edad">
                <mat-header-cell *matHeaderCellDef> Edad </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Edad}}
                    <!--                     <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                        <input matInput type="number" placeholder="Edad" formControlName="Edad">
                        <mat-hint align="start"> 0 - 1 </mat-hint>
                    </mat-form-field>
                    <span *ngIf="!element._isEditMode">{{element.edad}}</span> -->
                </mat-cell>
            </ng-container>

            <!-- Rango2 Column -->
            <ng-container matColumnDef="FemeninoSinMaternidad">
                <mat-header-cell *matHeaderCellDef> Femenino Sin Maternidad </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                        <input matInput type="number" placeholder="FemeninoSinMaternidad"
                            formControlName="FemeninoSinMaternidad">
                        <mat-hint align="start"> 0 - 1 </mat-hint>
                    </mat-form-field>
                    <span *ngIf="!element._isEditMode">{{element.FemeninoSinMaternidad}}</span>
                </mat-cell>
            </ng-container>


            <!-- Rango3 Column -->
            <ng-container matColumnDef="FemeninoConMaternidad">
                <mat-header-cell *matHeaderCellDef> Femenino Con Maternidad </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                        <input matInput type="number" placeholder="FemeninoConMaternidad"
                            formControlName="FemeninoConMaternidad">
                        <mat-hint align="start"> 0 - 1 </mat-hint>
                    </mat-form-field>
                    <span *ngIf="!element._isEditMode">{{element.FemeninoConMaternidad}}</span>
                </mat-cell>
            </ng-container>

            <!-- Rango4 Column -->
            <ng-container matColumnDef="Masculino">
                <mat-header-cell *matHeaderCellDef> Masculino </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-form-field class="mat-form-field-fluid" *ngIf="element._isEditMode">
                        <input matInput type="number" placeholder="Masculino" formControlName="Masculino">
                        <mat-hint align="start"> 0 - 1 </mat-hint>
                    </mat-form-field>
                    <span *ngIf="!element._isEditMode">{{element.Masculino}}</span>
                </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="acctions">
                <mat-header-cell *matHeaderCellDef class="mat-column mat-column-actions">
                    Accion
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="mat-column mat-column-actions">
                    <button *ngIf="!element._isEditMode && editable" (click)="editButtonOnClick(element)"
                        [disabled]="isSwitchedToEditMode" type="button" mat-icon-button color="primary"
                        matTooltip="Edit remark">
                        <mat-icon>create</mat-icon>
                    </button>
                    <button *ngIf="element._isEditMode && !loadingAfterSubmit" (click)="saveUpdated(element)"
                        mat-icon-button color="primary" type="button" matTooltip="Save changes">
                        <mat-icon>done</mat-icon>
                    </button>
                    <mat-spinner *ngIf="element._isEditMode && loadingAfterSubmit" [diameter]="20"></mat-spinner>

                    <button *ngIf="element._isEditMode" (click)="cancelEditButtonOnClick(element)" type="button"
                        mat-icon-button color="warn" matTooltip="Cancel changes">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-cell>

            </ng-container>

            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        </mat-table>
    </form>
    <mat-paginator #paginator [pageSizeOptions]="[26, 50, 75, 101]" showFirstLastButtons></mat-paginator>
</div>