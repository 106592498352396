import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {merge, Observable, Subscription} from 'rxjs';
import {
  ProductCharacteristicStoreUpdated,
  ProductCharacteristicUpdated,
  ProductCharecteristicsDatasource
} from '../../../../../../../core/quotation';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {ProductCharecteristicsModel} from '../../../../../../../core/quotation/_models/product-charecteristics.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../../core/reducers';
import {LayoutUtilsService, MessageType, QueryParamsModel, TypesUtilsService} from '../../../../../../../core/_base/crud';
import {tap} from 'rxjs/operators';
import {ProductCharacteristicsPageRequested} from '../../../../../../../core/quotation/_actions/product-charecteristics.actions';
import {Update} from '@ngrx/entity';

@Component({
  selector: 'kt-charecteristics',
  templateUrl: './charecteristics.component.html',
  styles: [
  ]
})
export class CharecteristicsComponent implements OnInit {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  dataSource: ProductCharecteristicsDatasource;
  displayedColumns = ['IdPlantillaC', 'Descripcion', 'valor', 'AplicaMaternidad', 'AplicaNSolo', 'Estado', 'actions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  selection = new SelectionModel<ProductCharecteristicsModel>(true, []);
  productCharecteristicsResult: ProductCharecteristicsModel[] = [];

  isSwitchedToEditMode = false;
  loadingAfterSubmit = false;
  formGroup: FormGroup;
  remarkForEdit: ProductCharecteristicsModel;
  remarkForAdd: ProductCharecteristicsModel;

  private componentSubscriptions: Subscription;

  Estados = [
    {id: 1, name: 'Activo'},
    {id: 0, name: 'inactivo'}
  ];

  constructor(private store: Store<AppState>,
              private fb: FormBuilder,
              public dialog: MatDialog,
              public typesUtilsService: TypesUtilsService,
              private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {

    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.loadRemarksList();
        })
      )
      .subscribe();

    this.dataSource = new ProductCharecteristicsDatasource(this.store);
    this.dataSource.entitySubject.subscribe(res => this.productCharecteristicsResult = res);
    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

        this.productId = res;
        this.loadRemarksList();
        this.createFormGroup();
    });

   }

  loadRemarksList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(new ProductCharacteristicsPageRequested({
      page: queryParams,
      productId: this.productId
    }));
  }

  createFormGroup(_item = null) {
    // 'edit' prefix - for item editing
    this.formGroup = this.fb.group({
      Valor: ['', Validators.compose([Validators.required])],
      AplicaMaternidad: [false, Validators.compose([Validators.required])],
      AplicaNSolo: [false , Validators.compose([Validators.required])],
      Estado: [false , Validators.compose([Validators.required])]
    });
    this.clearEditForm();
  }

  clearEditForm() {
    const controls = this.formGroup.controls;
    controls.Valor.setValue('');
    controls.AplicaMaternidad.setValue(false);
    controls.AplicaNSolo.setValue(false);
    controls.Estado.setValue(false);

    this.remarkForEdit = new ProductCharecteristicsModel();
    this.remarkForEdit.clear(this.productId);
    this.remarkForEdit._isEditMode = false;
  }

  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    filter.Descripcion = searchText;
    filter.Seccion = searchText;
    return filter;
  }

  editButtonOnClick(_item: ProductCharecteristicsModel) {
    const controls = this.formGroup.controls;
    controls.Valor.setValue(_item.Valor);
    controls.AplicaMaternidad.setValue(_item.AplicaMaternidad);
    controls.AplicaNSolo.setValue(_item.AplicaNSolo);
    controls.Estado.setValue(_item.Estado);
    const updateProductCharecteristics: Update<ProductCharecteristicsModel> = {
      id: _item.IdPlantillaC,
      changes: {
        _isEditMode: true
      }
    };
    this.store.dispatch(new ProductCharacteristicStoreUpdated({ productCharacteristic: updateProductCharecteristics }));
    this.isSwitchedToEditMode = true;
  }

  cancelEditButtonOnClick(_item: ProductCharecteristicsModel) {
    const updateProductCharecteristics: Update<ProductCharecteristicsModel> = {
      id: _item.IdPlantillaC,
      changes: {
        _isEditMode: false
      }
    };
    this.store.dispatch(new ProductCharacteristicStoreUpdated({ productCharacteristic: updateProductCharecteristics }));
    this.isSwitchedToEditMode = false;
  }

  checkEditForm() {
    const controls = this.formGroup.controls;
    if (controls.Valor.invalid || controls.AplicaMaternidad.invalid || controls.AplicaNSolo.invalid || controls.Estado.invalid) {
      controls.Valor.markAsTouched();
      return false;
    }

    return true;
  }

  saveUpdated(_item: ProductCharecteristicsModel) {
    if (!this.checkEditForm()) {
      return;
    }

    this.loadingAfterSubmit = true;
    const controls = this.formGroup.controls;
    this.loadingAfterSubmit = false;
    const objectForUpdate = new ProductCharecteristicsModel();
    objectForUpdate.IdCaracteristica = _item.IdCaracteristica;
    objectForUpdate.IdPlantillaC = _item.IdPlantillaC;
    objectForUpdate.IdPlan = this.productId;
    objectForUpdate.Descripcion = _item.Descripcion;
    objectForUpdate.Valor = controls.Valor.value;
    objectForUpdate.AplicaMaternidad = controls.AplicaMaternidad.value;
    objectForUpdate.AplicaNSolo = controls.AplicaNSolo.value;
    objectForUpdate.Estado = controls.Estado.value;
    objectForUpdate._isEditMode = false;


    const updateProductCharecteristics: Update<ProductCharecteristicsModel> = {
      id: _item.IdPlantillaC,
      changes: objectForUpdate
    };

    this.store.dispatch(new ProductCharacteristicUpdated({
      partialProductCharacteristic: updateProductCharecteristics,
      productCharacteristic: objectForUpdate
    }));
    const saveMessage = `Caracteristica actualizada`;
    this.isSwitchedToEditMode = false;
    this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, false);
    // this.ngOnInit();
  }

}
