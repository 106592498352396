<kt-portlet>
    <kt-portlet-body>

        <mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="IdCaracteristica">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Cod</mat-header-cell>
                <mat-cell *matCellDef="let b">{{ b.IdCaracteristica }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Caracteristica">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</mat-header-cell>
                <mat-cell *matCellDef="let b">{{b.Caracteristica}}</mat-cell>
            </ng-container>

            <ng-container  matColumnDef="Estado">
                <mat-header-cell  class="d-flex justify-content-center" *matHeaderCellDef mat-sort-header >Estado</mat-header-cell>
                <mat-cell  class="d-flex justify-content-center" *matCellDef="let b">{{b.Estado ===1 ? 'Activo':'Inactivo'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                <mat-cell *matCellDef="let b">
                    <button (click)="edit(b)" mat-icon-button color="primary" matTooltip="Editar">
                        <mat-icon>create</mat-icon>
                    </button>

                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]"></mat-paginator>


        <!-- start: BOTTOM -->
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>