<kt-portlet>
  <kt-portlet-body>
    <div class="form-filtration">
      <div class="row align-items-center">

        <div class="col-md-2 ml-4 kt-margin-bottom-10-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Buscar Bróker" #input [(value)]="filterGeneral" (keyup)="applyFilter($event)"/>
            <mat-hint align="start">
              <strong>Busqueda General</strong>
            </mat-hint>
          </mat-form-field>
        </div>

      </div>
    </div>

    <mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="Ruc">
        <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
        <mat-header-cell *matHeaderCellDef mat-sort-header>Ruc</mat-header-cell>
        <mat-cell *matCellDef="let b">{{ b.Ruc }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Broker">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Broker</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.Broker}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Telefono">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="email-cell">Telefono</mat-header-cell>
        <mat-cell *matCellDef="let b" class="email-cell">
          <a  class="font-weight-bold">{{b.Telefono}}</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Descripcion">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</mat-header-cell>
        <mat-cell *matCellDef="let b">
						{{  b.Descripcion }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Direccion">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Direccion</mat-header-cell>
        <mat-cell *matCellDef="let b">
						{{  b.Direccion }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Mail">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Mail</mat-header-cell>
        <mat-cell *matCellDef="let b">
						{{  b.Mail }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Estado">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Estado</mat-header-cell>
        <mat-cell *matCellDef="let b">
          <span class="label label-lg label-light-{{ (b.Estado)?'success':'danger' }} label-inline">{{  (b.Estado)?'Activo':'Inactivo' }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
        <mat-cell *matCellDef="let b">
          <button (click)="edit(b)" mat-icon-button color="primary" matTooltip="Editar">
            <mat-icon>create</mat-icon>
          </button>&nbsp;

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]"></mat-paginator>


    <!-- start: BOTTOM -->
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->

</kt-portlet>
