<!-- start::FILTERS & GROUP ACTIONS -->
<div class="form mb-3">
  <!-- start::FILTERS -->
  <div class="form-filtration">
    <div class="row align-items-center">
      <div class="col-md-2 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
          <input matInput placeholder="Search product remark" #searchInput placeholder="Buscar">
          <mat-hint align="start">Buscar Caracteristica</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-md-8 kt-margin-bottom-10-mobile"></div>
    </div>
  </div>
  <!-- end::FILTERS -->

  <!-- start::GROUP ACTIONS -->
  <!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
  <!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
  <!-- end::GROUP ACTIONS -->

</div>
<!-- end::FILTERS & GROUP ACTIONS -->

<!-- MATERIAL TABLE | Binded to datasources -->
<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
<div class="mat-table-wrapper">
  <form [formGroup]="formGroup">
    <mat-table class="lmat-elevation-z8" [dataSource]="dataSource" matSort matSortActive="text" matSortDirection="asc" matSortDisableClear>

      <ng-container matColumnDef="SeccionOrden">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Orden Sección</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column">
          <span>{{Caracteristica.SeccionOrden}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Seccion">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Sección</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column">
          <span>{{Caracteristica.Seccion}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CaracteristicaOrden">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Orden Caracteristica</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column">
          <span>{{Caracteristica.CaracteristicaOrden}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="IdPlantillaC">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-id">Codigo</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-id">
          <span>{{Caracteristica.IdPlantillaC}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Descripcion">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column">Caracteristica
        </mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column">
          <span>{{Caracteristica.Descripcion}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="valor">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-select">Valor</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-select">
          <mat-form-field *ngIf="Caracteristica._isEditMode" class="mat-form-field-fluid">
            <input matInput placeholder="Ingrese el valor del beneficio" formControlName="Valor">
            <mat-hint align="start">Porfavor ingrese un
              <strong>valor</strong>
            </mat-hint>
          </mat-form-field>
          <span *ngIf="!Caracteristica._isEditMode">{{Caracteristica.Valor}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AplicaMaternidad">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-checkbox">Maternidad</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-checkbox">
          <span *ngIf="!Caracteristica._isEditMode" class="label label-lg label-light-{{ (Caracteristica.AplicaMaternidad)?'success':'dark' }} label-inline">{{ (Caracteristica.AplicaMaternidad)?'Si':'No' }}</span>
          <mat-checkbox *ngIf="Caracteristica._isEditMode" class="example-margin" formControlName="AplicaMaternidad"></mat-checkbox>
        </mat-cell>
      </ng-container>



      <ng-container matColumnDef="AplicaNSolo">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-checkbox">Niño Solo</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-checkbox">
          <span *ngIf="!Caracteristica._isEditMode" class="label label-lg label-light-{{ (Caracteristica.AplicaNSolo)?'success':'dark' }} label-inline">{{ (Caracteristica.AplicaNSolo)?'Si':'No' }}</span>
          <mat-checkbox *ngIf="Caracteristica._isEditMode" class="example-margin" formControlName="AplicaNSolo"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Estado">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column mat-column-checkbox mr-5">Estado</mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-checkbox mr-5">
          <span *ngIf="!Caracteristica._isEditMode" class="label mr-5 label-lg label-light-{{ (Caracteristica.Estado == 1)?'success':'dark' }} label-inline">{{ (Caracteristica.Estado)?'Activo':'Inactivo' }}</span>
          <mat-form-field class="mat-form-field-fluid" *ngIf="Caracteristica._isEditMode">
            <mat-select placeholder="Estado" formControlName="Estado" [(value)]="Caracteristica.Estado">
              <mat-option *ngFor="let t of Estados"  [value]="t.id">
                {{ t.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="mat-column mat-column-actions">
          Accion
        </mat-header-cell>
        <mat-cell *matCellDef="let Caracteristica" class="mat-column mat-column-actions">
          <button *ngIf="!Caracteristica._isEditMode && editable" (click)="editButtonOnClick(Caracteristica)" [disabled]="isSwitchedToEditMode" type="button" mat-icon-button
                  color="primary"  matTooltip="Edit remark">
            <mat-icon>create</mat-icon>
          </button>
          <button *ngIf="Caracteristica._isEditMode && !loadingAfterSubmit" (click)="saveUpdated(Caracteristica)" mat-icon-button
                  color="primary" type="button"
                  matTooltip="Save changes">
            <mat-icon>done</mat-icon>
          </button>
          <mat-spinner *ngIf="Caracteristica._isEditMode && loadingAfterSubmit" [diameter]="20"></mat-spinner>

          <button *ngIf="Caracteristica._isEditMode" (click)="cancelEditButtonOnClick(Caracteristica)" type="button" mat-icon-button color="warn"
                   matTooltip="Cancel changes">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <!-- end: EDIT row -->

      <mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'mat-row-editing' : row._isEditMode}">
      </mat-row>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    </mat-table>
  </form>

  <div class="mat-table-message" *ngIf="!dataSource.hasItems">No hay beneficios</div>
  <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Por favor espere....</div>

</div>

<!-- start: BOTTOM -->
<div class="mat-table-bottom">
  <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
  <mat-paginator [pageSize]="3" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async"
                 [showFirstLastButtons]="true"></mat-paginator>
</div>
<!-- end: BOTTOM -->
