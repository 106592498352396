import { BaseModel } from '../../_base/crud';

export class CatalogueSectionsModel extends BaseModel {
  IdSeccion: number;
  Seccion: string;
  Estado: number;
  EstadoDesc: string;


  clear() {
    this.IdSeccion = 0;
    this.Seccion = '';
    this.Estado = 0;
    this.EstadoDesc = "";
  }
}
