<div class="container">
  <div class="card card-custom">
    <!--begin::Form-->
    <form class="form" [formGroup]="pass">
      <div class="card-body">
        <!--begin::Alert-->
        <kt-alert *ngIf="hasFormErrors" type="{{type}}" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
          {{ ms }}
        </kt-alert>
        <!--end::Alert-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Contraseña actual</label>
          <div class="col-lg-9 col-xl-6">
            <input formControlName="oldpassword" type="password" class="form-control form-control-lg form-control-solid mb-2" value="" placeholder="Contraseña actual">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('oldpassword', 'required')">Indique su contraseña actual</div>
            <a routerLink="/entrar/recuperar-contrasena" *ngIf="Olvido" class="text-sm font-weight-bold">¿Olvido su contraseña?</a>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Nueva contraseña</label>
          <div class="col-lg-9 col-xl-6">
            <input formControlName="password" type="password" class="form-control form-control-lg form-control-solid" placeholder="Nueva contraseña">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'required')">Indique su nueva contraseña</div>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'minLength')">Debe contener un minumos de 6 caracteres</div>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'maxLength')">No puede incluir as de 15 caracteres</div>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'pattern')">Asegurese de contener caracteres validos</div>
            <div class="fv-plugins-message-container" *ngIf="pass.get('confirmPassword').errors && pass.get('confirmPassword').errors.ConfirmPassword">Las contraseñas no coinciden</div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert">Confirmacion de nueva contraseña</label>
          <div class="col-lg-9 col-xl-6">
            <input formControlName="confirmPassword" type="password" class="form-control form-control-lg form-control-solid" placeholder="Nueva contraseña">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'required')">Repita su nueva contraseña</div>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
    <!--e<a (click)="change()" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">Guardar</a>-->
  </div>
</div>
