import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CharacteristicModel } from 'src/app/core/quotation/_models/catalogue-charecteristics.model';
import { CatalogueCharacteristicsService } from 'src/app/core/quotation/_services/catalogue-charecteristics.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { SubheaderService } from 'src/app/core/_base/layout/services/subheader.service';
import { CharecteristicCatalogueEditComponent } from '../charecteristic-catalogue-edit/charecteristic-catalogue-edit.component';

@Component({
  selector: 'kt-charecteristic-catalogue-list',
  templateUrl: './charecteristic-catalogue-list.component.html',
  styles: [
  ]
})
export class CharecteristicCatalogueListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<CharacteristicModel>;
  displayedColumns = ['IdCaracteristica', 'Caracteristica', 'Estado', 'actions'];
  private subscriptions: Subscription[] = [];
  constructor(public dialog: MatDialog,
    private cbs: CatalogueCharacteristicsService,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Lista Características', 'Estas son sus características');

    this.route.data
      .subscribe((data) => {

        this.dataSource = new MatTableDataSource(data.benefits);
      });

    this.subscriptions.push(this.subheaderService.setNewCatalogue$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if (bc) {
          this.add();
          this.subheaderService.setNewCatalogue(false);
        }
      });
    }));
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }
  add() {
    const catalogue = new CharacteristicModel();
    catalogue.clear(); // Set all defaults fields
    this.edit(catalogue);
  }
  edit(benefits: CharacteristicModel) {
    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += benefits.IdCaracteristica > 0 ? 'Agregar característica' : 'Nuevo caraterística';
    const _saveMessage = saveMessageTranslateParam;
    const _messageType = benefits.IdCaracteristica > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(CharecteristicCatalogueEditComponent, { data: { benefits } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.cbs.getAll().subscribe(next => {
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      });
    });
  }
}
