import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {RateDependentModel} from '../../_models/rate-dependent.model';
import {Observable} from 'rxjs';
import { HttpUtilsService } from 'src/app/core/_base/crud';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetTarifaDependientePlan?idplan=';

@Injectable({
  providedIn: 'root'
})

export class PlansDependentsRateService {
  constructor(private http: HttpClient,
    private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<RateDependentModel[]>(URL_API.url + URL_API_GET_QUOTES + id, options);
  }

  update(planStateModel: RateDependentModel): Observable<any> {
    const RateCategory: RateDependentModel[] = [];
    RateCategory.push(planStateModel);
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put(URL_API.url + 'ActTarifaDependientes', RateCategory, {headers: httpHeaders});
  }

  updateRangDependents(Rates: any[]): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaRangoDSolo', Rates, {
      headers: httpHeaders
    });
  }
 
  getTarifaRango(id: number){
    const options = {};
    return this.http.get<any[]>(URL_API.url + 'GetTarRangoDSoloGenero?idplan=' + id, options);
  }
}
