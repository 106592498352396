export class UsersTable {
  public static users: any = [
    {
      id: 1,
      username: 'admin',
      password: '123',
      dni: 'jorge.uzca@iroute.com.ec',
      email: '0922749692',
      accessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
      refreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      roles: [1], // Administrator
      pic: './assets/media/users/300_25.jpg',
      fullname: 'Jorge Uzca',
      occupation: 'Coder',
      companyName: 'Asisken',
      phone: '0996179219',
      address: {
        addressLine: 'L-12-20 Vertex, Cybersquare',
        city: 'San Francisco',
        state: 'sauces7',
        postCode: '45000'
      },
      socialNetworks: {
        linkedIn: 'https://linkedin.com/admin',
        facebook: 'https://facebook.com/admin',
        twitter: 'https://twitter.com/admin',
        instagram: 'https://instagram.com/admin'
      }
    },
    {
      id: 2,
      username: 'user',
      password: '123',
      dni: 'johanna.guano@iroute.com.ec',
      email: '0922749632',
      accessToken: 'access-token-6829bba69dd3421d8762-991e9e806dbf',
      refreshToken: 'access-token-f8e4c61a318e4d618b6c199ef96b9e55',
      roles: [2], // Manager
      pic: './assets/media/users/100_2.jpg',
      fullname: 'Johanna Guano',
      occupation: 'Administrador',
      companyName: 'Asisken',
      phone: '0996179325',
      address: {
        addressLine: '3487  Ingram Road',
        city: 'Greensboro',
        state: 'suburbio',
        postCode: '27409'
      },
      socialNetworks: {
        linkedIn: 'https://linkedin.com/user',
        facebook: 'https://facebook.com/user',
        twitter: 'https://twitter.com/user',
        instagram: 'https://instagram.com/user'
      }
    }
  ];

  public static tokens: any = [
    {
      id: 1,
      accessToken: 'access-token-' + Math.random(),
      refreshToken: 'access-token-' + Math.random()
    }
  ];
}
