import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';

@Component({
  selector: 'kt-table-range',
  templateUrl: './table-range.component.html',
  styles: [
  ]
})
export class TableRangeComponent implements OnInit {

  @Input('productId') productId$: Observable<number>;
  @Input() editable: boolean;
  @Input() hasFormErrors = false;
  @Input() type = 'warn';
  @Input() ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  @Input() save: (data: any[]) => Observable<any[]> = () => null;
  @Input() load: (id: number) => Observable<any[]> = () => null;

  @Input() onlyKids: boolean = false;

  productId: number;

  dataSource: MatTableDataSource<any[]> = new MatTableDataSource();
  isSwitchedToEditMode = false;
  loadingAfterSubmit = false;

  formGroup: FormGroup;
  rangos: any[];
  formBackup: any;
  columnRange: string [] = [];
  displayedColumns: string [] = [];

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {
    this.productId$.subscribe(res => {
      if (!res) return;

      this.productId = res;
      this.loadRanges(res);
    });
  }

  private loadRanges(id: number){
    this.load(id).subscribe( next =>{
      this.rangos = next;
      this.dataSource = new MatTableDataSource(next);
      this.initColumns(next);
    });
  }

  private initColumns(rangos: any[]){
    this.columnRange = [];
    this.displayedColumns = [];

    const data_ranges = rangos[0]; //Tomar primer elemento como estructura base
    
    this.displayedColumns.push('IdTarifa');

    if(data_ranges.hasOwnProperty('Categoria') && data_ranges.Categoria != null)
      this.displayedColumns.push('Categoria');

    if(data_ranges.hasOwnProperty('Descripcion') && data_ranges.Descripcion != null)
      this.displayedColumns.push('Descripcion');

    this.getDataRanges(data_ranges).map(({Rango}) => {
      this.columnRange.push(Rango);
      this.displayedColumns.push(Rango);
    });

    this.displayedColumns.push('acctions');
  }

  private getDataRanges(data: any){
    let list_ranges = data.Rangos;

    if(this.onlyKids){
      list_ranges = data.RangosSolo;
    }

    return list_ranges
  }

  getDataProperties(obj: any, path: string){
    if(path == 'IdTarifa') {
      return obj.IdTarifa;
    } else if(path == 'Categoria') {
      return obj.Categoria;
    } else if(path == 'Descripcion') {
      return obj.Descripcion;
    } else {
      let value = this.getDataRanges(obj).filter(data => data.Rango === path);
      return value.length == 1 ? value[0].Valor : 0; 
    }
  }

  private buildFormDynamic(rangos: any){
    let form = {};
  
    const data_ranges = rangos;
    
    form['IdTarifa'] = [data_ranges.IdTarifa];
    form['IdCategoria'] = [data_ranges.IdCategoria];
    form['Genero'] = [data_ranges.Genero];

    if(this.onlyKids){
      form['TipoDep'] = [data_ranges.TipoDep];
    }
    

    this.getDataRanges(data_ranges).map((data: any) => {
        form[data.Rango] = [data.Valor];
    });

    return this.fb.group(form);
  }

  private parseDynamicForm(): any[]{
    const formValue = this.formGroup.value;
    let list_data: any[] = [];

    console.log(formValue)

    this.displayedColumns.map((data) => {
      this.rangos.map(list => {
        if(((list.Categoria || !list.Genero) && list.IdCategoria == formValue.IdCategoria && !this.onlyKids) 
        || (list.Descripcion && list.Genero == formValue.Genero && !this.onlyKids)
        || (list.hasOwnProperty('TipoDep') && list.TipoDep == formValue.TipoDep)
        ){
          this.getDataRanges(list).map(({IdTarifa, IdRango, Rango}) => {
            let parseForm = {};
            if(data.match(Rango)){
              parseForm['IdTarifa'] = IdTarifa;
              parseForm['IdPlan'] = this.productId;
              parseForm['IdRango'] = IdRango;
              parseForm['Valor'] = formValue[data];
              if(!this.onlyKids){
                parseForm['Genero'] = list.Genero || "";
                parseForm['IdCategoria'] = list.IdCategoria || 0;
              }else{
                parseForm['TipoDep'] = list.TipoDep || "H";
                parseForm['Descripcion'] = list.Descripcion || "";
              }
              
              list_data.push(parseForm);
            }
          });
        }
      })
    });

    return list_data;
  }

  editButtonOnClick(_item: any) {
    console.log('item active',_item);
    this.formGroup = this.buildFormDynamic(_item);
    this.formBackup = this.formGroup.value;
    _item._isEditMode = true;
    this.isSwitchedToEditMode = true;
  }

  cancelEditButtonOnClick(_item: any) {
    _item._isEditMode = false;
    this.isSwitchedToEditMode = false;

    this.formGroup.reset(this.formBackup);
  }

  saveUpdated(_item: any){
    console.log(this.formGroup.value);
  

    this.loadingAfterSubmit = true;

    this.save(this.parseDynamicForm()).subscribe( next =>{
        this.loadRanges(this.productId);
        _item._isEditMode = false;
        this.loadingAfterSubmit = false;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification('Actualizacion Tarifa con exito', MessageType.Update, 10000, true, false);
        this.cdr.detectChanges(); // Detectar el cambio
      }, error => {
      this.loadingAfterSubmit = false;
      this.isSwitchedToEditMode = false;
      if(error.error.Mensaje){
        this.mensaje(error.error.Mensaje);
        this.cdr.detectChanges();
      } else {
        this.mensaje('Rayos! Algo salio mal en el servidor por favor intente de nuevo.');
        this.cdr.detectChanges();
      }
    });
  };

  mensaje(ms: string, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }
}
