import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';

import { mergeMap, take } from 'rxjs/operators';
import {ProductsService} from '../products.service';
import {environment} from '../../../../../environments/environment';
import {QuoteService} from '../quote.service';

@Injectable({
  providedIn: 'root'
})
export class ComparePdfResolverService implements Resolve<any> {
  constructor (private cs: QuoteService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const isPool = route.paramMap.get('isPool');
    const IdContizacion = route.paramMap.get('IdContizacion');
    const IdPlan1 = route.paramMap.get('IdPlan1');
    const IdPlan2 = route.paramMap.get('IdPlan2');

    const temp: any = {};

    temp.IdCotizacion = IdContizacion;
    temp.IdPlan = [];
    if(Number(IdPlan1)>0){
      temp.IdPlan.push(IdPlan1);
    }

    if(Number(IdPlan2)>0){
      temp.IdPlan.push(IdPlan2);
    }

    if(isPool === 'Pool'){
      return this.cs.comparePool(temp).pipe(
        take(1),
        mergeMap(crisis => {

          if (crisis) {

            return of(crisis);

          } else {

            this.router.navigate(['/oops']);
            return EMPTY;

          }
        })
      );
    }else{
      return this.cs.compare(temp).pipe(
        take(1),
        mergeMap(crisis => {

          if (crisis) {

            return of(crisis);

          } else {

            this.router.navigate(['/oops']);
            return EMPTY;

          }
        })
      );
    }


  }
}
