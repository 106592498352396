<kt-portlet>
  <kt-portlet-body>


      <div class="row justify-content-center px-8 my-lg-0 px-lg-10">
        <div class="col-xl-12 col-xxl-7">

            <!--begin: Form-->
            <div *ngIf="individual" class="pb-5" >
              <form class="form" [formGroup]="quoteForm" id="kt_form">
                <div class="mb-10 font-weight-bold text-dark">Datos Principales</div>
                <div class="form-group">
                  <label>Cliente</label>
                  <input type="text" class="form-control" formControlName="clientName" name="clientName" placeholder="Nombre del cliente">
                  <span class="form-text text-muted" *ngIf="!isControlHasError('clientName', 'required')">Ingrese el nombre completo del cliente.</span>
                  <div class="fv-plugins-message-container" *ngIf="isControlHasError('clientName', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
                </div>
                <div *ngIf="individual && ConBeneficio">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Fecha de nacimiento</label>
                        <input type="date" [max]="today" formControlName="clientBirth" class="form-control" name="clientBirth" (change)="setAgeTitular($event)">
                        <span class="form-text text-muted" *ngIf="!isControlHasError('clientBirth', 'required')">Seleccione la fecha de nacimiento del cliente.</span>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('clientBirth', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Edad</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <button class="btn btn-danger" type="button" (click)="minuAgeT()"> <i class="flaticon2-line"></i></button>
                          </div>
                          <input type="number" (change)="setDateBirth()" formControlName="ageClient" class="form-control"/>
                          <div class="input-group-append">
                            <button class="btn btn-success" type="button" (click)="plusAgeT()"> <i class="flaticon2-add-1"></i></button>
                          </div>
                        </div>
                        <span class="form-text text-muted" *ngIf="!isControlHasError('ageClient', 'min')">Seleccione edad.</span>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('ageClient', 'min')">Debe tener al menos 18 años para calificar como titular.</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Genero</label><br>
                    <mat-radio-group formControlName="gender" (change)="setG()">
                      <mat-radio-button value="M">Másculino</mat-radio-button>
                      <mat-radio-button value="F">Femenino</mat-radio-button>
                    </mat-radio-group>
                    <span class="fv-plugins-message-container" *ngIf="isControlHasError('gender', 'required')">Seleccione genero del cliente.</span>
                  </div>
                  <div class="mb-10 font-weight-bold text-dark">Maternidad</div>
                  <div class="form-group">
                    <label class="checkbox checkbox-lg">
                      <input type="checkbox" checked="checked" formControlName="maternity" name="matrnity"/>
                      <span class="mr-3"></span>
                      Incluye Maternidad
                    </label>
                  </div>
                  <div class="mb-10 font-weight-bold text-dark">Detalle del cónyuge</div>
                  <a mat-raised-button color="primary" class="mr-2" (click)="resetSponse()"><i class="flaticon-circle text-light mr-2"></i>Eliminar datos del cónyugue</a>
                  <div class="form-group">
                    <label>Genero</label><br>
                    <mat-radio-group formControlName="sponseGender">
                      <mat-radio-button value="M">Másculino</mat-radio-button>
                      <mat-radio-button value="F">Femenino</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Fecha de nacimiento</label>
                        <input type="date" [max]="today" class="form-control" formControlName="sponseBirth" name="clientBirthSpouse" (change)="setAgeSponse($event)">
                        <span class="form-text text-muted">Seleccione su fecha de nacimiento.</span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Edad</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <button class="btn btn-danger" type="button" (click)="minuAgeS()"> <i class="flaticon2-line"></i></button>
                          </div>
                          <input type="number" min="18" formControlName="ageSponse" (change)="setDateBirthS()" class="form-control"/>
                          <div class="input-group-append">
                            <button class="btn btn-success" type="button" (click)="plusAgeS()"> <i class="flaticon2-add-1"></i></button>
                          </div>
                        </div>
                        <span class="form-text text-muted" *ngIf="!isControlHasError('ageSponse', 'min')">Seleccione edad.</span>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError('ageSponse', 'min')">Debe tener al menos 18 años.</div>
                      </div>
                    </div>
                  </div>
                </div>

              </form>
              <div class="mb-10 font-weight-bold text-dark">Detalle de Dependientes</div>
              <div class="form-group">
                <a mat-raised-button color="primary" class="mr-2" (click)="addDependient()"><i class="fas fa-user-plus text-light mr-2"></i>Agregar Dependiente</a>
                <form [formGroup]="quoteDependentForm">
                <div class="row" formArrayName="ages">
                    <div class="col-xl-3 mt-4" *ngFor="let age of ages.controls; index as i">
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <button class="btn btn-danger" type="button" (click)="deleteDependient(i)"> <i class="flaticon2-delete"></i></button>
                          </div>
                          <input type="number" [formControlName]="i" class="form-control">
                        </div>
                        <div class="fv-plugins-message-container" *ngIf="isControlHasError(i, 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
                      </div>
                    </div>
                </div>
                </form>
              </div>
            </div>
            <div *ngIf="!individual" class="pb-5">
              <div class="mb-10 font-weight-bold text-dark">Datos Principales</div>
              <form [formGroup]="quotePoolForm">
                <div class="form-group">
                  <label>Cliente</label>
                  <input type="text" class="form-control" name="clientPoolName" formControlName="clientPoolName" placeholder="Nombre del cliente">
                  <span class="form-text text-muted" *ngIf="!isControlPoolHasError('clientPoolName', 'required')">Ingrese el nombre del completo.</span>
                  <div class="fv-plugins-message-container" *ngIf="isControlPoolHasError('clientPoolName', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
                </div>
                <div class="form-group">
                  <label>Actividad Economica</label>
                  <input type="text" class="form-control" name="actEconomy" formControlName="actEconomy" placeholder="Actividad Economica">
                  <span class="form-text text-muted" *ngIf="!isControlPoolHasError('actEconomy', 'required')">Ingrese actividad economica.</span>
                  <div class="fv-plugins-message-container" *ngIf="isControlPoolHasError('actEconomy', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>

                </div>
                <div class="mb-10 font-weight-bold text-dark">Cantidad de titulares por grupo</div>
                <div class="form-group">
                  <label>Titulares Solos</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-danger" type="button" (click)="minusAlone()"> <i class="flaticon2-line"></i></button>
                    </div>
                    <input type="number" min="0" formControlName="alones" class="form-control"/>
                    <div class="input-group-append">
                      <button class="btn btn-success" type="button" (click)="plusAlone()"> <i class="flaticon2-add-1"></i></button>
                    </div>
                  </div>
                  <span class="form-text text-muted">Indique la cantidad de titulares solos.</span>
                </div>
                <div class="form-group">
                  <label>Titulares mas uno</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-danger" type="button" (click)="minusPluOne()"> <i class="flaticon2-line"></i></button>
                    </div>
                    <input type="number" min="0" formControlName="plusOne" class="form-control"/>
                    <div class="input-group-append">
                      <button class="btn btn-success" type="button" (click)="plusPlusOne()"> <i class="flaticon2-add-1"></i></button>
                    </div>
                  </div>
                  <span class="form-text text-muted">Indique la cantidad de titulares más uno.</span>
                </div>
                <div class="form-group">
                  <label>Titulares más familia</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="btn btn-danger" type="button" (click)="minusWhitFamily()"> <i class="flaticon2-line"></i></button>
                    </div>
                    <input type="number" min="0" formControlName="whitFamily" class="form-control"/>
                    <div class="input-group-append">
                      <button class="btn btn-success" type="button" (click)="plusWhitFamily()"> <i class="flaticon2-add-1"></i></button>
                    </div>
                  </div>
                  <span class="form-text text-muted">Indique la cantidad de titulares más familia.</span>
                </div>
              </form>
            </div>
            <div *ngIf="individual && ConBeneficio">
              <div class="mb-10 font-weight-bold text-dark">Beneficios adicionales</div>
              <div class="mb-10 font-weight-bold text-dark" *ngIf="loadingToBenefist">Cargando beneficios adicionales</div>
              <form>
                <div>
                  <div class="mb-6" *ngFor="let b of benefits; index as i">
                    <!--begin::Content-->
                    <div class="d-flex align-items-center flex-grow-1">
                      <!--begin::Checkbox-->
                      <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                        <input type="checkbox" (change)="setCheckboxBenefists(i)" value="{{i}}"/>
                        <span></span>
                      </label>
                      <!--end::Checkbox-->
                      <!--begin::Section-->
                      <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                        <!--begin::Info-->
                        <div class="d-flex flex-column align-items-cente py-2 w-75">
                          <!--begin::Title-->
                          <a class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{ b.DescBeneficio }}</a>
                          <!--end::Title-->
                          <!--begin::Data-->
                          <!--end::Data-->
                        </div>
                        <!--end::Info-->
                        <!--begin::Label-->
                        <span class="label label-lg label-light-primary label-inline font-weight-bold py-4">{{ b.Descripcion }}</span>
                        <!--end::Label-->
                      </div>
                      <!--end::Section-->
                    </div>
                    <!--end::Content-->
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="ValorAnual > 0" class="pb-5">
              <h4 class="mb-10 font-weight-bold text-dark">Tarifa</h4>
              <div class="mb-0">
                <div class="row justify-content-center text-center my-0 my-md-2">
                  <!-- begin: Pricing-->
                  <div class="col-md-3 col-xxl-3 bg-white rounded-left shadow-sm">
                    <div>
                      <h4 class="mt-2 mb-2">Mensual</h4>
                      <span class="px-7 py-3 font-size-h1 font-weight-bold d-inline-flex flex-center bg-primary-o-10 rounded-lg mb-2">{{ ValorMensual | currency}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-xxl-3 bg-white shadow-sm">
                    <div>
                      <h4 class="mt-2 mb-2">Trimestral</h4>
                      <span class="px-7 py-3 font-size-h1 font-weight-bold d-inline-flex flex-center bg-primary-o-10 rounded-lg mb-2">{{ValorTrimestral | currency}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-xxl-3 bg-white shadow-sm">
                    <div>
                      <h4 class="mt-2 mb-2">Semestral</h4>
                      <span class="px-7 py-3 font-size-h1 font-weight-bold d-inline-flex flex-center bg-primary-o-10 rounded-lg mb-2">{{ValorSemestral | currency}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-xxl-3 bg-white shadow-sm">
                    <div>
                      <h4 class="mt-2 mb-2">Anual</h4>
                      <span class="px-7 py-3 font-size-h1 font-weight-bold d-inline-flex flex-center bg-primary-o-10 rounded-lg mb-2">{{ ValorAnual | currency}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-xxl-3 bg-white rounded-right shadow-sm">
                    <div>
                      <h4 class="mt-2 mb-2">Contado y Diferido</h4>
                      <span class="px-7 py-3 font-size-h1 font-weight-bold d-inline-flex flex-center bg-primary-o-10 rounded-lg mb-2">{{ ValorContado | currency}}</span>
                    </div>
                  </div>
                  <!-- end: Pricing-->
                </div>
              </div>
            </div>
            <!--end: Form Wizard Step 3-->

            <!--begin: Form Actions -->
            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2" *ngIf="ValorAnual > 0">
                <div (click)="save()" class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4 mr-2">
                  <i *ngIf="!loadingToSave" class="fas fa-save"></i><i *ngIf="loadingToSave" class="spinner spinner-md spinner-light mr-5"></i>Guardar
                </div>
                <div *ngIf="idQuote > 0" (click)="view()" class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4 mr-2">
                  <i class="far fa-file-pdf"></i>Ver PDF
                </div>
                <div *ngIf="idQuote > 0" (click)="send()" class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4 mr-2">
                  <i class="fas fa-mail-bulk"></i>Enviar eMail
                </div>
                <div *ngIf="idQuote > 0" (click)="compare()" class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4 mr-2">
                  <i class="fas fa-columns"></i>Comparar
                </div>
              </div>
              <div>
                <a (click)="save(false)" class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4 mr-3">
                  <i *ngIf="!loadingToRate" class="fas fa-calculator"></i><i *ngIf="loadingToRate" class="spinner spinner-md spinner-light mr-5"></i>Calcular</a>
              </div>
            </div>
            <!--end: Form Actions -->

          <!--end: Form Wizard Form-->
        </div>
      </div>


  </kt-portlet-body>
</kt-portlet>
