import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';

import { mergeMap, take } from 'rxjs/operators';
import {CharecteristicsService} from '../charecteristics.service';
import {CharecteristicsTemplateModel} from '../../_models/charecteristics-template.model';

@Injectable({
  providedIn: 'root'
})
export class CharecteristicsTemplateResolverService implements Resolve<CharecteristicsTemplateModel[]> {
  constructor (private cs: CharecteristicsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {

    return this.cs.getTemplate().pipe(
      take(1),
      mergeMap(crisis => {

        if (crisis) {

          return of(crisis);

        } else {

          this.router.navigate(['/oops']);
          return EMPTY;

        }
      })
    );
  }
}
