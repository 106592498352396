import { BaseModel } from '../../_base/crud';

export class AnotherRateModel  extends BaseModel {

  IdTarifa: number;
  IdPlan: number;
  Edad: number;
  Dependientes: number;
  NSolo: number;
  Estado: number;

  clear() {
    this.IdTarifa = 0;
    this.IdPlan = 0;
    this.Edad = 0;
    this.Dependientes = 0;
    this.NSolo = 0;
    this.Estado = 0;
  }
}
