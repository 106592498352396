import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ProductModel} from '../_models/product.model';
import {BackUserModel} from '../_models/_out/back-user.model';
import {Observable, of} from 'rxjs';
import {BackTarifasResModel} from '../_models/_in/back-tarifas-res.model';
import {PlanStateModel} from '../_models/plan-state.model';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {mergeMap} from 'rxjs/operators';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetPlanes';
const URL_API_GET_ACTIVE_PLANS = 'GetPlanesActivos';

@Injectable({
  providedIn: 'root'
})

export class ProductsService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  getAll(){
    const options = {};
    return this.http.get<ProductModel[]>(URL_API.url + URL_API_GET_QUOTES, options);
  }

  getAllProducts(): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(URL_API.url + URL_API_GET_QUOTES);
  }

  getActivePlans(): Observable<ProductModel[]> {
    return this.http.get<ProductModel[]>(URL_API.url + URL_API_GET_ACTIVE_PLANS);
  }

  getProductById(productId: any): Observable<ProductModel> {
    return this.http.get<ProductModel>(URL_API.url + `GetPlanById?IdPlan=${productId}`);
  }

  update(planStateModel: PlanStateModel): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackTarifasResModel>(URL_API.url + 'ActPlan', planStateModel, {headers: httpHeaders});
  }

  updatePlan(planStateModel: ProductModel): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackTarifasResModel>(URL_API.url + 'ActPlan', planStateModel, {headers: httpHeaders});
  }

  create(planStateModel: PlanStateModel): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post(URL_API.url + 'IngPlan', planStateModel, {headers: httpHeaders});
  }

  evalById(productId): Observable<ProductModel> {
    return this.http.get<ProductModel>(URL_API.url + `ValidaPlan?IdPlan=${productId}`);
  }



  findProducts(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    return this.getAllProducts().pipe(
      mergeMap(res => {
        const result = this.httpUtils.baseFilter(res, queryParams, ['Estado', 'isBorrador']);
        return of(result);
      })
    );
  }

}
