import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {SubheaderService} from '../../../../../core/_base/layout';

@Component({
  selector: 'kt-info',
  templateUrl: './info.component.html',
  styles: [
  ]
})
export class InfoComponent implements OnInit {

  info = environment.appInfo;

  constructor(private subheaderService: SubheaderService) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Cotizador Web', 'Información de desarrollo');
  }

}
