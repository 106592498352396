import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {BackQuoteResModel} from '../../../../../../core/quotation/_models/_in/back-quote-res.model';
import {Subscription} from 'rxjs';
import {BackCompareQuoteResModel} from '../../../../../../core/quotation/_models/_in/back-compare-quote-res.model';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {PdkMaker} from '../../../../../../core/quotation/_services/pdf-make';

@Component({
  selector: 'kt-quotes-compare',
  templateUrl: './quotes-compare.component.html',
  styles: [
  ]
})
export class QuotesCompareComponent implements OnInit, OnDestroy {
  q: BackCompareQuoteResModel;
  private subscriptions: Subscription[] = [];
  pdfMake: any;

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private subheaderService: SubheaderService,
    private pdfService: PdkMaker
  ) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data) => {
        this.q = data.compare;
        console.log(data.compare);
      });
    this.subheaderService.setTitle('Comparacion', 'Planes comparados');

    this.subscriptions.push(this.subheaderService.print$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        window.print();
        this.cdr.detectChanges();
      })
    }))

    this.subscriptions.push(this.subheaderService.download$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        // this.pdfService.generatePdf(this.q);
        this.cdr.detectChanges();
      })
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

}
