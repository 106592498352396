// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { ProductCharecteristicsState } from '../_reducers/product-charecteristics.reducers';
import { ProductCharecteristicsModel } from '../_models/product-charecteristics.model';

export const selectProductCharecteristicsState = createFeatureSelector<ProductCharecteristicsState>('productCharecteristics');

export const selectProductCharecteristicsById = (IdCaracteristica: number) => createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => productCharecteristicsState.entities[IdCaracteristica]
);

export const selectProductCharecteristicsPageLoading = createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => productCharecteristicsState.loading
);

export const selectCurrentProductIdInStoreForProductCharecteristics = createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => productCharecteristicsState.productId
);

export const selectLastCreatedProductCharecteristicsId = createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => productCharecteristicsState.lastCreatedProductCharacteristicId
);

export const selectPRShowInitWaitingMessage = createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => productCharecteristicsState.showInitWaitingMessage
);

export const selectProductCharecteristicsInStore = createSelector(
    selectProductCharecteristicsState,
    productCharecteristicsState => {
      const items: ProductCharecteristicsModel[] = [];
      each(productCharecteristicsState.entities, element => {
        items.push(element);
      });
      const httpExtension = new HttpExtenstionsModel();
      // tslint:disable-next-line
      const result: ProductCharecteristicsModel[] =
        httpExtension.sortArray(items, productCharecteristicsState.lastQuery.sortField, productCharecteristicsState.lastQuery.sortOrder);

      return new QueryResultsModel(items, productCharecteristicsState.totalCount, '');
    }
);
