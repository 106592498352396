<div class="card card-custom"
     [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{getTitle()}}</h3>
    </div>
  </div>
  <form class="form" [formGroup]="benefitsForm">
    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <kt-alert type="warn" *ngIf="hasFormErrors" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
        Oh!. Algo salio mal, por favor reintente de nuevo.
      </kt-alert>
      <div *ngIf="benefit.IdBeneficio == 0" class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Seleccione el plan.</label>
            <select name="gender" formControlName="plan" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
              <option value="" disabled>-- Seleccione el Plan --</option>
              <ng-container *ngFor="let plan of plans; index as i">
                <option *ngIf="!plan.isBorrador && plan.IdProducto==1" [value]="i">{{ plan.IdPlan + ' - ' + plan.DescPlan }}</option>
              </ng-container>
            </select>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('plan', 'required')">Debe seleccionar un plan</div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Seleccione el beneficio</label>
            <select name="gender" formControlName="benefit" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
              <option value="" disabled>-- Seleccione el Beneficio --</option>
              <ng-container *ngFor="let benefit of benefits; index as i">
                <option [value]="i">{{ benefit.IdCatalogoBeneficio }} - {{ benefit.DescBeneficio | slice:0:40 }}{{ (benefit.DescBeneficio.length > 40)?'...':'' }} </option>
              </ng-container>
            </select>
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('benefit', 'required')">Debe seleccionar un beneficio</div>
          </div>
        </div>
      </div>
      <div *ngIf="benefit.IdBeneficio > 0" class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Descripción</label>
            <input formControlName="description" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('description', 'required')">Debe indicar una descripcion</div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Observacion.</label>
            <input formControlName="obser" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('obser', 'required')">Debe indicar una observacion</div>
          </div>
        </div>
      </div>
      <div  *ngIf="benefit.IdBeneficio > 0"  class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>Costo</label>
            <input formControlName="coste" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" min="1" type="number">
            <div class="fv-plugins-message-container" *ngIf="isControlHasError('coste', 'required')">Debe indicar un costo</div>
          </div>
        </div>
      </div>

    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="{{ 'AUTH.GENERAL.CANCEL_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.CANCEL' | translate }}
          </button>&nbsp;
          <button (click)="save()" type="button" mat-raised-button color="primary" [disabled]="viewLoading" matTooltip="{{ 'AUTH.GENERAL.SAVE_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
