<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">Cambiar plan</h3>
    </div>
  </div>
  <div class="form">
    <div class="card-body">
      Los planes que ve a continuación cumplen con el mismo criterio que el plan actualmente seleccionado, si desea seleccionar un plan con diferentes característica debe ir a la página de selección de plan.

      <form [formGroup]="formChange">
        <div class="form-group">

          <label>Seleccione el plan.</label>
          <select formControlName="plan" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
            <option disabled>-- Seleccione el Plan --</option>
            <ng-container *ngFor="let plan of plans; index as i">
              <option *ngIf="validate(plan)" [value]="plan.IdPlan">{{ plan.IdPlan + ' - ' + plan.DescPlan + ' - ' + plan.Region }}{{ (plan.Estado == 3)?' [borrador]':'' }}</option>
            </ng-container>
          </select>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('plan', 'required')">Debe seleccionar un plan</div>

        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button mat-button (click)="onNoClick()">Cancelar</button>&nbsp;
          <button mat-button color="primary" cdkFocusInitial (click)="onYesClick()" [disabled]="viewLoading">Cambiar</button>
        </div>
      </div>
    </div>
  </div>
</div>
