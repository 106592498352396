import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { ProvinceModel } from '../_models/province.model';

const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetProvincias';

@Injectable({
  providedIn: 'root'
})

export class ProvinceService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}


  getAllProvince(): Observable<ProvinceModel[]> {
    return this.http.get<ProvinceModel[]>(URL_API.url + URL_API_GET_QUOTES);
  }

 }
