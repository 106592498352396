import {Component, Inject, OnInit} from '@angular/core';
import {BrokerModel} from '../../../../../../core/quotation/_models/broker.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {AdditionalBenefitsService} from '../../../../../../core/quotation/_services/additional-benefits.service';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {BrokersService} from '../../../../../../core/quotation/_services/brokers.service';
import { CharecteristicsModel } from '../../../../../../core/quotation/_models/charecteristics.model';
import { CharecteristicsService } from '../../../../../../core/quotation/_services/charecteristics.service';
import {CharecteristicsSectionModel} from '../../../../../../core/quotation/_models/charecteristics-section.model';

@Component({
  selector: 'kt-charecteristic-edit',
  templateUrl: './charecteristic-edit.component.html',
  styles: [
  ]
})
export class CharecteristicEditComponent implements OnInit {
  charecteristic: CharecteristicsModel;
  viewLoading = false;
  hasFormErrors = false;
  chForm: FormGroup;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
  sections: CharecteristicsSectionModel[];

  constructor(public dialogRef: MatDialogRef<CharecteristicEditComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private layoutUtilsService: LayoutUtilsService,
              private cs: CharecteristicsService,
              private cbs: CatalogueBenefitsService
              ) { }

  ngOnInit(): void {

    this.charecteristic = this.data.charecteristicsModel;

    // tslint:disable-next-line:triple-equals
    const a: boolean = (this.data.template == 3);

    this.cs.getSeccion(a).subscribe( next =>{
      this.sections = next;
    } );
    this.createForm();
  }

  getTitle(): string {
    let ms = '';
    let planType = '';
    if (this.charecteristic.IdSeccion > 0) {
      ms = 'Editar característica';
    } else {
      ms = 'Agregar nueva característica';
    }

    if(this.data.template == 1){
      planType = '[Individual]';
    }

    if(this.data.template == 2){
      planType = '[AsisPro]';
    }

    if(this.data.template == 3){
      planType = '[Pool]';
    }

    return ms + ' ' + planType;
  }

  createForm() {
      this.chForm = this.fb.group({
        IdSeccion: [this.charecteristic.IdSeccion, Validators.required],
        Descripcion: [this.charecteristic.Descripcion, Validators.required],
      });
  }

  save() {
    this.hasFormErrors = false;
    const controls = this.chForm.controls;
    /** check form */
    if (this.chForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    console.log('controls.sections.value');
    if(controls.IdSeccion.value == 0){
      this.mensaje('Recuerde debe seleccionar la seccion de la caracteristica');
      return;
    }

    this.viewLoading = true;
    const _char: CharecteristicsModel = new CharecteristicsModel();
    _char.clear();
    const e = this.sections.find( c => c.IdSeccion == controls.IdSeccion.value );

    _char.IdSeccion = e.IdSeccion;
    _char.Seccion = e.Seccion;
    _char.TipoPlantilla = this.data.template;
    if (this.charecteristic.IdSeccion > 0) {
        _char.IdPlantillaCaract = this.charecteristic.IdPlantillaCaract;
        _char.Descripcion = controls.Descripcion.value;
      this.update(_char);
    } else {
        // _char.IdPlantillaCaract = controls.IdPlantillaCaract.value;
        _char.Descripcion =  controls.Descripcion.value;
      this.add(_char);
    }
  }

  add(charecteristic: CharecteristicsModel){
      this.cs.add(charecteristic).subscribe( next =>{
      console.log(next);
      const message = 'La característica fue ingresado con éxito.';
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ charecteristic, isEdit: false });
    }, e=> {
      console.log(e);
        this.viewLoading = false;
        this.hasFormErrors = true;

    });

  }

  update(charecteristic: CharecteristicsModel){
    this.cs.update(charecteristic).subscribe( next =>{
      console.log(next);
        const message = 'La característica fue actualizado con éxito.';
        this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
        this.dialogRef.close({ charecteristic, isEdit: false });
      this.viewLoading = false;
    }, e =>{
      console.log(e);
        this.hasFormErrors = true;

    } );
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.chForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

}
