import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../../../../core/quotation/_services/users.service';
import {AuthService, currentUser, User, UserLoaded} from '../../../../../../core/auth';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmPasswordValidator} from '../../../../auth/register/confirm-password.validator';
import {Observable, of, Subscription} from 'rxjs';
import {BackUserModel} from '../../../../../../core/quotation/_models/_out/back-user.model';

@Component({
  selector: 'kt-costumers-change-password',
  templateUrl: './costumers-change-password.component.html',
  styles: [
  ]
})
export class CostumersChangePasswordComponent implements OnInit , OnDestroy {
  user$: Observable<User>;
  user: User;
  pass: FormGroup;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  private subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private fb: FormBuilder,
              private layoutUtilsService: LayoutUtilsService,
              private translate: TranslateService,
              private store: Store<AppState>,
              private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private us: UsersService,
              private auth: AuthService,
              public subheaderService: SubheaderService) { }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.subscriptions.push(this.subheaderService.save$.subscribe(bc => {
      this.change();
      this.cdr.detectChanges();

    }));
    this.user$.subscribe((next =>{
      this.user = next;
    }));
    this.subheaderService.setTitle('Cambiar contraseña', 'Cambia tu contraseña');
    this.createForm();
  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
    this.subscriptions.forEach(el => el.unsubscribe());

  }

  createForm(){
    this.pass = this.fb.group({
      oldpassword: ['', Validators.compose([
        Validators.pattern('(?=^.{3,15}$)(?![_-].+)(?!.+[_-]$)(?!.*[_-]{2,})[^<>[\\]{}|\\\\\\/^~%# :;,$%?\\0-\\cZ]+$'),
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)
      ])
      ],
      password: ['', Validators.compose([
        Validators.pattern('(?=^.{3,15}$)(?![_-].+)(?!.+[_-]$)(?!.*[_-]{2,})[^<>[\\]{}|\\\\\\/^~%# :;,$%?\\0-\\cZ]+$'),
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)
      ])
      ],
      confirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)
      ])
      ]
    },{
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  change(){
    this.Olvido = false;
    this.hasFormErrors = false;
    const controls = this.pass.controls;
    /** check form */
    if (this.pass.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.ms = 'Debe completar todos los campos';
      this.hasFormErrors = true;
      return;
    }

    if(this.user){
      if( btoa(controls.oldpassword.value) !== this.user.Clave ){


        this.ms = 'Oh! parece que su contraseña actual no es correcta';
        this.hasFormErrors = true;
        this.Olvido = true;
        return;
      }

      if(btoa(controls.oldpassword.value) === btoa(controls.password.value)){
        this.ms = 'Su contraseña actual es la igual a la que intenta cambiar.';
        this.hasFormErrors = true;
        return;
      }
    }


    this.updateCustomer(this.user);
    this.mensaje('Contraseña cambiada con exito.', 'accent');
    this.createForm();
  }

  updateCustomer(_customer: BackUserModel) {

    const  _new = new BackUserModel();
    _new.clear();

    _new.IdUsuario = _customer.IdUsuario;
    _new.accessToken = _customer.accessToken;
    _new.TipoIdentificacion = _customer.TipoIdentificacion;
    _new.Identificacion = _customer.Identificacion;
    _new.Apellidos = _customer.Apellidos;
    _new.Nombres = _customer.Nombres;
    _new.Correo = _customer.Correo;
    _new.Clave = btoa(this.pass.controls.password.value);
    _new.Direccion = _customer.Direccion;
    _new.IdBroker = _customer.IdBroker;
    _new.Telefono = _customer.Telefono;
    _new.Genero = _customer.Genero;
    _new.FechaNacimineto = _customer.FechaNacimineto;
    _new.IdRol = _customer.IdRol;
    _new.IdTipo = _customer.IdTipo;
    _new.IdEstado = _customer.IdEstado;
    _new.NombreAgencia = _customer.NombreAgencia;
    _new.IdProvincia = _customer.IdProvincia;
    _new.Provincia = _customer.Provincia;

    this.auth.update(_new).subscribe(v => {

      if( v.Mensaje === 'Actualizado Correctamente' ){

        this.pass.controls.password.setValue('');
        this.pass.controls.confirmPassword.setValue('');
        this.pass.controls.oldpassword.setValue('');

        this.auth.getUserByToken().subscribe(r=>{
          this.store.dispatch(new UserLoaded({ user: r }));
        });

        this.mensaje(v.Mensaje, 'accent');
      } else {
        this.mensaje('Rayos! Ago salio mal por favor intente de nuevo.');
      }
    }, error =>{
      if(error.error.Mensaje){
        this.mensaje(error.error.Mensaje);
      } else {
        this.mensaje('Rayos! Ago salio mal en el servidor por favor intente de nuevo.');
      }
    });
  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.pass.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }

}
