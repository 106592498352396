<!-- BEGIN: Left Aside -->
<div
  #asideMenuOffcanvas
  class="aside aside-left d-flex flex-column flex-row-auto"
  [ngClass]="asideClasses"
  id="kt_aside"
>
  <!-- begin:: Brand -->
  <kt-brand></kt-brand>
  <!-- end:: Brand -->

  <!-- BEGIN: Aside Menu -->
  <div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
    <div
      #asideMenu
      ktMenu
      [options]="menuOptions"
      [perfectScrollbar]="{ wheelPropagation: false, suppressScrollX: false }"
      [ngStyle]="{ 'max-height': '100vh', position: 'relative' }"
      id="kt_aside_menu"
      class="aside-menu scroll"
      (mouseenter)="mouseEnter($event)"
      (mouseleave)="mouseLeave($event)"
      [ngClass]="htmlClassService.getClasses('aside_menu', true)"
    >
      <ul
        class="menu-nav"
        [ngClass]="htmlClassService.getClasses('aside_menu_nav', true)"
      >
        <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
      </ul>
    </div>
  </div>
  <!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->

<ng-template #menuListTemplate>
  <ng-container *ngFor="let child of menuAsideService.menuList$ | async">
    <ng-container
      *ngIf="child.section"
      [ngTemplateOutlet]="menuItemSectionTemplate"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
    <ng-container
      *ngIf="child.separator"
      [ngTemplateOutlet]="menuItemSeparatorTemplate"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
    <ng-container
      *ngIf="child.title"
      [ngTemplateOutlet]="menuItemTemplate"
      [ngTemplateOutletContext]="{ item: child }"
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">
  <ng-container
    *ngIf="!item.permission"
    [ngTemplateOutlet]="menuItemInnerTemplate"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
  ></ng-container>
  <ng-template
    *ngIf="item.permission"
    ngxPermissionsOnly="{{ item.permission }}"
  >
    <ng-container
      [ngTemplateOutlet]="menuItemInnerTemplate"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
    ></ng-container>
  </ng-template>
</ng-template>

<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <li
    [attr.aria-haspopup]="true"
    [attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)"
    [attr.data-ktmenu-submenu-mode]="item.mode"
    [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']"
    [ngClass]="getItemCssClasses(item)"
    [ngbTooltip]="item.tooltip"
    data-placement="right"
  >
    <!-- if menu item hasn't submenu -->
    <a
      *ngIf="!item.submenu"
      [routerLink]="item.page"
      class="menu-link menu-toggle"
    >
      <ng-container
        [ngTemplateOutlet]="menuItemTextTemplate"
        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
      ></ng-container>
    </a>
    <!-- if menu item has sumnenu child  -->
    <a *ngIf="item.submenu" class="menu-link menu-toggle">
      <ng-container
        [ngTemplateOutlet]="menuItemTextTemplate"
        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
      ></ng-container>
    </a>

    <!-- if menu item has submenu child then recursively call new menu item component -->
    <div *ngIf="item.submenu" class="menu-submenu">
      <span class="menu-arrow"></span>
      <div
        *ngIf="item['custom-class'] === 'menu-item--submenu-fullheight'"
        class="menu-wrapper"
      >
        <!-- wrap submenu to full height -->
        <ng-container
          [ngTemplateOutlet]="menuSubmenuTemplate"
          [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
        ></ng-container>
      </div>
      <!-- normal submenu -->
      <ng-container
        *ngIf="item['custom-class'] !== 'menu-item--submenu-fullheight'"
        [ngTemplateOutlet]="menuSubmenuTemplate"
        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
      >
      </ng-container>
    </div>
  </li>
</ng-template>

<ng-template #menuSubmenuTemplate let-item="item" let-parentItem="parentItem">
  <ul class="menu-subnav">
    <ng-container *ngFor="let child of item.submenu">
      <ng-container
        *ngIf="child.section"
        [ngTemplateOutlet]="menuItemSectionTemplate"
        [ngTemplateOutletContext]="{ item: child, parentItem: item }"
      ></ng-container>
      <ng-container
        *ngIf="child.separator"
        [ngTemplateOutlet]="menuItemSeparatorTemplate"
        [ngTemplateOutletContext]="{ item: child, parentItem: item }"
      ></ng-container>
      <ng-container
        *ngIf="child.title"
        [ngTemplateOutlet]="menuItemTemplate"
        [ngTemplateOutletContext]="{ item: child, parentItem: item }"
      ></ng-container>
    </ng-container>
  </ul>
</ng-template>

<ng-template #menuItemTextTemplate let-item="item" let-parentItem="parentItem">
  <!-- if menu item has icon -->
  <i *ngIf="item.icon" class="menu-icon" [ngClass]="item.icon"></i>

  <!-- if menu item using bullet -->
  <i
    *ngIf="parentItem && parentItem.bullet === 'dot'"
    class="menu-bullet menu-bullet-dot"
  >
    <span></span>
  </i>
  <i
    *ngIf="parentItem && parentItem.bullet === 'line'"
    class="menu-bullet menu-bullet-line"
  >
    <span></span>
  </i>

  <!-- menu item title text -->
  <span class="menu-text" [translate]="item.translate">{{ item.title }}</span>
  <!-- menu item with badge -->
  <span *ngIf="item.badge" class="menu-badge">
    <span class="badge" [ngClass]="item.badge.type">{{
      item.badge.value
    }}</span>
  </span>

  <!-- if menu item has submenu child then put arrow icon -->
  <i *ngIf="item.submenu" class="menu-arrow"></i>
</ng-template>

<ng-template
  #menuItemSeparatorTemplate
  let-item="item"
  let-parentItem="parentItem"
>
  <li class="menu-separator"><span></span></li>
</ng-template>

<ng-template
  #menuItemSectionTemplate
  let-item="item"
  let-parentItem="parentItem"
>
  <li class="menu-section">
    <h4 class="menu-text">{{ item.section }}</h4>
    <i class="menu-icon flaticon-more-v2"></i>
  </li>
</ng-template>
