import {ChangeDetectorRef, Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductModel} from '../../../../../../core/quotation/_models/product.model';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {Observable, Subscription} from 'rxjs';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kt-quotes-select',
  templateUrl: './quotes-select.component.html',
  styles: [
  ]
})
export class QuotesSelectComponent implements OnInit, OnDestroy {
  plans: any = [];

  filterargs = [
    {
      IdCobertura:1,
      Estado:1,
      isBorrador: false,
      ConBeneficios: true,
      IdProducto: 1,
      Region: "Todas"
    }
  ];

  filterargsInter = [
    {
      IdCobertura:2,
      Estado:1,
      isBorrador:false,
      ConBeneficios: true,
      IdProducto: 2,
      Region: "Todas"
    }
  ];

  rol:number;
  international = false;

  closeResult: string;

  private destroyed = false;

  private subscriptions: Subscription[] = [];


  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              public dialog: MatDialog,
              private cdr: ChangeDetectorRef,
              private subheaderService: SubheaderService,
              private router: Router
  ) {

  }

  ngOnInit(): void {

    this.filterargs[0].ConBeneficios = false;
    this.filterargs[0].Region = "Todas";
    this.route.data
      .subscribe((data) => {
        this.plans = data.plans;

        data.plans.forEach(
          element => {
            if(element.IdCobertura === 2){
              this.international = true;
              return true;
            }
          }
        );
      });

    this.subscriptions.push(this.subheaderService.filterb$.subscribe(bc => {
      Promise.resolve(null).then(() => {

        // @ts-ignore
        if(bc.a){
          this.filterargs[0].ConBeneficios = false;
        }else{
          this.filterargs[0].ConBeneficios = true;
        }

        // @ts-ignore
        this.filterargs[0].Region = bc.c;

        // @ts-ignore
        this.filterargs[0].IdProducto = bc.b;
        if (!this.destroyed) {
          this.cdr.detectChanges();
        }
      });
    }));

    this.rol = Number(localStorage.getItem('p'));
  }

  ngOnDestroy() {
    this.destroyed = true;
  }

  okplan(plan){

    if(plan.IdProducto !== this.filterargs[0].IdProducto){
      return false;
    }

    if(this.filterargs[0].ConBeneficios && plan.IdProducto !== 2){
      if(!plan.ConBeneficios){
        return false;
      }
    }

    if(this.filterargs[0].Region !== "Todas" && plan.Region !== this.filterargs[0].Region){
      return false;
    }

    if(this.rol === 2){
      if(plan.Estado !== 1){
        return false;
      }
    }else{
      if(plan.Estado === 2){
        return false;
      }
    }

    return true;

    /*

    if(plan.IdCobertura !== this.filterargs[0].IdCobertura){
      return false;
    }

    if(this.rol === 2){
      if(plan.isBorrador !== this.filterargs[0].isBorrador){
        return false;
      }
      if(plan.Estado !== 1){
        return false;
      }
    }else{
      if(plan.Estado === 2){
        return false;
      }
    }

    if(plan.ConBeneficios !== this.filterargs[0].ConBeneficios){
      return false;
    }

    if(plan.IdProducto !== this.filterargs[0].IdProducto){
      return false;
    }

    return true;*/
  }




  goToForm(a) {
    if(a.IdProducto === 1){
      if(!this.filterargs[0].ConBeneficios){
        this.router.navigate(['/cotizar/formulario/'+a.IdPlan+'/conBeneficios']);
        }else{
        this.router.navigate(['/cotizar/formulario/'+a.IdPlan+'/sinBeneficios']);
        }
    } else {
      // Pool
      this.router.navigate(['/cotizar/formulario/'+a.IdPlan+'/conBeneficios']);
    }
  }


}
