<kt-portlet>
  <kt-portlet-body>

    <mat-table [dataSource]="dataSource" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="IdBeneficio">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Codigo</mat-header-cell>
        <mat-cell *matCellDef="let b">{{ b.IdCatalogoBeneficio }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="DescProducto">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Producto</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.DescProducto}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="DescPlan">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Plan</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.DescPlan}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="DescBeneficio">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.DescBeneficio}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Costo">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Costo</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.Costo | currency }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Observacion">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Observacion</mat-header-cell>
        <mat-cell *matCellDef="let b">{{b.Observacion}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Estado">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Estado</mat-header-cell>
        <mat-cell *matCellDef="let b">
          {{  b.Estado }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
        <mat-cell *matCellDef="let b">
          <button (click)="edit(b)" mat-icon-button color="primary" matTooltip="Editar"
          >
            <mat-icon>create</mat-icon>
          </button>&nbsp;

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]"></mat-paginator>


    <!-- start: BOTTOM -->
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->

</kt-portlet>
