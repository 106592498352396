export class PermissionsTable {
  public static permissions: any = [
    {
      id: 1,
      name: 'accessToAuthModule',
      level: 1,
      title: 'Modulo de Usuarios'
    },
    {
      id: 2,
      name: 'accessToAdminModule',
      level: 1,
      title: 'Modulo de Administracion'
    },
    {
      id: 3,
      name: 'accessToSearchModule',
      level: 1,
      title: 'Modulo de Consultas'
    },
    {
      id: 4,
      name: 'canReadAuthData',
      level: 2,
      parentId: 1,
      title: 'Leer'
    },
    {
      id: 5,
      name: 'canEditAuthData',
      level: 2,
      parentId: 1,
      title: 'Editar'
    },
    {
      id: 6,
      name: 'canDeleteAuthData',
      level: 2,
      parentId: 1,
      title: 'Eliminar'
    },
    {
      id: 7,
      name: 'canReadCompanyData',
      level: 2,
      parentId: 2,
      title: 'Leer'
    },
    {
      id: 8,
      name: 'canEditCompanyData',
      level: 2,
      parentId: 2,
      title: 'Editar'
    },
    {
      id: 9,
      name: 'canDeleteCompanyData',
      level: 2,
      parentId: 2,
      title: 'Eliminar'
    },
    {
      id: 10,
      name: 'canReadSearchData',
      level: 2,
      parentId: 3,
      title: 'Leer'
    },
    {
      id: 11,
      name: 'canEditSearchData',
      level: 2,
      parentId: 3,
      title: 'Editar'
    },
    {
      id: 12,
      name: 'canDeleteSearchData',
      level: 2,
      parentId: 3,
      title: 'Eliminar'
    },
  ];
}
