<div class="card card-custom"
	[ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="form" [formGroup]="customerForm">
		<div class="card-body">

			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
        {{ errorMessage }}
			</kt-alert>

      <div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="{{ 'AUTH.INPUT.DNI_TYPE' | translate }}" (selectionChange)="validateDNI($event)" formControlName="dniType" [disabled]='customer.IdUsuario > 0' >
              <mat-option value="CED">{{ "AUTH.INPUT.CEDULA" | translate }}</mat-option>
              <mat-option value="PAS">{{ "AUTH.INPUT.PASSPORT" | translate }}</mat-option>
            </mat-select>
            <mat-hint align="start">
              <strong>{{ "AUTH.INPUT.DNI_TYPE_DES" | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="{{ 'AUTH.INPUT.DNI' | translate }}" formControlName="dni" onkeyup="javascript:this.value=this.value.toUpperCase();" [readonly]='customer.IdUsuario > 0' >
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.DNI_DES' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Tipo de usuario" formControlName="IdRol" (selectionChange)="changeRol($event)" [disabled]='customer.IdUsuario > 0' >
              <mat-option>--</mat-option>
              <mat-option *ngFor="let t of Roles"  [value]="t.id">
                {{ t.name }}
              </mat-option>
            </mat-select>
            <mat-hint align="start">
              <strong>{{ "AUTH.INPUT.DNI_TYPE_DES" | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="{{ 'AUTH.INPUT.LASTNAME' | translate }}" formControlName="lastName" onkeyup="javascript:this.value=this.value.toUpperCase();" [readonly]='views'/>
						<mat-error>
							<strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
						</mat-error>
						<mat-hint align="start">
							<strong>{{ 'AUTH.INPUT.LASTNAME_DES' | translate }}</strong>
						</mat-hint>
					</mat-form-field>
				</div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="{{ 'AUTH.INPUT.FIRSTNAME' | translate }}" formControlName="firstName" onkeyup="javascript:this.value=this.value.toUpperCase();" [readonly]='views'/>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.FIRSTNAME_DES' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" [readonly]='views'/>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.EMAIL_DES' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
			</div>
      <div class="form-group row" *ngIf="customer.IdUsuario == 0">
        <div class="separator separator-dashed my-6"></div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input [type]="hide ? 'password' : 'text'" formControlName="password" matInput placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"/>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.PASSWORD' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input type="password" formControlName="confirmPassword" matInput placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"/>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row" *ngIf="!isAdmin">
        <div class="separator separator-dashed my-6"></div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Agencia" formControlName="agencyBroker" [disabled]='views || perfil'>
              <mat-option>--</mat-option>
              <ng-container *ngFor="let t of brokers">
                <mat-option *ngIf="t.Estado || views || perfil || customer.IdUsuario > 0" [value]="t.IdBroker">
                  {{ t.Broker }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint align="start">
              <strong>Seleccione su broker</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input type="text" formControlName="address" placeholder="address" matInput placeholder="Dirección" [readonly]='views'/>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>Ingrese su dirección</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
			<div class="form-group row">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput formControlName="address" placeholder="{{ 'AUTH.INPUT.PHONE' | translate }}" formControlName="phone" [readonly]='views'/>
            <mat-error>
              <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
            </mat-error>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.PHONE' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="{{ 'AUTH.INPUT.GENDER' | translate }}" formControlName="gender" [disabled]='views'>
              <mat-option value="f">Femenino</mat-option>
              <mat-option value="m">Masculino</mat-option>
            </mat-select>
            <mat-hint align="start">
              <strong>{{ 'AUTH.INPUT.GENDER_DES' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <input matInput [matDatepicker]="picker"
                   placeholder="{{ 'AUTH.INPUT.BIRTHDAY_DES' | translate }}"
                   formControlName="date" [readonly]='views'/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint align="start">Indique
              <strong>su fecha de nacimiento</strong> en formato 'mm/dd/yyyy'</mat-hint>
          </mat-form-field>
        </div>
			</div>

      <div class="form-group row" *ngIf="customer.IdUsuario > 0">
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
          <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="{{ 'AUTH.INPUT.STATUS' | translate }}" formControlName="status" [disabled]='perfil || views || customer.IdEstado == 4 || customer.IdEstado == 3'>
              <mat-option>--</mat-option>
              <ng-container *ngFor="let t of Estados">
                <mat-option *ngIf="validateStatus(t.id)" [value]="t.id">
                  {{ t.name }}
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-hint *ngIf="customer.IdEstado == 3" align="start">
              <strong>{{ customer.ObservacionEstado }}</strong>
            </mat-hint>
            <mat-hint *ngIf="customer.IdEstado != 3" align="start">
              <strong>{{ 'AUTH.INPUT.STATUS_DES' | translate }}</strong>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
          <button *ngIf="views" type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial>
            Ok
          </button>&nbsp;
					<button *ngIf="!views" type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="{{ 'AUTH.GENERAL.CANCEL_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.CANCEL' | translate }}
					</button>&nbsp;
					<button *ngIf="!views" type="button" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="viewLoading" matTooltip="{{ 'AUTH.GENERAL.SAVE_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.SAVE' | translate }}
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
