<div class="card card-custom overflow-hidden">
  <div class="card-body p-0" id="htmlData">
    <!-- begin: Invoice-->
    <!-- begin: Invoice header-->
    <div class="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style="background-image: url(/assets/media/bg/bg-3.jpg);">
      <div class="col-md-9">
        <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
          <h1 class="display-4 font-weight-boldest mb-10">{{ q.NombrePlan }}</h1>
          <div class="d-flex flex-column align-items-md-end px-0">
            <!--begin::Logo-->
            <a class="mb-5">
              <img src="assets/media/logos/logo-default-inverse.png" alt="">
            </a>
            <!--end::Logo-->
            <!--
            <span class="d-flex flex-column align-items-md-end opacity-70">
															<span>Agencia {{ q.Agencia }}</span>
															<span>Dirección: {{ q.Direccion }}</span>
														</span>
            -->
          </div>
        </div>
        <div class="border-bottom w-100 opacity-20"></div>
        <div class="d-flex justify-content-between pt-6">
          <div class="d-flex flex-column flex-root">
            <span class="font-weight-bolde mb-2r">Cotización NO: {{ q.IdCotizacion }}</span>
            <span class="font-weight-bolde mb-2r">{{ q.FechaCotizacion | date }}</span>
            <br/>
            <span class="font-weight-bolder mb-2">Cliente: {{ q.Cliente }}</span>
            <span *ngIf="q.TitularEdad>0" class="font-weight-bolde mb-2r">Edad: {{ q.TitularEdad }}</span>
            <span *ngIf="q.ConyugueEdad" class="font-weight-bolde mb-2r">Edad del Cónyuge: {{ q.ConyugueEdad }}</span>
            <span class="font-weight-bolde mb-2r">Agente: {{ q.Agente }}</span>
            <span class="font-weight-bolde mb-2r">Telefono: {{ q.Telefono }}</span>
            <span class="font-weight-bolde mb-2r">Agencia: {{ q.Agencia }}</span>
            <span class="font-weight-bolde mb-2r">Dirección: {{ q.Direccion }}</span>
          </div>
          <div class="d-flex flex-column flex-root">
          </div>
          <div class="d-flex flex-column flex-root">
            <br/>
            <br/>
            <br/>
            <ng-template *ngIf="q.Dependientes?.length > 0" ></ng-template>
            <span class="font-weight-bolder mb-2">Dependientes:</span>
            <ng-template *ngIf="q.Dependientes?.length > 0" ></ng-template>
            <span *ngFor="let d of q.Dependientes; index as i" class="font-weight-bolde mb-2r">Dependiente {{ i + 1 }}: {{ d }} a&ntilde;o(s)</span>
          </div>
        </div>
      </div>
    </div>
    <!-- end: Invoice header-->
    <!-- begin: Invoice body-->
    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
      <div class="col-sm-12 col-md-10">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th class="pl-0 font-weight-bold text-muted text-uppercase" style="width:70%"></th>
              <th class="text-right font-weight-bold text-muted text-uppercase">{{ q.NombrePlanVersion[0] }}</th>
              <th class="text-right font-weight-bold text-muted text-uppercase">{{ q.NombrePlanVersion[1] }}</th>
              <th class="text-right pr-0 font-weight-bold text-muted text-uppercase" *ngIf="q.NombrePlanVersion[2]">{{ q.NombrePlanVersion[2] }}</th>
            </tr>
            </thead>
          </table>
        <ng-container *ngFor="let s of q.Caracteristicas; index as i">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="pl-0 font-weight-bold text-muted text-uppercase" style="width:70%">{{s.Seccion}}</th>
                <th class="text-right font-weight-bold text-muted text-uppercase"></th>
                <th class="text-right font-weight-bold text-muted text-uppercase"></th>
                <th class="text-right pr-0 font-weight-bold text-muted text-uppercase" *ngIf="q.NombrePlanVersion[2]"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let a of s.CompararCaracteristicas"  class="font-weight-boldest font-size-lg">
                <td class="pl-0 pt-7">{{a.Descripcion}}</td>
                <td class="text-right pt-7">{{a.Valor[0]}}</td>
                <td class="text-right pt-7">{{a.Valor[1]}}</td>
                <td class="text-right pr-0 pt-7 text-right" *ngIf="a.Valor[2]">{{a.Valor[2]}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th class="pl-0 font-weight-bold text-muted text-uppercase" style="width:70%">Tarifa</th>
              <th class="text-right font-weight-bold text-muted text-uppercase">{{ q.NombrePlan[0] }}</th>
              <th class="text-right font-weight-bold text-muted text-uppercase">{{ q.NombrePlan[1] }}</th>
              <th class="text-right pr-0 font-weight-bold text-muted text-uppercase" *ngIf="q.NombrePlan[2]">{{ q.NombrePlan[2] }}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">Mensual</td>
              <td class="text-right pt-7">{{q.Totales[0].ValorMensual}}</td>
              <td class="text-right pt-7">{{q.Totales[1].ValorMensual}}</td>
              <td class="text-right pr-0 pt-7 text-right" *ngIf="q.Totales.length > 2">{{q.Totales[2].ValorMensual}}</td>
            </tr>
            <tr class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">Trimestral</td>
              <td class="text-right pt-7">{{q.Totales[0].ValorTrimestral}}</td>
              <td class="text-right pt-7">{{q.Totales[1].ValorTrimestral}}</td>
              <td class="text-right pr-0 pt-7 text-right" *ngIf="q.Totales.length > 2">{{q.Totales[2].ValorTrimestral}}</td>
            </tr>
            <tr class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">Semestral</td>
              <td class="text-right pt-7">{{q.Totales[0].ValorSemestral}}</td>
              <td class="text-right pt-7">{{q.Totales[1].ValorSemestral}}</td>
              <td class="text-right pr-0 pt-7 text-right" *ngIf="q.Totales.length > 2">{{q.Totales[2].ValorSemestral}}</td>
            </tr>
            <tr class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">Anual</td>
              <td class="text-right pt-7">{{q.Totales[0].ValorAnual}}</td>
              <td class="text-right pt-7">{{q.Totales[1].ValorAnual}}</td>
              <td class="text-right pr-0 pt-7 text-right" *ngIf="q.Totales.length > 2">{{q.Totales[2].ValorAnual}}</td>
            </tr>
            <tr class="font-weight-boldest font-size-lg">
              <td class="pl-0 pt-7">Contado y  Diferido</td>
              <td class="text-right pt-7">{{q.Totales[0].ValorContado}}</td>
              <td class="text-right pt-7">{{q.Totales[1].ValorContado}}</td>
              <td class="text-right pr-0 pt-7 text-right" *ngIf="q.Totales.length > 2">{{q.Totales[2].ValorContado}}</td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- end: Invoice body-->
    <!-- begin: Invoice footer-->
    <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
      <div class="col-md-9 mt-4">
        <div class="d-flex justify-content-between flex-column flex-md-row font-size-lg">
          <p class="font-size-sm">{{ q.Conf_Observaciones_Pdf }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
