<mat-table [dataSource]="dataSource" class="lmat-elevation-z8">

    <ng-container matColumnDef="IdTarifa">
        <mat-header-cell *matHeaderCellDef class="mat-column mat-column-id"> Cod.  </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="mat-column mat-column-id"> {{i + 1}} </mat-cell><!-- {{getDataProperties(element, 'IdTarifa')}} -->
    </ng-container>

       
    <ng-container matColumnDef="Categoria">
        <mat-header-cell *matHeaderCellDef> Categoria </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getDataProperties(element, 'Categoria')}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Descripcion">
        <mat-header-cell *matHeaderCellDef> Descripcion </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getDataProperties(element, 'Descripcion')}} </mat-cell>
    </ng-container>
    

              <!-- NUEVOS CAMBIOS  -->
    <ng-container [matColumnDef]="column" *ngFor="let column of columnRange"> <!-- matColumnDef="Valor" -->
        <mat-header-cell *matHeaderCellDef> {{column}} </mat-header-cell>
        <mat-cell *matCellDef="let element" >
            <ng-container *ngIf="element._isEditMode">
            <form [formGroup]="formGroup">
                <mat-form-field class="mat-form-field-fluid">
                <input matInput type="number" placeholder="Rangos" [formControlName]="column">
                <mat-hint align="start">
                    {{column}}
                </mat-hint>
                </mat-form-field>
            </form>
            </ng-container> 
            <span *ngIf="!element._isEditMode"> {{getDataProperties(element, column)}}</span>
        </mat-cell>
        <!-- FIN NUEVOS CAMBIOS  -->
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="acctions">
      <mat-header-cell *matHeaderCellDef class="mat-column mat-column-actions">
        Accion
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-column mat-column-actions">
        <button *ngIf="!element._isEditMode && editable" (click)="editButtonOnClick(element)" [disabled]="isSwitchedToEditMode" type="button" mat-icon-button
                color="primary"  matTooltip="Edit remark">
          <mat-icon>create</mat-icon>
        </button>
        <button *ngIf="element._isEditMode && !loadingAfterSubmit" (click)="saveUpdated(element)" mat-icon-button
                color="primary" type="button"
                matTooltip="Save changes">
          <mat-icon>done</mat-icon>
        </button>
        <mat-spinner *ngIf="element._isEditMode && loadingAfterSubmit" [diameter]="20"></mat-spinner>

        <button *ngIf="element._isEditMode" (click)="cancelEditButtonOnClick(element)" type="button" mat-icon-button color="warn"
                matTooltip="Cancel changes">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>

    </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  </mat-table>