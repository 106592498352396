// Angular
import {Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ViewRef, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import {Subscription, of, Subject} from 'rxjs';
import {delay, finalize, takeUntil, tap} from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// CRUD
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../../core/_base/crud';
// Services and Models

import {UserModel} from '../../../../../../core/quotation/_models/user.model';
import {BrokersService} from '../../../../../../core/quotation/_services/brokers.service';
import {DateAdapter} from '@angular/material/core';
import {ConfirmPasswordValidator} from '../../../../auth/register/confirm-password.validator';
import {BackUserModel} from '../../../../../../core/quotation/_models/_out/back-user.model';
import {AuthService} from '../../../../../../core/auth';
import {BrokerModel} from '../../../../../../core/quotation/_models/broker.model';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-customers-edit-dialog',
	templateUrl: './customer-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class CustomerEditDialogComponent implements OnInit, OnDestroy {
	// Public properties
	customer: UserModel;
	customerForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	passV = true;
	isAdmin=true;
  errorMessage = 'Oh! Algo salio mal, por favor intenta de nuevo.';
	// Private properties
	private componentSubscriptions: Subscription;

  Roles = [
    {id: 1, name: 'Administrador'},
    {id: 2, name: 'Vendedor'}
  ];

  Estados = [
    {id: 1, name: 'Activo'},
    {id: 2, name: 'Inactivo'},
    {id: 3, name: 'Denegado'},
    {id: 4, name: 'Pendiente'},
    {id: 5, name: 'Suspendido'},
  ];
  private unsubscribe: Subject<any>;

  dniType;
  brokers:BrokerModel[];
  hide = true;
  perfil = false;
  views = false;
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<CustomerEditDialogComponent>
	 * @param data: any
	 * @param fb: FormBuilder
	 * @param store: Store<AppState>
	 * @param typesUtilsService: TypesUtilsService
	 */
	constructor(public dialogRef: MatDialogRef<CustomerEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
		private bs: BrokersService,
    private auth: AuthService,
    private dateAdapter: DateAdapter<Date>,
    private cdr: ChangeDetectorRef,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService) {
    this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

    this.customer = this.data.customer;
    this.bs.getAll().subscribe( next =>{
      this.brokers = next;
      this.cdr.detectChanges();
    });
    this.createForm();

    if(this.customer.IdRol === 2){
      this.isAdmin = false;
    }

    if (localStorage.getItem('perfil') === null) {
      this.perfil = false;
    }else{
      this.perfil = true;
      localStorage.removeItem('perfil');
    }

    if (localStorage.getItem('views') === null) {
      this.views = false;
    }else{
      this.views = true;
      localStorage.removeItem('views');
    }

    this.dateAdapter.setLocale('es-EC');

	}
	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
	}

	createForm() {
    if (this.customer.IdUsuario === 0) {
      this.customerForm = this.fb.group({
        dniType: [this.customer.TipoIdentificacion, [Validators.required]],
        dni: [this.customer.Identificacion, [Validators.required]],
        IdRol: [this.customer.IdRol, [Validators.required]],
        agencyBroker: [this.customer.IdBroker, []],
        lastName: [this.customer.Apellidos, Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern('^[a-zA-Z ]*$')
        ])
        ],
        firstName: [this.customer.Nombres, Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern('^[a-zA-Z ]*$')
        ])
        ],
        password: ['', Validators.compose([
          Validators.pattern('(?=^.{3,15}$)(?![_-].+)(?!.+[_-]$)(?!.*[_-]{2,})[^<>[\\]{}|\\\\\\/^~%# :;,$%?\\0-\\cZ]+$'),
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15)
        ])
        ],
        confirmPassword: ['', Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(15)
        ])
        ],
        email: [this.customer.Correo, Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          Validators.maxLength(320)
        ]),
        ],
        address: [this.customer.Direccion, []],
        phone: [this.customer.Telefono, Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10)
        ])
        ],
        date: [this.customer.FechaNacimineto, [Validators.required]],
        gender: [this.customer.Genero, [Validators.required]],
        status: [this.customer.IdEstado, [Validators.required]]
      },{
        validator: ConfirmPasswordValidator.MatchPassword
      });
    }else{
      this.customerForm = this.fb.group({
        dniType: [this.customer.TipoIdentificacion],
        dni: [this.customer.Identificacion],
        IdRol: [this.customer.IdRol],
        agencyBroker: [this.customer.IdBroker, []],
        lastName: [this.customer.Apellidos, Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern('^[a-zA-Z ]*$')
        ])
        ],
        firstName: [this.customer.Nombres, Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern('^[a-zA-Z ]*$')
        ])
        ],
        email: [this.customer.Correo, Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          Validators.maxLength(320)
        ]),
        ],
        address: [this.customer.Direccion, []],
        phone: [this.customer.Telefono, Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(10)
        ])
        ],
        date: [this.customer.FechaNacimineto, [Validators.required]],
        gender: [this.customer.Genero, [Validators.required]],
        status: [this.customer.IdEstado, [Validators.required]]
      });
    }

	}

  validateStatus(v){
	  const  idStatus = this.customer.IdEstado;

    if(idStatus == v ){
      return true;
    }

	  if(idStatus == 1 && v == 2){
	    return true;
    }

    if(idStatus == 5 && v == 1){
      return true;
    }

    if(idStatus == 2 && v == 1){
      return true;
    }

    return false;
    }


    /**
	 * Returns page title
	 */
	getTitle(): string {

    if(this.views){
      return `Datos del usuarios por aprobar: '${this.customer.Nombres} ${
        this.customer.Apellidos
      }'`;
    }

	  if(this.perfil){
	    return 'Edita tu perfil'
    }

		if (this.customer.IdUsuario > 0) {
			return `Editar Usuario '${this.customer.Nombres} ${
				this.customer.Apellidos
				}'`;
		}

		return 'Nuevo Usuario';
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.customerForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */


	/**
	 * On Submit
	 */
	onSubmit() {
		this.hasFormErrors = false;
    const controls = this.customerForm.controls;
		/** check form */
		if (this.customerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

      this.errorMessage = 'Oh! Algo salio mal, recuerde llenar todo el formulario e intentde nuevo.';

			this.hasFormErrors = true;
			return;
		}


    const _customer = new BackUserModel();
    _customer.clear();



		if (this.customer.IdUsuario > 0) {

		  _customer.IdUsuario = this.customer.IdUsuario;
		  _customer.TipoIdentificacion = this.customer.TipoIdentificacion;
		  _customer.Identificacion = this.customer.Identificacion;
		  _customer.IdTipo = this.customer.IdTipo;
      _customer.Apellidos = controls.lastName.value;
      _customer.Nombres = controls.firstName.value;
      _customer.Correo = controls.email.value;
      _customer.IdRol = this.customer.IdRol;
      _customer.FechaNacimineto = controls.date.value;
      _customer.Genero = this.customer.Genero;
      _customer.Telefono = controls.phone.value;
      _customer.Clave = this.customer.Clave;
      _customer.IdEstado = controls.status.value;
      _customer.Direccion = controls.address.value;

      if( this.customer.IdRol > 1){
        const  b: BrokerModel[] = this.brokers;
        const  n: number = controls.agencyBroker.value;
        const e = b.find( c => c.IdBroker == n );

        _customer.IdBroker = e.IdBroker;
        _customer.NombreAgencia = e.Broker;

      }else{
        _customer.IdBroker = this.brokers[0].IdBroker;
        _customer.NombreAgencia = this.brokers[0].Broker;
      }

			this.updateCustomer(_customer);

		} else {
      _customer.TipoIdentificacion = controls.dniType.value;
      _customer.Identificacion = controls.dni.value;
      _customer.Apellidos = controls.lastName.value;
      _customer.Nombres = controls.firstName.value;
      _customer.Correo = controls.email.value;
      _customer.Clave = btoa(controls.password.value);
      _customer.Telefono = controls.phone.value;
      _customer.Genero = controls.gender.value;
      _customer.FechaNacimineto = controls.date.value;
      _customer.Direccion = controls.address.value;

      if( controls.IdRol.value > 1){
        const  b: BrokerModel[] = this.brokers;
        const  n: number = controls.agencyBroker.value;
        const e = b.find( c => c.IdBroker === n );

        _customer.IdBroker = e.IdBroker;
        _customer.NombreAgencia = e.Broker;
        _customer.IdEstado = 4;
        _customer.IdRol = 2;
      }else{
        _customer.IdBroker = this.brokers[0].IdBroker;
        _customer.Direccion = this.brokers[0].Direccion;
        _customer.NombreAgencia = this.brokers[0].Broker;
        _customer.IdEstado = 1;
        _customer.IdRol = 1;
      }
			this.createCustomer(_customer);
		}
	}

	isIdBroker(c: BrokerModel, d){
	  return c.IdBroker === d;
  }

	/**
	 * Update customer
	 *
	 * @param _customer: CustomerModel
	 */
	updateCustomer(_customer: BackUserModel) {

    this.auth.update(_customer).subscribe(v => {
      if( v.Mensaje === 'Actualizado Correctamente' ){
        if(this.perfil){
          localStorage.removeItem('perfil');
          this.layoutUtilsService.showActionNotification('Perfil Actualizado, es posible que algunos cambios no los vea hasta que vuelva a iniciar sesion.', MessageType.Read, 15000, true, false);
        }else{
          this.layoutUtilsService.showActionNotification('Usuarios Actualizado Exitosamente', MessageType.Read, 10000, true, false);
        }
        this.dialogRef.close({ _customer, isEdit: false });
      } else{
        this.errorMessage = v.Mensaje;
        this.hasFormErrors = true;
      }

    }, error =>{
      this.errorMessage = error.error.Mensaje;
      this.hasFormErrors = true;
    });
	}

	/**
	 * Create customer
	 *
	 * @param _customer: CustomerModel
	 */
	createCustomer(_customer: BackUserModel) {
    this.auth.add(_customer).subscribe( next =>{
      const message = `El nuevo usuario fue agregado con exito.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Read, 10000, true, false);
      this.dialogRef.close({ _customer, isEdit: false });
    }, e =>{
      this.errorMessage = e.error.Mensaje;
      this.hasFormErrors = true;
    } );
	}

	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

  validateDNI(e){
    this.dniType = e.value;
    const dniInput = this.customerForm.get('dni');
    dniInput.clearValidators();
    switch (this.dniType){
      case 'CED':
        dniInput.setValidators([Validators.required,
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10)
        ]);
        break;
      case 'PAS':
        dniInput.setValidators([Validators.required,
          Validators.minLength(4),
          Validators.maxLength(15),
          Validators.pattern('^[a-zA-Z0-9]*$')]);
        break;
      default:
        dniInput.updateValueAndValidity();
        break;
    }
  }

  changeRol(e){
    const a = e.value;

    if( a === 1 ){
      this.isAdmin = true;
      this.customerForm.controls.agencyBroker.setValidators([]);
      this.customerForm.controls.address.setValidators([]);
      this.customerForm.controls.address.setValue(this.brokers[0].Direccion);
    } else {
      this.isAdmin = false;
      this.customerForm.controls.agencyBroker.setValidators([Validators.required]);
      this.customerForm.controls.address.setValidators([Validators.required]);
    }
  }

  compareItems(i1, i2) {
    return i1 && i2 && i1.IdRol===i2.id;
  }
}
