// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../../partials/partials.module';
// Core
import { FakeApiService } from '../../../../core/_base/layout';
// Auth
import { ModuleGuard } from '../../../../core/auth';
// Core => Services
import {
  productsReducer,
  ProductEffects,
  ProductsService,
  productCharacteristicsReducer,
  ProductCharacteristicEffects,
  ProductsCharecteristicService
} from '../../../../core/quotation';
// Core => Utils
import {
  HttpUtilsService,
  TypesUtilsService,
  InterceptService,
  LayoutUtilsService
} from '../../../../core/_base/crud';
// Shared
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from '../../../partials/content/crud';
// Components
import { QuotationComponent } from './quotation.component';
// Customers
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { CustomerEditDialogComponent } from './customers/customer-edit/customer-edit.dialog.component';
// Products
import { ProductsListComponent } from './products/products-list/products-list.component';
import { ProductEditComponent } from './products/product-edit/product-edit.component';
// Orders
import { OrdersListComponent } from './orders/orders-list/orders-list.component';
import { OrderEditComponent } from './orders/order-edit/order-edit.component';
// Material
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../../../../../environments/environment';
import { NgbProgressbarModule, NgbProgressbarConfig, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuotesListComponent } from './quotes/quotes-list/quotes-list.component';
import { QuotesSelectComponent } from './quotes/quotes-select/quotes-select.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProductsSettingsComponent } from './products/products-settings/products-settings.component';
import { QuotesAddComponent } from './quotes/quotes-add/quotes-add.component';
import { PlansResolverService } from 'src/app/core/quotation/_services/resolvers/plans-resolver.service';
import { UsersResolverService } from '../../../../core/quotation/_services/resolvers/users-resolver.service';
import { ValidatePlansResolverService } from '../../../../core/quotation/_services/resolvers/validate-plans-resolver.service';
import { QuotesSelectOptionsComponent } from './quotes/quotes-select/quotes-select-options/quotes-select-options.component';
import { CoreModule } from '../../../../core/core.module';
import { PlanResolverService } from '../../../../core/quotation/_services/resolvers/plan-resolver.service';
import { QuotesPdfComponent } from './quotes/quotes-pdf/quotes-pdf.component';
import { PdfResolverService } from '../../../../core/quotation/_services/resolvers/pdf-resolver.service';
import { BrokerListComponent } from './broker/broker-list/broker-list.component';
import { BrokerEditComponent } from './broker/broker-edit/broker-edit.component';
import { BeniftCatalogueEditComponent } from './benefit-catalogue/benift-catalogue-edit/benift-catalogue-edit.component';
import { BeniftCatalogueListComponent } from './benefit-catalogue/benift-catalogue-list/benift-catalogue-list.component';
import { BeniftPlanListComponent } from './benefit-plan/benift-plan-list/benift-plan-list.component';
import { BeniftPlanEditComponent } from './benefit-plan/benift-plan-edit/benift-plan-edit.component';
import { BrokersResolverService } from '../../../../core/quotation/_services/resolvers/brokers-resolver.service';
import { CatalogueBenefitsResolverService } from '../../../../core/quotation/_services/resolvers/catalogue-benefits-resolver.service';
import { AdditionalBenefitsResolverService } from '../../../../core/quotation/_services/resolvers/additional-benefits-resolver.service';
import { CostomersApprovalComponent } from './customers/costomers-approval/costomers-approval.component';
import { UsersApprovalResolverService } from 'src/app/core/quotation/_services/resolvers/users-approval-resolver.service';
import { CostumersChangePasswordComponent } from './customers/costumers-change-password/costumers-change-password.component';
import { CharecteristicsComponent } from './products/_subs/charecteristics/charecteristics.component';
import { AgeRangeComponent } from './products/_subs/age-range/age-range.component';
import { GenderRateComponent } from './products/_subs/gender-rate/gender-rate.component';
import { DependentsComponent } from './products/_subs/dependents/dependents.component';
import { KidsRateComponent } from './products/_subs/kids-rate/kids-rate.component';
import { PoolRateComponent } from './products/_subs/pool-rate/pool-rate.component';
import { CategoryRateComponent } from './products/_subs/category-rate/category-rate.component';
import { PoolRateEditComponent } from './products/_subs/pool-rate/pool-rate-edit/pool-rate-edit.component';
import { GeneralConfigComponent } from './general-config/general-config.component';
import { GeneralConfigResolverService } from '../../../../core/quotation/_services/resolvers/general-config-resolver.service';
import { CategoryComponent } from './products/category/category.component';
import { SendMailComponent } from './quotes/_subs/send-mail/send-mail.component';
import { ChangePlanComponent } from './quotes/_subs/change-plan/change-plan.component';
import { CharecteristicEditComponent } from './product-charecteristics/charecteristics-edit/charecteristic-edit.component';
import { CharecteristicListComponent } from './product-charecteristics/charecteristics-list/charecteristic-list.component';
import { QuoteResolverService } from '../../../../core/quotation/_services/resolvers/quote-resolver.service';
import { CompareSelectComponent } from './quotes/_subs/compare-select/compare-select.component';
import { QuotesCompareComponent } from './quotes/quotes-compare/quotes-compare.component';
import { ComparePdfResolverService } from '../../../../core/quotation/_services/resolvers/compare-pdf-resolver.service';
import { SectionEditComponent } from './section/section-edit/section-edit.component';
import { SectionListComponent } from './section/section-list/section-list.component';
import { SortFeaturesComponent } from './product-charecteristics/sort-features/sort-features.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CharecteristicsSelectComponent } from './product-charecteristics/sort-features/charecteristics-select/charecteristics-select.component';
import { CharecteristicsTemplateResolverService } from '../../../../core/quotation/_services/resolvers/charecteristics-template-resolver.service';
import { SectionCatalogueListComponent } from './section-catalogue/section-catalogue-list/section-catalogue-list.component';
import { SectionCatalogueEditComponent } from './section-catalogue/section-catalogue-edit/section-catalogue-edit.component';
import { CharecteristicCatalogueListComponent } from './charecteristic-catalogue/charecteristic-catalogue-list/charecteristic-catalogue-list.component';
import { CharecteristicCatalogueEditComponent } from './charecteristic-catalogue/charecteristic-catalogue-edit/charecteristic-catalogue-edit.component';
import { CatalogueSectionsResolverService } from 'src/app/core/quotation/_services/resolvers/catalogue-sections-resolver.service';
import { CatalogueCharecteristicsResolverService } from 'src/app/core/quotation/_services/resolvers/catalogue-charecteristics-resolver.service';
import { ForAgeComponent } from './products/_subs/for-age/for-age.component';
import { AnotherRateComponent } from './products/_subs/another-rate/another-rate.component';
import { ForAgeEditComponent } from './config-for-age/for-age-edit/for-age-edit.component';
import { ForAgeListComponent } from './config-for-age/for-age-list/for-age-list.component';
import { ForDependentsEditComponent } from './config-for-dependents/for-dependents-edit/for-dependents-edit.component';
import { ForDependentsListComponent } from './config-for-dependents/for-dependents-list/for-dependents-list.component';
import { TableRangeComponent } from './products/_subs/components/table-range/table-range.component';

// tslint:disable-next-line:class-name
const routes: Routes = [
  {
    path: '',
    component: QuotationComponent,
    // canActivate: [ModuleGuard],
    // data: { moduleName: 'quotes' },
    children: [
      {
        path: '',
        redirectTo: 'nuevo',
        pathMatch: 'full'
      },
      {
        path: 'nuevo',
        component: QuotesSelectComponent,
        resolve: {
          plans: PlansResolverService
        }
      },
      {
        path: 'listar',
        component: QuotesListComponent
      },
      {
        path: 'listar/planes',
        component: ProductsListComponent
      },
      {
        path: 'agregar/plan',
        component: ProductEditComponent
      },
      {
        path: 'editar/plan/:id',
        component: ProductEditComponent,
        resolve: {
          editable: ValidatePlansResolverService
        }
      },
      {
        path: 'formulario/:IdPlan/:beneficio',
        component: QuotesAddComponent,
        resolve: {
          plan: PlanResolverService
        }
      }
      ,
      {
        path: 'edit/formulario/:IdPlan/:IdQ/:beneficio/:isPool',
        component: QuotesAddComponent,
        resolve: {
          plan: QuoteResolverService
        }
      },
      {
        path: 'ver/:IdQ/:isPool/pdf',
        component: QuotesPdfComponent,
        resolve: {
          q: QuoteResolverService
        }
      },
      {
        path: 'formulario/:typeplan',
        component: QuotesAddComponent
      },
      {
        path: 'configuracion/general',
        component: ProductsSettingsComponent,
        resolve: {
          config: GeneralConfigResolverService
        }
      },
      {
        path: 'perfil',
        component: CostumersChangePasswordComponent
      },
      {
        path: 'usuarios',
        component: CustomersListComponent,
        resolve: {
          users: UsersResolverService
        }
      },
      {
        path: 'aprobacion/usuarios',
        component: CostomersApprovalComponent,
        resolve: {
          users: UsersApprovalResolverService
        }
      },
      {
        path: 'agencias',
        component: BrokerListComponent,
        resolve: {
          brokers: BrokersResolverService
        }
      },
      {
        path: 'beneficio/catalogo',
        component: BeniftCatalogueListComponent,
        resolve: {
          benefits: CatalogueBenefitsResolverService
        }
      },
      {
        path: 'caracteristicas/catalogo',
        component: CharecteristicCatalogueListComponent,
        resolve: {
          benefits: CatalogueCharecteristicsResolverService
        }
      },
      {
        path: 'seccion/catalogo',
        component: SectionCatalogueListComponent,
        resolve: {
          benefits: CatalogueSectionsResolverService
        }
      },
      {
        path: 'beneficio/plan',
        component: BeniftPlanListComponent,
        resolve: {
          benefits: AdditionalBenefitsResolverService
        }
      },
      {
        path: 'comparar/:isPool/:IdContizacion/:IdPlan1/:IdPlan2',
        component: QuotesCompareComponent,
        resolve: {
          compare: ComparePdfResolverService
        }
      },
      {
        path: 'listarCategoria',
        component: CategoryComponent,
      },
      {
        path: 'Caracteristica',
        component: CharecteristicListComponent,
      },
      {
        path: 'OrdenarCaracteristica',
        component: SortFeaturesComponent,
        resolve: {
          Feactures: CharecteristicsTemplateResolverService
        }
      },
      {
        path: 'configuracion/rangoEdades',
        component: ForAgeListComponent,
      },
      {
        path: 'configuracion/rangoDependientesNiños',
        component: ForDependentsListComponent,
      }
    ]
  }
];


@NgModule({
  exports: [],
  imports: [
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    PartialsModule,
    NgxPermissionsModule.forChild(),
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    DragDropModule,
    MatTooltipModule,
    NgbProgressbarModule,
    environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forFeature(FakeApiService, {
      passThruUnknownUrl: true,
      dataEncapsulation: false
    }) : [],
    StoreModule.forFeature('products', productsReducer),
    EffectsModule.forFeature([ProductEffects]),
    StoreModule.forFeature('productCharecteristics', productCharacteristicsReducer),
    EffectsModule.forFeature([ProductCharacteristicEffects]),
    MatExpansionModule,
    CoreModule,
    NgbModalModule,
  ],
  providers: [
    ModuleGuard,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'mat-dialog-container-wrapper',
        height: 'auto',
        width: '900px'
      }
    },
    TypesUtilsService,
    LayoutUtilsService,
    HttpUtilsService,
    ProductsService,
    ProductsCharecteristicService,
    TypesUtilsService,
    LayoutUtilsService
  ],
  entryComponents: [
    ActionNotificationComponent,
    CustomerEditDialogComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    BeniftCatalogueEditComponent,
    CharecteristicCatalogueEditComponent,
    SectionCatalogueEditComponent,
    BrokerEditComponent,
    PoolRateEditComponent,
    SendMailComponent,
    CharecteristicEditComponent,
    BeniftPlanEditComponent,
    ChangePlanComponent,
    CompareSelectComponent,
    CharecteristicsSelectComponent,
    ForAgeEditComponent,
    ForDependentsEditComponent,
  ],
  declarations: [
    QuotationComponent,
    // Customers
    CustomersListComponent,
    CustomerEditDialogComponent,
    // Orders
    OrdersListComponent,
    OrderEditComponent,
    // Products
    ProductsListComponent,
    ProductEditComponent,
    QuotesListComponent,
    QuotesSelectComponent,
    ProductsSettingsComponent,
    QuotesAddComponent,
    QuotesSelectOptionsComponent,
    QuotesPdfComponent,
    BrokerListComponent,
    BrokerEditComponent,
    BeniftCatalogueEditComponent,
    BeniftCatalogueListComponent,
    BeniftPlanListComponent,
    BeniftPlanEditComponent,
    CostomersApprovalComponent,
    CostumersChangePasswordComponent,
    CharecteristicsComponent,
    AgeRangeComponent,
    GenderRateComponent,
    DependentsComponent,
    KidsRateComponent,
    PoolRateComponent,
    CategoryRateComponent,
    PoolRateEditComponent,
    GeneralConfigComponent,
    CategoryComponent,
    SendMailComponent,
    ChangePlanComponent,
    CharecteristicEditComponent,
    CharecteristicListComponent,
    CompareSelectComponent,
    QuotesCompareComponent,
    SectionEditComponent,
    SectionListComponent,
    SortFeaturesComponent,
    CharecteristicsSelectComponent,
    SectionCatalogueListComponent,
    SectionCatalogueEditComponent,
    CharecteristicCatalogueListComponent,
    CharecteristicCatalogueEditComponent,
    ForAgeComponent,
    AnotherRateComponent,
    ForAgeEditComponent,
    ForAgeListComponent,
    ForDependentsEditComponent,
    ForDependentsListComponent,
    TableRangeComponent
    // Quotations
  ]
})
export class QuotationModule { }
