// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';
import { InfoComponent } from './views/pages/apps/quotation/info/info.component';

const routes: Routes = [
  { path: 'entrar', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'oops', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'info', component: InfoComponent },
      {
        path: 'inicio',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'productos',
        loadChildren: () => import('./views/pages/apps/quotation/quotation.module').then(m => m.QuotationModule),
      },
      {
        path: 'brokers',
        loadChildren: () => import('./views/pages/apps/quotation/quotation.module').then(m => m.QuotationModule),
      },
      {
        path: 'cotizar',
        loadChildren: () => import('./views/pages/apps/quotation/quotation.module').then(m => m.QuotationModule),
      },
      { path: '', redirectTo: 'inicio', pathMatch: 'full' },
      { path: '**', redirectTo: 'inicio', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'oops', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
