// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'plansfilter',
  pure: false
})
export class PlansPipe implements PipeTransform {
  transform(myobjects: Array<object>, args?: Array<object>): any {
    if (args && Array.isArray(myobjects)) {
      // copy all objects of original array into new array of objects
      let returnobjects = myobjects;
      // args are the compare oprators provided in the *ngFor directive
      // tslint:disable-next-line:only-arrow-functions
      args.forEach(function (filterobj) {
        const filterkey = Object.keys(filterobj)[0];
        const filtervalue = filterobj[filterkey];
        myobjects.forEach(function (objectToFilter) {
          if (objectToFilter[filterkey] != filtervalue && filtervalue != '') {
            // object didn't match a filter value so remove it from array via filter
            returnobjects = returnobjects.filter(obj => obj !== objectToFilter);
          }
        })
      });
      // return new object to *ngFor directive
      return returnobjects;
    }
  }
}
