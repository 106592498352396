// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  home: 'http://localhost:4200/',
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  n: 'n',
  //apiRootURL: 'http://192.168.33.12/CotizadorWeb/api/',
   apiRootURL: 'http://localhost:8081/api/',
  appInfo: {
    version: '2.0.0',
    productName: 'Cotizador Web',
    owner: {
      name: 'Asisken Asistencia Médica S. A.',
      address: '',
      email: '',
      phone: ''
    }
  },
  config: {
    idPrincipalAgency: 3,
    byForce:{ // configuracion forzada por el back
      defaulIdStatusUser:4,
      defaulIdRolUser:1
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
