export class RolesTable {
  public static roles: any = [
    {
      id: 1,
      title: 'Coder',
      isCoreRole: true,
      permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    {
      id: 2,
      title: 'Administrador',
      isCoreRole: false,
      permissions: [1, 4, 5, 6, 7]
    },
    {
      id: 3,
      title: 'Cliente',
      isCoreRole: false,
      permissions: [3, 10, 11]
    }
  ];
}
