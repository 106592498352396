import {Component, OnInit, Input, ChangeDetectorRef, ViewRef } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GenderRateModel} from '../../../../../../../core/quotation/_models/gender-rate.model';
import {PlansGenderRateService} from '../../../../../../../core/quotation/_services/product_subs/plans-gender-rate.service';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';
import { ForAgeRangeService } from 'src/app/core/quotation/_services/forAgeRange.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'kt-gender-rate',
  templateUrl: './gender-rate.component.html',
  styles: [
  ]
})

export class GenderRateComponent implements OnInit {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;

  dataSource = new MatTableDataSource();
  //dataSource: any[];
  formGroup: FormGroup;
  genderForEdit: GenderRateModel;
  isSwitchedToEditMode = false; // Controla que solo se pueda editar una sola celda
  loadingAfterSubmit = false;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  rangos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public load = this.loadRange.bind(this);
  public save = this.saveUpdated.bind(this);

  formBackup: any;
  columnRange: string [] = [];
  displayedColumns: string [] = [];

  constructor(private plansGenderServices: PlansGenderRateService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private layoutUtilsService: LayoutUtilsService,
              private forAgeRangeService: ForAgeRangeService) { }

  ngOnInit(): void {
    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

      this.productId = res;
      //this.loadRange();
      //this.loadData(res);
      //this.createFormGroup();
    });

    //////////////////////////////////////
/*     this.nuevoDataSource = [] //TABLE DATASOURCE

//GET SOMETHING FROM SERVICE 
this.listecidenKisi = this.listeciServis.listecidenKisi;
this.listecidenVazife = this.listeciServis.listecidenVazife;

//FILL TABLE DATASOURCE 
var obj = {};
for (let i in this.rangos ){
    for( let v of this.rangos[i].Rango){
        obj[v.name] = v.value;
    }
    this.nuevoDataSource.Push(obj);
    obj={};
}

//CREATE DISPLAYED COLUMNS DYNAMICALLY
this.displayedColumns = [];
for( let v in this.nuevoDataSource[0]){
    this.displayedColumns.Push(v);
}

//INITIALIZE MatTableDataSource
this.dataSource = new MatTableDataSource(this.nuevoDataSource); */



    //////////////////////////////////////
  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

  }

  loadData(id){
    this.plansGenderServices.get(id).subscribe( next =>{
      this.dataSource = new MatTableDataSource(next);
    });
/*     this.plansGenderServices.getTarifaRango(id).subscribe( next =>{
      //this.dataSource = this.columnRange.map(x => this.formatInputRow(x));
      this.dataSource = new MatTableDataSource(next);
      this.dataa = next;
      console.log(this.dataa);
      console.log('datasource',this.dataSource);
    }); */

  }

/*   formatInputRow(row) {
    const output = {};

    for (let i = 0; i < this.dataa.length; ++i) {
      output[this.dataa[i].Valor] = this.dataa[i][row];
    }

    return output;
  } */

  public getRango(): Observable<any[]>{
    return this.rangos$.asObservable();
  }

  loadRange(id: number){
    return this.plansGenderServices.getTarifaRango(id);
  }

  saveUpdated(data: any[]) {
    return this.plansGenderServices.updateRangoGender(data);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }

}
