import { BaseModel } from '../../_base/crud';

export class RateCategoryModel  extends BaseModel {

  IdTarifaCat: number;
  IdPlan: number;
  IdCategoria: number;
  DescCategoria: string;
  Rango1: number;
  Rango2: number;
  Rango3: number;
  Rango4: number;
  Rango5: number;
  Rango6: number;
  Rango7: number;
  Rango8: number;
  Rango9: number;
  Rango10: number;
  Rango11: number;
  Rango12: number;
  Rango13: number;
  Rango14: number;
  Rango15: number;

  clear() {
    this.IdTarifaCat = 0;
    this.IdPlan = 0;
    this.IdCategoria = 0;
    this.DescCategoria = '';
    this.Rango1 = 0;
    this.Rango2 = 0;
    this.Rango3 = 0;
    this.Rango4 = 0;
    this.Rango5 = 0;
    this.Rango6 = 0;
    this.Rango7 = 0;
    this.Rango8 = 0;
    this.Rango9 = 0;
    this.Rango10 = 0;
    this.Rango11 = 0;
    this.Rango12 = 0;
    this.Rango13 = 0;
    this.Rango14 = 0;
    this.Rango15 = 0;                                                                                                    

  }
}
