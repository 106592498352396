import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {UserModel} from '../_models/user.model';
const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_USERS = 'usuario';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  jsonConfig: any;

  constructor(private http: HttpClient) {
  }

  getUsers(){
    const options = {};
    return this.http.get(URL_API.url + URL_API_GET_USERS, options);
  }

  getAll(){
    const options = {};
    return this.http.get<UserModel[]>(URL_API.url + URL_API_GET_USERS, options);
  }

  getAllToApproval(){
    const options = {};
    return this.http.get<UserModel[]>(URL_API.url + 'GetUsarioByEstado?estado=4', options);
  }

}
