export class BackUserModel {
  IdUsuario: number;
  accessToken: string;
  TipoIdentificacion: string;
  Identificacion: string;
  Apellidos: string;
  Nombres: string;
  Correo: string;
  Clave: string;
  Direccion: string;
  IdBroker: number;
  Telefono: string;
  Genero: string;
  FechaNacimineto: string;
  IdRol:  number;
  IdTipo: number;
  IdEstado: number;
  NombreAgencia: string;
  IdProvincia:  number;
  Provincia: string;

  clear() {
    this.IdUsuario = 0;
    this.accessToken = 'access-token-8f3ae836da744329a6f93bf20594b5cc';
    this.TipoIdentificacion = '';
    this.Identificacion = '';
    this.Apellidos = '';
    this.Nombres = '';
    this.Correo = '';
    this.Clave = '';
    this.Direccion = '';
    this.IdBroker = 0;
    this.Telefono = '';
    this.Genero = '';
    this.FechaNacimineto = '';
    this.IdRol = 0;
    this.IdTipo = 0;
    this.IdEstado = 0;
    this.NombreAgencia = '';
    this.IdProvincia = 1;
    this.Provincia = '';
  }
}
