import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ProductModel} from '../../_models/product.model';
import {Observable} from 'rxjs';
import {BackTarifasResModel} from '../../_models/_in/back-tarifas-res.model';
import {PlanStateModel} from '../../_models/plan-state.model';
import {RateCategoryModel} from '../../_models/rate-category.model';
import {HttpUtilsService} from '../../../_base/crud';
import { RateModel } from '../../_models/rate.model';

const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
  providedIn: 'root'
})

export class PlansCategoryRateService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}

  get(id: number){
    const options = {};
    return this.http.get<RateCategoryModel[]>(URL_API.url + 'GetTarifaCategoria?idPlan=' + id, options);
  }

  update(Characteristics: RateCategoryModel): Observable<any> {
    const RateCategory: RateCategoryModel[] = [];
    RateCategory.push(Characteristics);
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaCategorias', RateCategory, {
      headers: httpHeaders
    });
  }

  updateRangoCategory(Rates: any[]): Observable<any> {
    const httpHeaders = this.httpUtils.getHTTPHeaders();
    return this.http.put(URL_API.url + 'ActTarifaRangoEdad', Rates, {
      headers: httpHeaders
    });
  }
 
  getTarifaRango(id: number){
    const options = {};
    return this.http.get<RateModel[]>(URL_API.url + 'GetTarifaRangoCategoria?idplan=' + id, options);
  }

}
