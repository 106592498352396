import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ProductModel} from '../_models/product.model';
import {Observable, of} from 'rxjs';
import {HttpUtilsService, QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { RegionModel } from '../_models/region.model';

const URL_API = JSON.parse(localStorage.getItem('r'));
const URL_API_GET_QUOTES = 'GetRegiones';

@Injectable({
  providedIn: 'root'
})

export class RegionService {
  constructor(private http: HttpClient,
              private httpUtils: HttpUtilsService) {}


  getAllProducts(): Observable<RegionModel[]> {
    return this.http.get<RegionModel[]>(URL_API.url + URL_API_GET_QUOTES);
  }

}
