// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Register, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BackUserModel} from '../../../../core/quotation/_models/_out/back-user.model';
import {environment} from '../../../../../environments/environment';
import {BrokerModel} from '../../../../core/quotation/_models/broker.model';
import {MessageType} from '../../../../core/_base/crud';
import { ProvinceService } from 'src/app/core/quotation/_services/province.service';

import {ReCaptcha2Component} from 'ngx-captcha';
@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('state', [
      state(
        'visible',
        style({
          opacity: '1'
        })
      ),
      state(
        'hidden',
        style({
          opacity: '0'
        })
      ),
      transition('* => visible', [animate('500ms ease-out')]),
      transition('visible => hidden', [animate('500ms ease-out')])
    ])
  ]
})
export class RegisterComponent implements OnInit, OnDestroy {
  URL_API = JSON.parse(localStorage.getItem('r'));
  siteKey = '6LdnLwgUAAAAAAIb9L3PQlHQgvSCi16sYgbMIMFR';

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme = 'light';
  public size = 'normal';
  public lang = 'es';
  public type = 'image' ;
  public useGlobalDomain: boolean = false;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  public aFormGroup: FormGroup;
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
  errorMasager: string;
  stateBroker: string;

  provincia: any = [];
  nameProvince: '';
  brokers: any = [];

  errorMin = '';
  errorMax ='';

  errorMinCedula = 'Debe contener al menos 10 digitos';
  errorMaxCedula = 'Solo debe contener 10 digitos';
  errorMinPassport = 'Debe contener al menos 4 digitos';
  errorMaxPassport = 'Solo debe contener 10 digitos';

  cedulaInvalid = false;
  dniType;
  maxlengthDNI = 15;

  nombreAgencia = '';

  today = new Date(new Date().valueOf()-(((365*18)+5)*24*60*60*1000)).toJSON().split('T')[0];

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  private _showBroker: boolean;
  get showBroker() {
    return this._showBroker;
  }

  resolved(e) {
    this.captchaSuccess = true;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressAlpha(event: any) {
    const pattern = /^[a-zA-Z ]*$/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  set showBroker(val: boolean) {
    if (val) {
      this._showBroker = true;
      this.stateBroker = 'visible';
    } else {
      this.stateBroker = 'hidden';
    }
  }
	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private provinceService: ProvinceService
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
    this.captchaSuccess = false;
    this.loadProvince();
		this.initRegisterForm();
    this.route.data
      .subscribe((data) => {
        this.brokers = data.result;
      });
	}

	/*
    * On destroy
    */

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

  loadProvince(){
    this.provinceService.getAllProvince().subscribe(data =>{
      this.provincia = data;
      console.log(this.provincia);
    })
  }
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
      dniType: ['', [Validators.required]],
      dni: ['', [Validators.required]],
      userType: ['', [Validators.required]],
      agencyBroker: ['', []],
      lastName: ['', Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z ]*$')
      ])
      ],
      firstName: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z ]*$')
			])
			],
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				// https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				Validators.maxLength(320)
			]),
			],
      address: ['', []],
			password: ['', Validators.compose([
        Validators.pattern('(?=^.{3,15}$)(?![_-].+)(?!.+[_-]$)(?!.*[_-]{2,})[^<>[\\]{}|\\\\\\/^~%# :;,$%?\\0-\\cZ]+$'),
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(15)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(15)
			])
			],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10)
      ])
      ],
      date: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      province: ['', [Validators.required]]
    }, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

  brokerSelect(e){
	  if(e.target.value === 'asisken'){
      this.showBroker = false;
      this.registerForm.controls.agencyBroker.setValidators([]);
      this.registerForm.controls.address.setValidators([]);
      this.registerForm.controls.address.setValue(this.brokers[0].Direccion);
      this.nombreAgencia = this.brokers[0].Broker;
    }else{
      this.showBroker = true;
      this.registerForm.controls.agencyBroker.setValidators([Validators.required]);
      this.registerForm.controls.address.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)
      ]);
    }
  }

	validateDNI(e){
	  this.dniType = e.target.value;
    const dniInput = this.registerForm.get('dni');
    dniInput.clearValidators();
	  switch (this.dniType){
      case 'CED':
        dniInput.setValidators([Validators.required,
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10)
        ]);
        this.errorMax = this.errorMaxCedula;
        this.errorMin = this.errorMinCedula;
        this.maxlengthDNI = 10;
        break;
      case 'PAS':
        dniInput.setValidators([Validators.required,
          Validators.minLength(4),
          Validators.maxLength(15),
          Validators.pattern('^[a-zA-Z0-9]*$')]);
        this.errorMax = this.errorMaxPassport;
        this.errorMin = this.errorMinPassport;
        this.maxlengthDNI = 15;
        this.cedulaInvalid = false;
        break;
      default:
        dniInput.updateValueAndValidity();
        break;
    }
  }

  changeBrocker(e){
	  const a = e.target.value;

	  if( a !== '' ){

      const  b: BrokerModel[] = this.brokers;
      const  n: number = this.registerForm.controls.agencyBroker.value;

      const f: BrokerModel = b.find( c => c.IdBroker == n );


      if(f.Direccion){
        this.registerForm.controls.address.setValue(f.Direccion);
      }


    }
  }

  validateCedula(){
    const dniInput = this.registerForm.get('dni');
    if(this.dniType === 'CED'){
      this.cedulaInvalid = !this.validadorDeCedula(dniInput.value);
    }
  }

  validadorDeCedula(cedula: string) {
    let cedulaCorrecta = false;
    if (cedula.length === 10)
    {
      const tercerDigito = parseInt(cedula.substring(2, 3), 0);
      if (tercerDigito < 6) {
        const coefValCedula = [2, 1, 2, 1, 2, 1, 2, 1, 2];
        const verificador = parseInt(cedula.substring(9, 10), 0);
        let suma = 0;
        let digito = 0;
        for (let i = 0; i < (cedula.length - 1); i++) {
          digito = parseInt(cedula.substring(i, i + 1), 0) * coefValCedula[i];
          suma += ((parseInt((digito % 10)+'', 0) + (parseInt((digito / 10)+'', 0))));
        }
        suma= Math.round(suma);
        if ((Math.round(suma % 10) === 0) && (Math.round(suma % 10) === verificador)) {
          cedulaCorrecta = true;
        } else if ((10 - (Math.round(suma % 10))) === verificador) {
          cedulaCorrecta = true;
        } else {
          cedulaCorrecta = false;
        }
      } else {
        cedulaCorrecta = false;
      }
    } else {
      cedulaCorrecta = false;
    }
    return cedulaCorrecta;
  }


  getProvinceName(id:any){
    const i = parseInt(id);
    for (let index = 0; index < this.provincia.length; index++) {
      if(this.provincia[index].ProvinciaID === i){
        return this.provincia[index].Descripcion;
      }
    }
  }
	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.authNoticeService.setNotice('Debe completar todo el formulario', 'danger');

      return;
		}

		this.loading = true;

		const _user: BackUserModel = new BackUserModel();
		_user.clear();

    const  b: BrokerModel[] = this.brokers;
    const  n: number = (this.showBroker)?controls.agencyBroker.value:1;
    const e = b.find( c => c.IdBroker == n );


    _user.TipoIdentificacion = controls.dniType.value;
    _user.Identificacion = controls.dni.value;
    _user.Apellidos = controls.lastName.value;
    _user.Nombres = controls.firstName.value;
    _user.Correo = controls.email.value;
    _user.Clave = btoa(controls.password.value);
    _user.Direccion = e.Direccion;
    _user.IdProvincia = controls.province.value;
    _user.Provincia =this.getProvinceName(controls.province.value);
    _user.IdBroker = e.IdBroker;
    _user.Telefono = controls.phone.value;
    _user.Genero = controls.gender.value;
    _user.FechaNacimineto = controls.date.value;
    _user.NombreAgencia = e.Broker;
    _user.IdRol = 2;
    _user.IdEstado = 4;


    this.auth.add(_user).subscribe( next =>{
      this.authNoticeService.setNotice(this.translate.instant('AUTH.REGISTER.SUCCESS'), 'success');
      this.router.navigateByUrl('/entrar');
    }, error =>{
      if(error.error.Mensaje){
        this.authNoticeService.setNotice(error.error.Mensaje, 'danger');
        this.loading = false;
      }else{
        this.authNoticeService.setNotice(this.translate.instant('AUTH.GENERAL.SERVER_ERROR'), 'danger');
        this.loading = false;
      }
    } );
	}

  onErrorAlertClose() {
    this.errorMasager = '';
  }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

  animationDone(event) {
    if (event.fromState === 'visible' && event.toState === 'hidden') {
      this._showBroker = false;
    }
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
}
