import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserModel} from '../../../../../../core/quotation/_models/user.model';
import {SelectionModel} from '@angular/cdk/collections';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {ActivatedRoute} from '@angular/router';
import {UsersService} from '../../../../../../core/quotation/_services/users.service';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {CustomerEditDialogComponent} from '../customer-edit/customer-edit.dialog.component';
import {AuthService, currentUser} from '../../../../../../core/auth';
import {BackUserModel} from '../../../../../../core/quotation/_models/_out/back-user.model';

@Component({
  selector: 'kt-costomers-approval',
  templateUrl: './costomers-approval.component.html',
  styles: [
  ]
})
export class CostomersApprovalComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: MatTableDataSource<UserModel>;
  displayedColumns = ['Identificacion', 'Nombres', 'NombreAgencia', 'actions'];


  selection = new SelectionModel<UserModel>(true, []);
  customersResult: UserModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param snackBar: MatSnackBar
   * @param layoutUtilsService: LayoutUtilsService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param route
   */
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private us: UsersService,
    private auth: AuthService,
    public subheaderService: SubheaderService
  ) { }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {

    this.subheaderService.setTitle('Aprobacion de usuario', 'Estos son los usuarios por aprobacion');
    // Init DataSource
    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.users);
      });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** ACTIONS */
  /**
   * Delete customer
   *
   * @param _item: CustomerModel
   */
  deleteCustomer(_item: UserModel) {
    const _title = 'Desaprobar usuarios';
    const _description = 'Porfavor ayudenos indicando el porque de la desaprobacion';
    const _waitDesciption = 'Por favor espere';
    const _deleteMessage = 'El usuario fue desaprobado';

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption, _item);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.us.getAllToApproval().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
      this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Read, 10000, true, false);
    });
  }



  /**
   * Show Edit customer dialog and save after success close result
   * @param customer: CustomerModel
   */
  views(customer: BackUserModel) {
    localStorage.setItem('views', '___________');

    const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      localStorage.removeItem('views');
    });
  }

  approval(customer: BackUserModel) {
    const _user: BackUserModel = customer;
    _user.IdEstado = 1;
    this.auth.approval(_user).subscribe(v => {
      this.us.getAllToApproval().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
      this.layoutUtilsService.showActionNotification('Usuario Aprobado con exito.', MessageType.Read, 10000, true, false);
    }, error =>{
      this.layoutUtilsService.showActionNotification('Ocurrio un problema intente de nuevo.', MessageType.Read, 10000, true, false);
    });
  }


  /**
   * Toggle all selections
   */

  /** UI */
  /**
   * Retursn CSS Class Name by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 1:
        return 'success';
      case 2:
        return 'metal';
      case 3:
        return 'danger';
      case 4:
        return 'metal';
      case 5:
        return 'metal';
    }
    return '';
  }

  /**
   * Returns Item Status in string
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 1:
        return 'Activo';
      case 2:
        return 'Inactivo';
      case 3:
        return 'Denegado';
      case 4:
        return 'Pendiente';
      case 5:
        return 'Suspendido';
    }
    return '';
  }

  /**
   * Returns CSS Class Name by type
   * @param status: number
   */
  getItemCssClassByType(status: number = 0): string {
    switch (status) {
      case 0:
        return 'primary';
      case 1:
        return 'danger';
      case 2:
        return 'success';
    }
    return 'success';
  }

}
