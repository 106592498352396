import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CatalogueBenefitsModel} from '../_models/catalogue-benefits.model';
import {BackResModel} from '../_models/_in/back-res.model';
const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
  providedIn: 'root'
})

export class CatalogueBenefitsService {
  constructor(private http: HttpClient) {}

  getAll(){
    const options = {};
    return this.http.get<CatalogueBenefitsModel[]>(URL_API.url + 'GetCatalogosBeneficios', options);
  }

  add(benefits: CatalogueBenefitsModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<BackResModel>(URL_API.url + 'IngCatalogoBeneficio', benefits, {headers: httpHeaders});
  }

  update(benefits: CatalogueBenefitsModel){
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.put<BackResModel>(URL_API.url + 'ActCatalogoBeneficio', benefits, {headers: httpHeaders});
  }

}
