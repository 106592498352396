export class BackTarifaNormalModel {
  IdPlan: number;
  TitularBeneficio: boolean;
  TitularEdad: number;
  TitularGenero: string;
  TitularMaternidad: boolean;
  ConyugueEdad: number;
  ConyugueGenero: string;
  Dependientes: number[];
  CoberturasAdicionales: number[];
  CatBeneficiosAdicionales: number[];
  Estado: boolean;
  TitularNacimiento: Date;
  ConyugueFechaNacimiento : Date;
  IdUsuario: number;
  Cliente: string;
  GuardarCotizacion: boolean;



  clear() {
    this.IdPlan = 0;
    this.TitularBeneficio = true;
    this.TitularEdad = 0;
    this.TitularGenero = '';
    this.TitularMaternidad = true;
    this.GuardarCotizacion = true;
    this.ConyugueEdad = 0;
    this.ConyugueGenero = '';
    this.Dependientes = [];
    this.CoberturasAdicionales = [];
    this.CatBeneficiosAdicionales = [];
    this.Estado = true;
    this.TitularNacimiento = new Date();
    this.ConyugueFechaNacimiento = new Date();
    this.IdUsuario = 0;
    this.Cliente = '';
  }
}
