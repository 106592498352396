import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {BrokerModel} from '../../../../../../core/quotation/_models/broker.model';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {SelectionModel} from '@angular/cdk/collections';
import {UserModel} from '../../../../../../core/quotation/_models/user.model';
import {Subscription} from 'rxjs';
import {CatalogueBenefitsModel} from '../../../../../../core/quotation/_models/catalogue-benefits.model';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';
import {BeniftPlanEditComponent} from '../../benefit-plan/benift-plan-edit/benift-plan-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {CatalogueBenefitsService} from '../../../../../../core/quotation/_services/catalogue-benefits.service';
import {AdditionalBenefitsService} from '../../../../../../core/quotation/_services/additional-benefits.service';
import {ProductsService} from '../../../../../../core/quotation/_services/products.service';
import {BrokersService} from '../../../../../../core/quotation/_services/brokers.service';
import {BrokerEditComponent} from '../broker-edit/broker-edit.component';

@Component({
  selector: 'kt-broker-list',
  templateUrl: './broker-list.component.html',
  styles: [
  ]
})
export class BrokerListComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<BrokerModel>;
  displayedColumns = ['Ruc', 'Broker', 'Telefono', 'Descripcion', 'Direccion', 'Mail', 'Estado', 'actions'];

  filterGeneral = '';
  selection = new SelectionModel<BrokerModel>(true, []);
  customersResult: UserModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private cbs: CatalogueBenefitsService,
    private bs: BrokersService,
    private ps: ProductsService,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {
    this.subheaderService.setTitle('Agencias', 'Estas son sus agencias');
    this.route.data
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data.brokers);
      });

    this.subscriptions.push(this.subheaderService.setNewBroker$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        if(bc){
          this.add();
        }
      });
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  edit(broker: BrokerModel) {
    console.log(broker);
    const dialogRef = this.dialog.open(BrokerEditComponent,  { data: { broker } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.bs.getAll().subscribe( next =>{
        this.dataSource = new MatTableDataSource(next);
        this.cdr.detectChanges();
      } );
    });
  }

  add() {
    const broker = new BrokerModel();
    broker.clear();
    this.edit(broker);
  }

}
