export class Address {

  constructor () {
    this.clear();
  }

  // tslint:disable-next-line: variable-name
  address_line: string;
  city: string;
  state: string;
  // tslint:disable-next-line: variable-name
  post_code: string;

  clear() {
    this.address_line = '';
    this.city = '';
    this.state = '';
    this.post_code = '';
  }
}
