<div class="card card-custom"
     [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">{{getTitle()}}</h3>
    </div>
  </div>

    <div class="card-body">
      <div class="card-body-progress">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <kt-alert *ngIf="hasFormErrors" type="{{type}}" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
        {{ms}}
      </kt-alert>
      <form [formGroup]="brokerForm">
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Ruc</label>
              <input formControlName="ruc" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" [readonly]='broker.IdBroker > 0'>
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('ruc', 'required')">Por favor ingrese el RUC</div>
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('ruc', 'pattern')">Por favor ingrese solo números</div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Nombre</label>
              <input formControlName="brokerName" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('brokerName', 'required')">Debe indicar el nombre</div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Teléfono</label>
              <input formControlName="brokerPhone" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('brokerPhone', 'required')">Por favor indique numero de telefono</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Descripción</label>
              <input formControlName="des" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('des', 'required')">Debe indicar una descripcion</div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Dirección</label>
              <input formControlName="address" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('address', 'required')">Debe indicar una dirección</div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="form-group">
              <label>Correo</label>
              <input formControlName="email" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text">
              <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'required')">Debe indicar un correo electronico</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <div class="form-group">
              <label>Estado</label>
              <select formControlName="statu" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
                <option value="true">Activo</option>
                <option value="false">Inactivo</option>
              </select>
            </div>
          </div>
        </div>
      </form>

    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between">
        <div class="mr-2"></div>
        <div>
          <button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="{{ 'AUTH.GENERAL.CANCEL_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.CANCEL' | translate }}
          </button>&nbsp;
          <button (click)="save()" type="button" mat-raised-button color="primary" [disabled]="viewLoading" matTooltip="{{ 'AUTH.GENERAL.SAVE_CHANGE' | translate }}">
            {{ 'AUTH.GENERAL.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
</div>

