// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewRef,
} from "@angular/core";
// RxJS
import { Subscription } from "rxjs";
// Layout
import { SubheaderService } from "../../../../../core/_base/layout";
import { Breadcrumb } from "../../../../../core/_base/layout/services/subheader.service";
import { CustomersListComponent } from "../../../../pages/apps/quotation/customers/customers-list/customers-list.component";
import { TranslateService } from "@ngx-translate/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { RegionService } from "src/app/core/quotation/_services/region.service";

@Component({
  selector: "kt-subheader1",
  templateUrl: "./subheader1.component.html",
  styleUrls: ["./subheader1.component.scss"],
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  @Input() fixed = true;
  @Input() clear = false;
  @Input() width = "fluid";
  @Input() subheaderClasses = "";
  @Input() subheaderContainerClasses = "";
  @Input() displayDesc = false;
  @Input() displayDaterangepicker = true;

  today: number = Date.now();
  title = "";
  desc = "";
  breadcrumbs: Breadcrumb[] = [];
  newUser = false;

  nameRegion: "";
  region: any = [];
  
  filterByBeneficio = "Si";
  filterByTipoDePlan: "Individual";
  filterByRegion = "Todas";

  IdTemplate = 1;
  IdTemplateDes = "Individual";

  // Private properties
  private subscriptions: Subscription[] = [];
  private history: string[] = [];
  /**
   * Component constructor
   *
   * @param subheaderService: SubheaderService
   */
  constructor(
    public subheaderService: SubheaderService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private regionService: RegionService,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.loadRegion();
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.subheaderService.title$.subscribe((bt) => {
        if (bt) {
          Promise.resolve(null).then(() => {
            this.title = bt.title;
            this.desc = bt.desc;
            if (this.title == "Generar Cotizacion") {
              this.iniFilter();
            }
            if (this.title == "Lista Característica") {
              this.setTemplate(1);
            }
            this.cdr.detectChanges();
          });
        }
      })
    );

    this.subscriptions.push(
      this.subheaderService.breadcrumbs$.subscribe((bc) => {
        Promise.resolve(null).then(() => {
          this.breadcrumbs = bc;
        });
      })
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  template(id) {
    if (id == 1) {
      return "Individual";
    }
    if (id == 2) {
      return "AsisPro";
    }
    if (id == 3) {
      return "Pool";
    }
  }

  nu() {
    this.subheaderService.setNewUser(true);
  }

  newBenefit() {
    this.subheaderService.setNewBanefit(true);
  }

  newCatalogue() {
    this.subheaderService.setNewCatalogue(true);
  }

  newBroker() {
    this.subheaderService.setNewBroker(true);
  }

  newCharecteristic() {
    this.subheaderService.setNewCharecteristic(true);
  }
  save() {
    this.subheaderService.save(true);
  }
         
  send() {
    this.subheaderService.send(true);
  }

  download() {
    this.subheaderService.download(true);
  }

  print() {
    this.subheaderService.print(true);
  }

  change() {
    this.subheaderService.change(true);
  }

  reset() {
    console.log("reset en cabecera");
    this.subheaderService.reset(true);
  }

  upload() {
    this.subheaderService.uploap(true);
  }

  filterByBenef(a) {
    this.filterByBeneficio = a;
    this.applyFiler(a, this.filterByTipoDePlan, this.filterByRegion);
  }

  filterByPlans(b) {
    this.filterByTipoDePlan = b;
    this.applyFiler(this.filterByBeneficio, b, this.filterByRegion);
  }

  filterByReg(c) {
    this.filterByRegion = c;
    this.applyFiler(this.filterByBeneficio, this.filterByTipoDePlan, c);
  }

  setTemplate(b) {
    this.IdTemplateDes = this.template(b);
    this.IdTemplate = b;
    this.subheaderService.setIdTemplate(b);
  }

  applyFiler(a, b, c) {
    this.subheaderService.filter(a, b, c);
  }

  iniFilter() {
    this.filterByBenef("Si");
    this.filterByPlans("Individual");
    this.filterByReg("Todas");
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
  }

  loadRegion() {
    this.regionService.getAllProducts().subscribe((data) => {
      this.region = data;
      console.log(this.region);
    });
  }
}
