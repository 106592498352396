<!--begin::Wrapper-->
<div class="login-content d-flex flex-column">
  <!--begin::Logo-->
  <div class="login-logo pb-xl-20 pb-15 pt-20">
    <img src="assets/media/logos/logo-4.png" class="max-h-70px" alt="" />
  </div>
  <!--end::Logo-->
  <!--begin::Signin-->
  <div class="login-form">
    <!--begin::Form-->
    <form
      class="form fv-plugins-bootstrap fv-plugins-framework"
      [formGroup]="registerForm"
      autocomplete="off"
      novalidate="novalidate"
      id="kt_login_form"
    >
      <div class="pb-1 pb-lg-5">
        <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
          {{ "AUTH.REGISTER.TITLE" | translate }}
        </h3>
        <div class="text-muted font-weight-bold font-size-h4">
          {{ "AUTH.REGISTER.QUESTION" | translate }}
          <a
            routerLink="/entrar"
            class="text-primary font-weight-bolder"
          >{{ "AUTH.REGISTER.QUIESTION_LINK" | translate }}</a
          >
        </div>
      </div>

      <kt-auth-notice></kt-auth-notice>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.DNI_TYPE" | translate }}</label>
        <select (change)="validateDNI($event)" name="dniType" formControlName="dniType" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
          <option value="" disabled>{{ "AUTH.INPUT.DNI_TYPE_DES" | translate }}</option>
          <option value="CED">{{ "AUTH.INPUT.CEDULA" | translate }}</option>
          <option value="PAS">{{ "AUTH.INPUT.PASSPORT" | translate }}</option>
        </select>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dniType', 'required')">{{ "AUTH.VALIDATION.DNI_TYPE" | translate }}</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.DNI" | translate }}</label>
        <input formControlName="dni" (change)="validateCedula()" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" name="email" placeholder="{{ 'AUTH.INPUT.DNI_DES' | translate }}" maxlength="{{maxlengthDNI}}" autocomplete="off" onkeyup="javascript:this.value=this.value.toUpperCase();">
        <div class="fv-plugins-message-container" *ngIf="cedulaInvalid">{{ "AUTH.VALIDATION.DNI_ERROR" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'pattern')">{{ "Ingrese caracteres validos" }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'minlength')">{{ errorMin }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('dni', 'maxlength')">{{ errorMax }}</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.USER_TYPE" | translate }}</label>
        <select name="userType" (change)="brokerSelect($event)" formControlName="userType" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
          <option value="" disabled>{{ "AUTH.INPUT.USER_TYPE_DES" | translate }}</option>
          <option value="asisken">{{ "AUTH.INPUT.ASISKEN" | translate }}</option>
          <option value="broker">{{ "AUTH.INPUT.BROKER" | translate }}</option>
        </select>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('userType', 'required')">{{ "AUTH.INPUT.USER_TYPE_DES" | translate }}</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.LASTNAME" | translate }}</label>
        <input formControlName="lastName" (keypress)="keyPressAlpha($event)" formControlName="lastName" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" name="lastName" placeholder="{{ 'AUTH.INPUT.LASTNAME_DES' | translate }}" maxlength="100" autocomplete="off" onkeyup="javascript:this.value=this.value.toUpperCase();">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('lastName', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('lastName', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('lastName', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('lastName', 'pattern')">Ingrese solo letras.</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ 'AUTH.INPUT.FIRSTNAME' | translate }}</label>
        <input formControlName="firstName" (keypress)="keyPressAlpha($event)" name="firstName" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" placeholder="{{ 'AUTH.INPUT.FIRSTNAME_DES' | translate }}" maxlength="100" autocomplete="off" onkeyup="javascript:this.value=this.value.toUpperCase();">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('firstName', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('firstName', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('firstName', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('firstName', 'pattern')">Ingrese solo letras.</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ 'AUTH.INPUT.EMAIL' | translate }}</label>
        <input formControlName="email" name="email" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" placeholder="{{ 'AUTH.INPUT.EMAIL_DES' | translate }}" maxlength="100" autocomplete="off">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'email')">{{ 'AUTH.VALIDATION.INVALID_EMAIL' | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('email', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</div>
      </div>

      <div class="form-group fv-plugins-icon-container has-success">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ "AUTH.INPUT.PASSWORD" | translate }}</label>
        </div>
        <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 invalid" type="password" formControlName="password" name="password" value="demo" autocomplete="off">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'pattern')">{{ 'AUTH.VALIDATION.SPECIAL_CHARACTERS' | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 6</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('password', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 15</div>
        <div class="fv-plugins-message-container" *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">Las contraseñas no coinciden</div>
      </div>

      <div class="form-group fv-plugins-icon-container has-success">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5">{{ "AUTH.INPUT.CONFIRM_PASSWORD" | translate }}</label>
        </div>
        <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 invalid" type="password" formControlName="confirmPassword" name="confirmPassword" autocomplete="off">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('confirmPassword', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('confirmPassword', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 6</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('confirmPassword', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 15</div>
      </div>
      <div *ngIf="showBroker" [@state]="stateBroker" (@state.done)="animationDone($event)">

        <div class="form-group fv-plugins-icon-container">
          <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.BROKER" | translate }}</label>
          <select name="agencyBroker" formControlName="agencyBroker" (change)="changeBrocker($event)" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
            <option value="" disabled>{{ "AUTH.INPUT.BROKER_DES" | translate }}</option>
            <ng-container *ngFor="let broker of brokers">
              <option *ngIf="broker.Estado"  [value]="broker.IdBroker">{{broker.Broker}}</option>
            </ng-container>
          </select>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('agencyBroker', 'required')">{{ "AUTH.INPUT.BROKER_DES" | translate }}</div>
        </div>

        <div class="form-group fv-plugins-icon-container">
          <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.ADDRESS" | translate }}</label>
          <input formControlName="address" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" name="address" placeholder="{{ 'AUTH.INPUT.ADDRESS_DES' | translate }}" autocomplete="off" onkeyup="javascript:this.value=this.value.toUpperCase();">
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('address', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('address', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 6</div>
          <div class="fv-plugins-message-container" *ngIf="isControlHasError('address', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 15</div>
        </div>

      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "Provincia" | translate }}</label>
        <select name="province" formControlName="province" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
          <option value="" disabled>{{ "Provincia" | translate }}</option>
          <ng-container *ngFor="let pro of provincia">
            <option [value]="pro.ProvinciaID">{{pro.Descripcion}}</option>
          </ng-container>
        </select>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('province', 'required')">Este campo es requerido</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.PHONE" | translate }}</label>
        <input formControlName="phone" mask='(000) 000-0000' (keypress)="keyPress($event)" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="tel" name="phone" placeholder="{{ 'AUTH.INPUT.PHONE' | translate }}" maxlength="10" autocomplete="off">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('phone', 'required')">{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('phone', 'minlength')">{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 6</div>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('phone', 'maxlength')">{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 10</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.GENDER" | translate }}</label>
        <select name="gender" formControlName="gender" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0">
          <option value="" disabled>{{ "AUTH.INPUT.GENDER_DES" | translate }}</option>
          <option value="f">Femenino</option>
          <option value="m">Masculino</option>
        </select>
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('gender', 'required')">{{ "AUTH.INPUT.GENDER_DES" | translate }}</div>
      </div>

      <div class="form-group fv-plugins-icon-container">
        <label class="font-size-h6 font-weight-bolder text-dark">{{ "AUTH.INPUT.BIRTHDAY" | translate }}</label>
        <input name="birthday" formControlName="date" class="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" [max]="today" type="date">
        <div class="fv-plugins-message-container" *ngIf="isControlHasError('date', 'required')">{{ "AUTH.INPUT.BIRTHDAY_DES" | translate }}</div>
      </div>

      <div *ngIf="URL_API.recaptcha" class="form-group row">
        <re-captcha (resolved)="resolved($event)"
                    formControlName="recaptchaReactive"
                    siteKey="{{ URL_API.sitekey }}">
        </re-captcha>
      </div>

      <kt-auth-notice></kt-auth-notice>
      <div class="pb-lg-0 pb-5">
        <button id="kt_login_signin_submit"
                [disabled]="URL_API.recaptcha && !captchaSuccess"
                (click)="submit()"
                [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">{{ "AUTH.REGISTER.BUTTON" | translate }}</button>

        <a routerLink="/entrar" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">{{ "AUTH.GENERAL.BACK_BUTTON" | translate }}</a>
      </div>

      <!--begin::Action-->
      <!--end::Action-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Signin-->
</div>
<!--end::Wrapper-->
