import { PlansPoolRateService } from './../../../../../../../core/quotation/_services/product_subs/plans-pool-rate.service';
import { Component, OnInit, Input, ChangeDetectorRef, ViewRef } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UpdateStatusDialogComponent} from '../../../../../../partials/content/crud';
import {MatDialog} from '@angular/material/dialog';
import {PoolRateEditComponent} from './pool-rate-edit/pool-rate-edit.component';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {PoolRateModel} from '../../../../../../../core/quotation/_models/pool-rate.model';
import {LayoutUtilsService, MessageType} from '../../../../../../../core/_base/crud';

export interface RateElement {
  id: number;
  descripction: string;
  o1: number;
  o2: number;
  o3: number;
  o4: number;
}

const ELEMENT_DATA_TABLE: RateElement[] = [
  {id: 1, descripction: 'Titular Solo', o1: 0, o2: 0, o3: 0, o4: 0},
  {id: 1, descripction: 'Titular + 1 dependiente', o1: 0, o2: 0, o3: 0, o4: 0},
  {id: 1, descripction: 'Titular más familia', o1: 0, o2: 0, o3: 0, o4: 0},
];

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'}
];
@Component({
  selector: 'kt-pool-rate',
  templateUrl: './pool-rate.component.html',
  styles: [
  ]
})
export class PoolRateComponent implements OnInit {
  @Input() productId$: Observable<number>;
  @Input() editable: boolean;
  productId: number;
  displayedColumns: string[] = ['Categoria', 'Valor', 'acctions'];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  formGroup: FormGroup;
  poolForEdit: PoolRateModel;
  isSwitchedToEditMode = false;
  loadingAfterSubmit = false;
  hasFormErrors = false;
  Olvido = false;
  type = 'warn';
  ms = 'Rayos! Ago salio mal por favor intente de nuevo.';

  constructor(private plansPoolRateService: PlansPoolRateService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {

    console.log(this.editable);

    this.productId$.subscribe(res => {
      if (!res) {
        return;
      }

      this.productId = res;
      this.loadData(res);
      this.createFormGroup();
    });
  }

  ngOnDestroy() {

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }

  }

  loadData(id){
    this.plansPoolRateService.get(id).subscribe( next =>{
      const PoolRate: PoolRateModel[] = [];
      const Pool5 = next.filter(e => e.CantidadMin < 16);
      this.dataSource = new MatTableDataSource(Pool5);
      const Pool16 = next.filter(e => e.CantidadMin > 15);
      this.dataSource2 = new MatTableDataSource(Pool16);
    });

  }

  createFormGroup() {
    // 'edit' prefix - for item editing
    this.formGroup = this.fb.group({
      Valor: [0 , Validators.compose([Validators.required])],
    });
    this.clearEditForm();
  }

  clearEditForm() {
    const controls = this.formGroup.controls;
    controls.Valor.setValue(0);

    this.poolForEdit = new PoolRateModel();
    this.poolForEdit._isEditMode = false;
  }

  editButtonOnClick(_item: PoolRateModel) {
    const controls = this.formGroup.controls;
    controls.Valor.setValue(_item.Valor);
    _item._isEditMode = true;
    this.isSwitchedToEditMode = true;
  }

  cancelEditButtonOnClick(_item: PoolRateModel) {
    _item._isEditMode = false;
    this.isSwitchedToEditMode = false;
  }

  saveUpdated(_item: PoolRateModel) {

    this.loadingAfterSubmit = true;
    const controls = this.formGroup.controls;
    const objectForUpdate = new PoolRateModel();
    objectForUpdate.IdTarifaPool = _item.IdTarifaPool;
    objectForUpdate.IdPlan = this.productId;
    objectForUpdate.IdCategoria = _item.IdCategoria;
    objectForUpdate.Categoria = _item.Categoria;
    objectForUpdate.IdTarifaGen = _item.IdTarifaGen;
    objectForUpdate.CantidadMin = _item.CantidadMin;
    objectForUpdate.Valor = controls.Valor.value;
    objectForUpdate._isEditMode = false;

   this.plansPoolRateService.update(objectForUpdate).subscribe( next =>{
      this.loadData(this.productId);
      this.loadingAfterSubmit = false;
      this.isSwitchedToEditMode = false;
      this.layoutUtilsService.showActionNotification('Actualizacion Tarifa con exito', MessageType.Update, 10000, true, false);
       // Detectar el cambio
       this.cdr.detectChanges();
    }, error => {
     this.loadingAfterSubmit = false;
     this.isSwitchedToEditMode = false;
     if(error.error.Mensaje){
       this.mensaje(error.error.Mensaje);
       this.cdr.detectChanges();
     } else {
       this.mensaje('Rayos! Algo salio mal en el servidor por favor intente de nuevo.');
       this.cdr.detectChanges();
     }
   });

   this.cdr.detectChanges();

    /*  const saveMessage = `Categoria actualizada`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, false);*/
  }

  mensaje(ms, type='warn'){
    this.ms = ms;
    this.type = type;
    this.hasFormErrors = true;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.ms = 'Rayos! Ago salio mal por favor intente de nuevo.';
    this.type = 'warn';
  }

}
