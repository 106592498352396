<!--begin::Subheader-->
<div class="subheader py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
  <div [ngClass]="subheaderContainerClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline mr-5">
        <ng-container *ngIf="title">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-2 mr-5">
            {{title}}
          </h5>
        </ng-container>
        <!--end::Page Title-->

        <!--begin::Action-->
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200" *ngIf="desc"></div>
        <span class="text-muted font-weight-bold mr-4" *ngIf="desc">{{desc}}</span>
        <!--end::Action-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <div class="d-flex align-items-center">

      <a *ngIf="title == 'Formulario de Cotizacion'" routerLink="/cotizar/nuevo"
        class="btn btn-light font-weight-bold btn-sm mr-2">
        <i class="flaticon2-left-arrow-1"></i>{{ 'QUOTATION.GENERAL.BACK' | translate }}</a>
      <a *ngIf="title == 'Formulario de Cotizacion'" (click)="reset()"
        class="btn btn-light font-weight-bold btn-sm mr-2">
        <i class="flaticon2-reload-1"></i>{{ 'QUOTATION.GENERAL.RESET' | translate }}</a>
      <!--begin::Dropdown-->
      <div ngbDropdown *ngIf="title == 'Formulario de Cotizacion'" [placement]="'bottom-right'"
        class="dropdown dropdown-inline" ngbTooltip="¿Desea cambiar el plan?">
        <a ngbDropdownToggle (click)="change()" href="javascript:;" class="btn btn-light btn-sm font-weight-bold mr-3">
          <span class="text-primary font-weight-bold">Cambiar Plan</span>
        </a>
      </div>
      <!--end::Dropdown-->



      <a *ngIf="title == 'Cotizaciones'" (click)="reset()" class="btn btn-primary font-weight-bold btn-sm mr-2">
        <i class="flaticon-refresh"></i>{{ 'QUOTATION.GENERAL.REFRESH' | translate }}</a>

      <a *ngIf="title == 'Cotizaciones' || title == 'Bienvenido'" routerLink="/cotizar/nuevo"
        class="btn btn-primary font-weight-bold btn-sm">
        <i class="flaticon2-add-square"></i>{{ 'QUOTATION.GENERAL.NEW_QUOTATION' | translate }}</a>

      <a (click)="nu()" *ngIf="title == 'Lista de usuarios'" class="btn btn-primary font-weight-bold btn-sm">
        <i class="flaticon2-add-square"></i>{{ 'QUOTATION.GENERAL.NEW_USER' | translate }}</a>

      <a (click)="upload()" *ngIf="title == 'Productos'" class="btn btn-secondary font-weight-bold btn-sm mr-2">
        <i class="flaticon2-refresh-arrow"></i>Refrescar</a>

      <a routerLink="/productos/agregar/plan" *ngIf="title == 'Productos'"
        class="btn btn-primary font-weight-bold btn-sm">
        <i class="flaticon2-add-square"></i>{{ 'QUOTATION.GENERAL.NEW_PRODUCT' | translate }}</a>

      <ng-container ktPortletTools *ngIf="title === 'Editar Borrador'">
        <a (click)="back()" class="btn btn-secondary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="la la-arrow-left"></i>
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.BACK' | translate }}</span>
        </a>
        <a (click)="save()" class="btn btn-primary font-weight-bold btn-sm mr-2" color="primary" mat-raised-button
          matTooltip="Save & Continue">
          <span class="hidden-mobile">Guardar cambios</span>
        </a>
      </ng-container>
      <ng-container ktPortletTools *ngIf="title === 'Detalles de cotización'">
        <a (click)="send()" class="btn btn-primary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon-mail"></i>
          <span class="hidden-mobile">Enviar</span>
        </a>
        <a (click)="download()" class="btn btn-primary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-download-1"></i>
          <span class="hidden-mobile">Descargar</span>
        </a>
        <a (click)="print()" class="btn btn-primary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-print"></i>
          <span class="hidden-mobile">Imprimir</span>
        </a>
        <a (click)="back()" class="btn btn-secondary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-left-arrow-1"></i>
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.BACK' | translate }}</span>
        </a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title === 'Ver Plan'">
        <a (click)="back()" class="btn btn-secondary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-left-arrow-1"></i>{{ 'QUOTATION.GENERAL.BACK' | translate }}
        </a>
        <a (click)="save()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-left"></i>Crear nueva version
        </a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title === 'Comparacion'">
        <a (click)="print()" class="btn btn-primary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-print"></i>
          <span class="hidden-mobile">Imprimir</span>
        </a>
        <a (click)="back()" class="btn btn-secondary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="flaticon2-left-arrow-1"></i>{{ 'QUOTATION.GENERAL.BACK' | translate }}
        </a>
      </ng-container>




      <ng-container ktPortletTools *ngIf="title === 'Nuevo Producto'">
        <a (click)="back()" class="btn btn-secondary font-weight-bold btn-sm mr-2" mat-raised-button
          matTooltip="Back to the products list">
          <i class="la la-arrow-left"></i>
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.BACK' | translate }}</span>
        </a>
        <a (click)="save()" class="btn btn-primary font-weight-bold btn-sm mr-2" color="primary" mat-raised-button
          matTooltip="Save & Continue">
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.SAVE' | translate }}</span>
        </a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title === 'Catalogo de beneficios'">
        <a (click)="newCatalogue()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Nuevo Catalogo</a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title == 'Lista Secciones' ">

        <a (click)="newCatalogue()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Sección</a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title == 'Lista Características' ">

        <a (click)="newCatalogue()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Característica</a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title == 'Lista de Rangos' ">

        <a (click)="newCatalogue()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Rango</a>
      </ng-container>

      <ng-container ktPortletTools *ngIf="title === 'Beneficios adicionales'">
        <a (click)="newBenefit()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Beneficio</a>
      </ng-container>


      <ng-container *ngIf="title == 'Configuraciones Generales'">
        <a (click)="back()" class="btn btn-secondary mr-2" mat-raised-button matTooltip="Volver al inicio">
          <i class="la la-arrow-left"></i>
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.BACK' | translate }}</span>
        </a>
        <a (click)="save()" class="btn btn-primary mr-2" color="primary" mat-raised-button matTooltip="Guardar">
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.SAVE' | translate }}</span>
        </a>
      </ng-container>


      <ng-container *ngIf="title == 'Agencias'">
        <a (click)="newBroker()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Agencia</a>
      </ng-container>

      <ng-container *ngIf="title == 'Lista Característica' ">
        <div class="btn-group mr-2" ngbDropdown role="group">
          <button class="btn btn-light btn-sm font-weight-bold dropdown-toggle-split" ngbDropdownToggle>
            <span class="text-muted font-weight-bold mr-2">Plantilla:</span>
            <span class="text-primary font-weight-bold">{{ template(IdTemplate) }}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="setTemplate(1)">Individual</button>
            <button class="dropdown-item" (click)="setTemplate(2)">AsisPro</button>
            <button class="dropdown-item" (click)="setTemplate(3)">Pool</button>
          </div>
        </div>
        <a (click)="newCharecteristic()" class="btn btn-primary font-weight-bold btn-sm">
          <i class="flaticon2-add-square"></i>Agregar Característica</a>
      </ng-container>


      <ng-container *ngIf="title == 'Características por Sección' ">
        <div class="btn-group mr-2" ngbDropdown role="group">
          <button class="btn btn-light btn-sm font-weight-bold dropdown-toggle-split" ngbDropdownToggle>
            <span class="text-muted font-weight-bold mr-2">Planes:</span>
            <span class="text-primary font-weight-bold">{{ filterByTipoDePlan?filterByTipoDePlan:'Individual' }}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="filterByPlans('Individual')">Individual</button>
            <button class="dropdown-item" (click)="filterByPlans('Pool')">Pool</button>
          </div>
        </div>
        <a (click)="save()" class="btn btn-primary btn-sm mr-2" color="primary" mat-raised-button matTooltip="Guardar">
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.SAVE' | translate }}</span>
        </a>
      </ng-container>



      <ng-container *ngIf="title == 'Cambiar contraseña'">
        <a (click)="back()" class="btn btn-secondary mr-2" mat-raised-button matTooltip="Volver al inicio">
          <i class="la la-arrow-left"></i>
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.BACK' | translate }}</span>
        </a>
        <a (click)="save()" class="btn btn-primary mr-2" color="primary" mat-raised-button matTooltip="Guardar">
          <span class="hidden-mobile">{{ 'QUOTATION.GENERAL.SAVE' | translate }}</span>
        </a>
      </ng-container>

      <!--begin::Lista Característica-->
      <ng-container *ngIf="title == 'Generar Cotizacion'" ktPortletTools>
        <div class="btn-group mr-2" ngbDropdown>
          <button class="btn btn-light btn-sm font-weight-bold dropdown-toggle-split" ngbDropdownToggle>
            <span class="text-muted font-weight-bold mr-2">Region:</span>
            <span class="text-primary font-weight-bold">{{ filterByRegion }}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="filterByReg('Todas')">Todas</button>
            <ng-container *ngFor="let item of region">
              <button class="dropdown-item" (click)="filterByReg(item.Descripcion)">{{item.Descripcion}}</button>
            </ng-container>
          </div>
        </div>
        <div *ngIf="filterByTipoDePlan == 'Individual'" class="btn-group mr-2" ngbDropdown role="group">
          <button class="btn btn-light btn-sm font-weight-bold dropdown-toggle-split" ngbDropdownToggle>
            <span class="text-muted font-weight-bold mr-2">Titular con beneficio:</span>
            <span class="text-primary font-weight-bold">{{ filterByBeneficio }}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="filterByBenef('Si')">Si</button>
            <button class="dropdown-item" (click)="filterByBenef('No')">No</button>
          </div>
        </div>
        <div class="btn-group mr-2" ngbDropdown role="group">
          <button class="btn btn-light btn-sm font-weight-bold dropdown-toggle-split" ngbDropdownToggle>
            <span class="text-muted font-weight-bold mr-2">Planes:</span>
            <span class="text-primary font-weight-bold">{{ filterByTipoDePlan?filterByTipoDePlan:'Individual' }}</span>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="filterByPlans('Individual')">Individual</button>
            <button class="dropdown-item" (click)="filterByPlans('Pool')">Pool</button>
          </div>
        </div>
      </ng-container>

      <!--end::Dropdown-->
    </div>
  </div>
</div>
<!--end::Subheader-->