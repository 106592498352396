<kt-portlet>
  <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->

  <!-- end::Header -->

  <kt-portlet-body>
    <!-- start::FILTERS & GROUP ACTIONS -->
    <div class="form mb-3">
      <div class="form-filtration">
        <div *ngIf="showAlert" role="alert" class="mat-alert mat-alert-warn">
          <div class="mat-alert-text">{{messageAlert}}</div>
          <div class="mat-alert-close">
            <button 
              class="mat-focus-indicator mat-icon-button mat-button-base mat-warn" 
              color="warn" 
              mat-icon-button="" 
              type="button"
              (click)="closeAlert()">
                <span class="mat-button-wrapper">
                  <mat-icon class="material-icons mat-icon notranslate mat-icon-no-color" role="img" aria-hidden="true">clear</mat-icon>
                </span>
            </button>
          </div>
        </div>
        <div class="row align-items-center">
          <!--<div class="col-md-2">
            <mat-form-field>
              <mat-select [(value)]="filterStatus" (selectionChange)="loadCustomersList()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="0">Suspendido</mat-option>
                <mat-option value="1">Activo</mat-option>
                <mat-option value="Pending">Pendiente</mat-option>
              </mat-select>
              <mat-hint align="start">
                <strong>Buscar</strong> por estado
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="col-md-2">
            <mat-form-field>
              <mat-select [(value)]="filterType" (selectionChange)="loadCustomersList()">
                <mat-option value="">Todos</mat-option>
                <mat-option value="0">Pool</mat-option>
                <mat-option value="1">Individual</mat-option>
              </mat-select>
              <mat-hint align="start">
                <strong>Buscar</strong> por producto
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="col-md-2 kt-margin-bottom-10-mobile">
            <mat-form-field class="mat-form-field-fluid">
              <input matInput placeholder="Buscar cotización" #searchInput />
              <mat-hint align="start">
                <strong>Busqueda</strong> general
              </mat-hint>
            </mat-form-field>
          </div> -->
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Fecha Desde</mat-label>
              <input matInput
               [matDatepicker]="dateSincePicker"
               [(value)]="dateSince" [max]="dateNow"
               (dateChange)="changeDateSince($event)"
               (keypress)="onlyKeyPressDEL($event)"
               autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="dateSincePicker"></mat-datepicker-toggle>
              <mat-datepicker #dateSincePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Fecha Hasta</mat-label>
              <input matInput
                [matDatepicker]="dateUntilPicker"
                [(value)]="dateUntil" [min]="dateSince"
                [disabled]="!dateSince"
                (dateChange)="changeDateUntil($event)"
                (keypress)="onlyKeyPressDEL($event)"
                autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="dateUntilPicker"></mat-datepicker-toggle>
              <mat-datepicker #dateUntilPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-4 pr-12">
            <mat-form-field class="w-100">
              <mat-select [(value)]="filterPlan">
                <mat-option value="0">Todos</mat-option>
                <mat-option *ngFor="let plan of planDataSource" [value]="plan.IdPlan">{{plan.DescPlan + ' - ' + plan.Region}}</mat-option>
              </mat-select>
              <mat-hint align="start">
                <strong>Buscar</strong> por planes
              </mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <a class="btn btn-primary font-weight-bold btn-sm mr-2"
              (click)="buttonFilter()">Buscar</a>
          </div>
        </div>
      </div>

    </div> 
    <!-- end::FILTERS & GROUP ACTIONS -->

    <!-- MATERIAL TABLE | Binded to datasources -->
    <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
    <div class="mat-table-wrapper">
      <mat-table class="lmat-elevation-z8" [dataSource]="dataSource?.Items" matSort #sort1="matSort"
                 matSortActive="IdContizacion" matSortDirection="asc" matSortDisableClear>
        <!-- Checkbox Column -->

        <!-- Table with selection -->
        <!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->


        <ng-container matColumnDef="IdContizacion">
          <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
          <mat-header-cell  mat-sort-header>No. Cotización</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.IdContizacion}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Cliente">
          <mat-header-cell  mat-sort-header>Cliente</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.Cliente}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="FechaCotizacion">
          <mat-header-cell  mat-sort-header>Fecha Cotización</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.FechaCotizacion |  date}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="NombreProducto">
          <mat-header-cell  mat-sort-header>Producto</mat-header-cell>
          <mat-cell *matCellDef="let customer">
            {{customer.NombreProducto}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NombrePlan">
          <mat-header-cell  mat-sort-header>Plan</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.NombrePlan}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Region">
          <mat-header-cell  mat-sort-header>Región</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.Region}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Estado">
          <mat-header-cell  mat-sort-header>Estado</mat-header-cell>
          <mat-cell *matCellDef="let customer">
						<span
              class="label label-lg label-light-{{ getItemCssClassByStatus(customer.status) }} label-inline">{{  getItemStatusString(customer.Estado) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container hidden matColumnDef="VersionPlan">
          <mat-header-cell  mat-sort-header>Version</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.VersionPlan}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell >Actions</mat-header-cell>
          <mat-cell *matCellDef="let customer">

            <button *ngIf="customer.DescEstadoPlan === 'Activo'" mat-icon-button color="primary" matTooltip="Edit customer"
                    (click)="edit(customer)">
              <mat-icon>create</mat-icon>
            </button>&nbsp;

            <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Acciones">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="view(customer)" mat-menu-item>
                <mat-icon>print</mat-icon>
                <span>Imprimir</span>
              </button>
              <button mat-menu-item>
                <mat-icon>email</mat-icon>
                <span>Enviar</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <!-- Message for empty data  -->
      <!-- <div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>-->
      <!-- <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>-->

      <!-- Paginator -->
      <!-- <mat-paginator
      [length]="filterDataSource?.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
      </mat-paginator> -->
   </div>

   <!-- start: BOTTOM -->
    <div class="mat-table-bottom">
      <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
       <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
      <!-- MATERIAL PAGINATOR | Binded to dasources -->
      <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
       <mat-paginator [pageSize]="50" [pageSizeOptions]="[50]" [length]="dataSource?.TotalItems"
                    [showFirstLastButtons]="true" (page)="load()"></mat-paginator>
    </div>
    <!-- end: BOTTOM -->
  </kt-portlet-body>
  <!-- end::Body -->

</kt-portlet>
