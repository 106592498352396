import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef} from '@angular/core';
import {BackQuoteResModel} from '../../../../../../core/quotation/_models/_in/back-quote-res.model';
import {ActivatedRoute} from '@angular/router';
import {SubheaderService} from '../../../../../../core/_base/layout';
import {Subscription} from 'rxjs';
import {PdkMaker} from '../../../../../../core/quotation/_services/pdf-make';
import {SendMailComponent} from '../_subs/send-mail/send-mail.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'kt-quotes-pdf',
  templateUrl: './quotes-pdf.component.html',
  styles: [
  ]
})
export class QuotesPdfComponent implements OnInit, OnDestroy {
  q: BackQuoteResModel;
  private subscriptions: Subscription[] = [];
  pdfMake: any;

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private subheaderService: SubheaderService,
    private pdfService: PdkMaker
    ) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data) => {
        this.q = data.q;
      });
    this.subheaderService.setTitle('Detalles de cotización', 'Vista general');

    this.subscriptions.push(this.subheaderService.print$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        window.print();
        this.cdr.detectChanges();
      })
    }))

    this.subscriptions.push(this.subheaderService.download$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.pdfService.generatePdf(this.q);
        this.cdr.detectChanges();
      })
    }))

    this.subscriptions.push(this.subheaderService.send$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.send(this.q);
        this.cdr.detectChanges();
      })
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  send(q: BackQuoteResModel) {
    const dialogRef = this.dialog.open(SendMailComponent,  {
      data: { q },
      width: '50%' });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
    });
  }

}
