// Angular
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
// Layout
import { OffcanvasOptions } from '../../../../core/_base/layout';
import { AppState } from '../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../core/auth';
import {UserModel} from '../../../../core/quotation/_models/user.model';
import {LayoutUtilsService, MessageType} from '../../../../core/_base/crud';
import {CustomerEditDialogComponent} from '../../../pages/apps/quotation/customers/customer-edit/customer-edit.dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'kt-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  user$: Observable<User>;
  // Public properties
  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle'
  };

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService) {

  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(new Logout());
  }


  editCustomer(customer: User) {

    localStorage.setItem('perfil', '___________');

    let saveMessageTranslateParam = 'Guardar';
    saveMessageTranslateParam += customer.IdUsuario > 0 ? 'Agregar usuario' : 'Nuevo Usuario';
    const dialogRef = this.dialog.open(CustomerEditDialogComponent, { data: { customer } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      localStorage.removeItem('perfil');
      this.user$ = this.store.pipe(select(currentUser));

    });
  }
}
