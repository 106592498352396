import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BackResModel } from '../_models/_in/back-res.model';
import { AnotherRateConfigModel } from '../_models/another-rate-config.model';
const URL_API = JSON.parse(localStorage.getItem('r'));

@Injectable({
    providedIn: 'root'
})

export class ForAnotherAgeRangeService {
    constructor(private http: HttpClient) { }

    getAll() {
        const options = {};
        console.log(URL_API.url)
        return this.http.get<AnotherRateConfigModel[]>(URL_API.url + 'ForAnotherAgeRange', options);
    }

    getById(id: number) {
        const options = {};
        console.log(URL_API.url)
        return this.http.get<AnotherRateConfigModel[]>(URL_API.url + `ForAnotherAgeRange?Id=${id}`, options);
    }

    add(sections: AnotherRateConfigModel) {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<AnotherRateConfigModel>(URL_API.url + 'ForAnotherAgeRange', sections, { headers: httpHeaders });
    }

    update(sections: AnotherRateConfigModel) {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');
        return this.http.put<AnotherRateConfigModel>(URL_API.url + 'ForAnotherAgeRange', sections, { headers: httpHeaders });
    }

}
